import React from 'react';
import { withRouter, Route, Redirect } from 'react-router-dom';
import { Auth } from 'aws-amplify';

class PrivateRoute extends React.Component {
  state = {
    loaded: false,
    isAuthenticated: false
  };

  componentDidMount() {
    this.authenticate();
  }

  authenticate() {
    Auth.currentAuthenticatedUser()
      .then(() => {
        this.setState({ loaded: true, isAuthenticated: true });
      })
      .catch(() => {
        this.setState({ loaded: true, isAuthenticated: false });
      });
  }

  render() {
    const {
      component: Component = null,
      render: Render = null,
      path,
      ...rest
    } = this.props;

    console.log('path:', path)
    const { loaded, isAuthenticated } = this.state;

    if (!loaded) {
      return null;
    } else {
      return (
        <Route
          {...rest}
          render={props =>
            isAuthenticated ? (
              Render ? (Render(props))
                : Component ? (<Component {...props} />)
                  : null
            ) : (
              <Redirect
                to={{ pathname: '/auth', state: { from: props.location } }}
              />
            )
          }
        />
      );
    }
  }
}

export default withRouter(PrivateRoute);
