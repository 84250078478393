import React from 'react';
import { Grid, Paper, Box, Container } from '@material-ui/core';

export default ({ micro, classes, parent }) => {
  console.log(micro);
  return (
    <Container
      style={{
        height: '100%',
        flexGrow: 100,
        width: '100%',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '5000px'
      }}
    >
      <Box textAlign="left" ml={2}>
        <h3>Micro {micro.micro_index + 1}</h3>
      </Box>
      <Grid
        container
        wrap='nowrap'
        alignItems={'center'}
        spacing={0}
        style={{
          flexGrow: 100,
          display: 'flex',
          alignItems: 'stretch',
        }}
      >
        {
          micro.days.map((day, i) => {
            if (day.movements.length > 0) {
              console.log('day.movements.length:', day.movements.length)
              return (
                <Grid
                  item
                  key={day.day_id}
                  style={{
                    flexGrow: 100,
                    display: 'flex',
                    alignItems: 'stretch',
                    backgroundColor: 'white',
                    flexDirection: 'column',
                    border: '1px solid black',
                  }}
                // onClick={() => parent.props.history.push(`/micro/${micro.micro_id}`) }
                >
                  {
                    day.movements.map((movement, mi) => {
                      let sets = movement.sets.length
                      let reps = movement.sets[0].reps
                      let weight = Math.round(movement.sets[0].weight)
                      let summary = `${sets} sets of ${reps} reps @ ${weight} kg`
                      return (
                        <div className={classes.navVariable} key={movement.movement_id} >
                          <div className={classes.navVariableSmallValue}>{movement.movement_name.en}</div>
                          <div className={classes.navVariableName}>{summary}</div>
                        </div>
                      )
                    })
                  }
                </Grid>
              )
            }
          })}
      </Grid>
    </Container>
  )
}