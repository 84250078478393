import React from 'react';

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Translate } from "react-localize-redux";

const SelectMetric = (props, handleChange) => {
  const { classes, parent } = props

  return (
    <div className={classes.radioRow}>
      <div className={classes.metricSelect}>
        <RadioGroup
          aria-label="metric"
          name="metric"
          value={parent.state.metric}
          onChange={(e) => {
            handleChange({ target: { name: "metric", value: e } }, parent);
          }}
          row
        >
          <FormControlLabel
            value={true}
            control={<Radio color="primary" />}
            id="kilograms"
            label={<Translate id={"kilograms"} />}
            labelPlacement="end"
          />
          <FormControlLabel
            value={false}
            control={<Radio color="primary" />}
            id="pounds"
            label={<Translate id={"pounds"} />}
            labelPlacement="end"
          />
        </RadioGroup>
      </div>
    </div>
  )
}

export default SelectMetric;