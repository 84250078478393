import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles, Backdrop, CircularProgress, Card, Typography } from "@material-ui/core";
import { getPrograms, handleProgramIdChange } from "./api";
import { getNextMonday } from "./utils";
import { styles } from "./styles";
import { KeyboardArrowRight } from '@material-ui/icons'
import PushPinIcon from '@mui/icons-material/PushPin';

class _Programs extends React.Component {
  state = {
    cognito_id: "",
    comp_styles: {
      squat: "low-bar back squat",
      bench: "medium-grip bench press",
      deadlift: "sumo deadlift",
    },
    username: "",
    stripeUrl: "",
    frequency: 3,
    birthday: "",
    male: true,
    bodyweight: "",
    height: "",
    maxs: {
      "low-bar back squat": { max: "", xrm: 1 },
      "medium-grip bench press": { max: "", xrm: 1 },
      "sumo deadlift": { max: "", xrm: 1 },
    },
    start_date: "",
    promoCode: "",
    plError: "",
    pbError: "",
    readiness_level: "",
    fitness_level: "",
    birth_day: 1,
    birth_month: 1,
    birth_year: 2000,
    pageLoading: true,
    errors: [],
    entrymin: 0,
    entrymax: 999,
    metric: true,
    email: "",
    discount: 0,
    program: 'powerlifting',
    loadingProgram: ''
  };

  async componentDidMount() {
    const { userData } = this.props;

    console.log('mounting layout! userData:', userData)

    var state = this.state
    state = Object.assign(state, userData)

    state.start_date = getNextMonday()
    state.birth_day = userData.birthday ? parseInt(userData.birthday.split(" ")[0].split("-")[2]) : 1
    state.birth_month = userData.birthday ? parseInt(userData.birthday.split(" ")[0].split("-")[1]) : 1
    state.birth_year = userData.birthday ? parseInt(userData.birthday.split(" ")[0].split("-")[0]) : 2000

    state.metric = userData.metric

    state.state_copy = state

    this.setState(state);

    await getPrograms(userData.cognito_id, this)

    this.setState({ pageLoading: false })
    // console.log('state after componentDidMount:', this.state)
  }

  RenderNewProgramCard = () => {
    const { classes } = this.props;

    return (
      <div
        className={classes.newProgramCard}
        raised={true}
        onClick={() => this.props.history.push('/onboard')}
        key="new_card"
        id="new_card"
      >
        <div className={classes.newProgramCardContent}>
          <Typography variant="h6" align="left">
            Build new program
          </Typography>
          <KeyboardArrowRight />
        </div>
      </div>
    )
  }

  RenderCard = (p) => {
    const { classes } = this.props;

    const program = p.program.charAt(0).toUpperCase() + p.program.slice(1)

    return (
      <div className={classes.card} raised={true} key={p.program_id}>
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'flex-start' }}>
          <Typography variant="h5" component="div" align="left" gutterBottom>
            {program}
          </Typography>
          <Typography sx={{ fontSize: 10 }} align="left" id="display_dates">
            {p.start_date} — {p.end_date}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            backgroundColor: 'white'
          }}
          onClick={(e) => handleProgramIdChange(this, p.program_id)}
        >
          {this.state.program_id === p.program_id ?
            null :
            (
              this.state.loadingProgram === p.program_id ?
                <CircularProgress size={18} /> : <PushPinIcon fontSize={'small'} id="selectProgram" />
            )
          }
        </div>
      </div >
    )
  }

  render() {
    const { classes } = this.props;
    const { programs } = this.state;

    if (this.state.pageLoading) {
      return (
        <Backdrop open={true} invisible={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      return (
        <div className={classes.root}>
          {this.RenderNewProgramCard()}
          {programs.map(p => this.RenderCard(p))}
        </div>
      );
    }
  }
}

export default withRouter(withStyles(styles)(_Programs));