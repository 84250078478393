import React from 'react';
import { Grid, Paper, Box, Container } from '@material-ui/core';

export default ({ day, classes }) => {
    return (
        <Container maxWidth='lg'>
            <Box textAlign="left" ml={2}>
                <h3>Day {day.day_index + 1}</h3>
            </Box>
            <Grid container spacing={1} alignItems="stretch" justify="space-evenly">
                {
                    day.movements.map((movement, i) =>
                        <Grid item xs>
                            <a href={`${day.day_index}/movement/${i}`} className={classes.link}>
                                <Box component={Paper} elevation={3} p={2}>
                                <Box textAlign="left" fontWeight='fontWeightBold'>{movement.movement_name.en}</Box> 
                                <Box textAlign="left">{movement.sets.length} Sets</Box>
                                </Box>
                            </a>
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    )
}

