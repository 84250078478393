import axios from 'axios';
import mixpanel from 'mixpanel-browser';
import { Auth } from 'aws-amplify';

mixpanel.init('fb6d0cf9cf3b6d33c7c68d30106861a7');

const getConfig = async () => {
  console.log('in getConfig')
  const date = new Date()
  const local_date = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  console.log('authToken:', authToken)

  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken
    },
    params: { 'local_date': local_date }
  };
}

export const postBuildRequest = async (pl, redirect = true, sendToWorkouts = false) => {
  console.log('we are in postBiuldRequest, pl:', pl)
  const q = {
    cognito_id: pl.cognito_id,
    first_name: pl.first_name,
    last_name: pl.last_name,
    program: pl.program,
    workout_days: pl.workout_days,
    frequency: parseInt(pl.frequency),
    fitness_level: pl.fitness_level,
    birthday: pl.birthday,
    birth_year: pl.birth_year,
    birth_month: pl.birth_month,
    birth_day: pl.birth_day,
    height: pl.height,
    bodyweight: pl.bodyweight,
    male: pl.male,
    start_date: pl.start_date,
    maxs: pl.maxs,
    comp_styles: pl.comp_styles,
    metric: pl.metric,
    autologin: pl.autologin,
    url: window.location.href,
    length_days: pl.length_days,
    program_focus: pl.program_focus,
    sessions_info: pl.sessions_info,
    volume_info: pl.volume_info,
    source: 'web'
  };

  const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/programs`

  var errors = []

  for (const property in q) {
    if (q[property] === null || q[property] === "") {
      errors.push(property)
    }

    if (property === 'maxs') {
      for (const lift of ['snatch', 'clean', 'back_squat', pl.comp_styles.jerk]) {
        const val = q.maxs[lift]
        if (val.max === 0 || val.max === null || val.max === "" || val.max === "0") {
          errors.push(lift)
        }
      }

    }
  }

  console.log('errors:', errors)

  let config = await getConfig()
  console.log('config:', config)
  const data = JSON.stringify(q);
  console.log('url', url)
  console.log('data', JSON.stringify(q, null, 2))

  setTimeout(function () {
    if (errors.length > 0) {
      console.log('ERROR:', errors)
      pl.handleValidationError(errors)
    } else {
      console.log('getting stripe url now...')

      mixpanel.track(`submitting program build request`);

      return axios
        .post(url, data, config)
        .then(data => {
          mixpanel.track(`build request returned successfully`);
          console.log('got link ', data.data);
          if (redirect) {
            if (sendToWorkouts) {
              window.location.href = window.location.href.replace('onboard', 'workouts')
            } else {
              window.location.href = data.data;
            }
          }
        })
        .catch(error => {
          mixpanel.track(`build request failed`);
          console.log('ERROR:', error)
        });
    }
  }, 100)
};

export const getPrograms = async (cognito_id, parent) => {
  console.log('running getPrograms')
  var params = { cognito_id }

  const config = await getConfig()

  var url = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/programs`;
  url = `${url}?cognito_id=${cognito_id}&source=web`

  try {
    const data = await axios
      .get(url, params, config);
    console.log('got data', data.data);
    parent.setState({ programs: data.data })
  } catch (error) {
    console.log('error!!!!', error);
  }
};

export const handleProgramIdChange = (parent, program_id) => {
  parent.setState({ loading: true, loadingProgram: program_id });
  var q = { cognito_id: parent.state.cognito_id, program_id, programs: parent.state.programs }

  console.log('trying to submit program_id update with payload:', q)
  parent.props.updateUserData(q).then(() => {
    parent.setState({ loading: false, program_id, loadingProgram: '' })
  }
  ).catch((err) => console.log('ERROR:', err))
}