export const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "63px"
  },
  tabContainer: {
    flex: 1,
    padding: 20,
  },
  content: {
    display: "flex",
    marginTop: "40px",
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '72%',
    height: '90%',
    backgroundColor: 'white',
    border: '2px solid #000',
    boxShadow: 24,
    overflowY: "scroll",
    padding: 20
  },
  profilesContainer: {
    display: "flex",
    flex: 1,
    marginBottom: 30,
    overflowX: "scroll",
    width: window.innerWidth - 50,
    paddingBottom: 10
  },
  profileCard: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 10,
    boxShadow: "2px 3px 12px 2px #dadada",
    maxWidth: 300,
    minWidth: 300,
    borderRadius: 5,
    margin: 10
  },
  profileAddCard: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 10,
    boxShadow: "2px 3px 12px 2px #dadada",
    maxWidth: 300,
    minWidth: 300,
    borderRadius: 5,
    margin: 10,
    height: 571,
    borderStyle: 'solid',
    borderWidth: 4,
    borderColor: 'lightblue',
    justifyContent: "center",
    alignItems: "center",
  },
  profileSectionContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 130,
    minHeight: 130,
    minWidth: 280,
    overflowY: 'scroll',
    paddingLeft: 15,
    paddingRight: 15
  },
  buttonContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  button: {
    flex: 'none',
    backgroundColor: "black",
    color: 'white',
    margin: 5
  },
  profileResultsContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  profileResult: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: 20,
    boxShadow: "2px 3px 12px 2px #dadada",
    borderRadius: 5,
    margin: 10
  },
  profileResultTitle: {
    fontSize: 24,
    fontWeight: 800
  },
  programCardContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    width: '100%'
  },
  programSummaryContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: 1,
    maxWidth: 300
  },
  programMicro: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: 20,
    width: '100%'
  },
  programMicroTitle: {
    fontWeight: 700,
    fontSize: 20,
    width: '100%',
    textAlign: 'center'
  },
  programMicroNote: {
    flex: 1,
    width: '100%',
    textAlign: 'center',
    marginTop: 10
  },
  programDay: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 20,
    justifyItems: "center",
    // backgroundColor: "red",
    marginTop: 20,
    padding: 10,
    borderRadius: 5,
    alignSelf: "center",
  },
  dayName: {
    display: "flex",
    flex: 1,
    transform: "rotate(-90deg)",
    width: 30,
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    fontSize: 20,
    height: '100%'
  },
  programDayMovements: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center"
  },
  programMovement: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
    flexDirection: 'column',
    marginLeft: 20,
    backgroundColor: "white",
    boxShadow: "2px 3px 12px 2px #dadada",
    margin: 5,
    padding: 5,
    borderRadius: 5
  },
  programTopInfo: {
    display: "flex",
    flex: 1,
    alignItems: "stretch",
  },
  programBottomInfo: {
    display: "flex",
    flex: 1,
    gap: 8,
    marginLeft: 5,
  },
  programBottomInfoBox: {
    display: "flex",
    padding: 4,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
    fontSize: 8
  },
  programMovementName: {
    display: "flex",
    borderWidth: 2,
    borderRadius: 5,
    borderStyle: "solid",
    borderColor: "black",
    width: 600,
    // minHeight: 37.9,
    padding: 5,
    margin: 5,
    justifyContent: "center",
    alignItems: "center"
  },
  programMovementInfoContainer: {
    display: "flex",
    flexGrow: 1,
  },
  programMovementInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 2,
    borderRadius: 5,
    borderStyle: "solid",
    borderColor: "black",
    backgroundColor: "#fdfdfd",
    padding: 5,
    margin: 5,
  },
  toggleGroup: {
    display: "flex",
  },
  appBarShit: {
    position: "fixed",
  },
  workOut: {
    color: "green",
    fontSize: 20,
  },
  root: {
    flexShrink: 0,
    marginLeft: "10px",
  },
  programMovementInfoTop: {
    alignSelf: 'center'
  }
});
