import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import LanguageToggle from './LanguageToggle';
import Menu from '../Drawer';
import { styles } from './styles';

class _Header extends React.Component {
  state = {
    anchorEl: null,
    dialogState: false,
    showMenu: false
  };


  // Handlers
  handleChange = event => { this.setState({ auth: event.target.checked }) };
  handleMenu = event => { this.setState({ anchorEl: event.currentTarget }) };
  handleClose = () => { this.setState({ anchorEl: null }) };
  handleOpenDialog = () => { this.setState({ dialogState: true }) };
  handleCloseDialog = () => { this.setState({ dialogState: false }) };
  handleHeaderSignIn = () => { this.props.updateAuth(true) }
  handleHeaderSignout = () => { this.props.updateAuth(false) }

  render() {
    const { classes } = this.props;
    const location = this.props.history.location.pathname;

    return (
      <Fragment>
        <div
          className={classes.container}
          style={{
            backgroundColor: location === '/auth' ? 'transparent' : '#002141'
          }}
        >
          <div className={classes.languageSelect}>
            < LanguageToggle />
          </div>
          <Link className={classes.link} to="/workouts">
            <p className={classes.logoWH} id="logoWH">
              <div>WEIGHTLIFTING</div>
              <div style={{ color: "#e73823" }}>.AI</div>
            </p>
          </Link>
          {this.props.isAuth ? (
            <div className={classes.menuButton}>
              <Menu handleHeaderSignOut={this.handleHeaderSignout} />
            </div>
          ) : (
            <div />
          )
          }
        </div>
      </Fragment>
    );
  }
}

_Header.propTypes = {
  classes: PropTypes.object.isRequired
};

export const Header = withRouter(withStyles(styles)(_Header));
