import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import 'date-fns';
import { styles } from './styles';
import mixpanel from 'mixpanel-browser';
import { FormControl, Button } from '@material-ui/core';
import submitData from './api'
import { Translate } from "react-localize-redux";
import { WorkoutDaysSelect } from '../../components/Form/userForm'
import { Backdrop, CircularProgress } from "@material-ui/core";


class _WorkoutSettings extends React.Component {
  state = {
    on_hold: null,
    workout_days: ['monday', 'wednesday', 'friday'],
    loading: false,
    pageLoading: true
  };

  statusClass = () => {
    const status = this.state.on_hold_status

    if (status === 'active_pending_hold') {
      return 'isPending'
    } else if (status === 'on_hold') {
      return 'isOnHold'
    } else if (status === 'active') {
      return 'isActive'
    } else {
      return 'isPending'
    }
  }


  async componentDidMount() {
    mixpanel.track('view workout settings');

    const { userData, fetchUserData } = this.props;
    await fetchUserData(userData.userReducer.userName).then((data) => {
      this.setInitialState(data);
    });
    console.log('componentDidMount:', this.state)
  }

  handleSubmit = (e, data) => {
    this.setState({ loading: true })
    e.preventDefault();
    console.log(data);

    var workout_days
    var on_hold

    if (data.type === 'workout_days') {
      console.log('handling workout_days')
      workout_days = this.state.workout_days
      on_hold = null
    } else {
      console.log('handling hold')
      on_hold = !this.state.on_hold
      workout_days = null
    }

    submitData({
      program_id: this.state.program_id,
      cognito_id: this.state.cognito_id,
      on_hold: on_hold,
      workout_days: workout_days
    }).then((data) => {
      console.log(data)
      var state = this.state
      Object.assign(state, data)
      state.loading = false
      this.setState(state)
    })
  };

  setInitialState = (data) => {
    console.log('data:', data)
    this.setState({
      cognito_id: data.cognito_id,
      program_id: data.program_id,
      on_hold: data.current_program.on_hold,
      workout_days: data.current_program.workout_days,
      on_hold_start_date: data.current_program.on_hold_start_date,
      on_hold_end_date: data.current_program.on_hold_end_date,
      on_hold_status: data.on_hold_status,
      pageLoading: false
    });
  };

  render() {

    const { classes } = this.props;
    const status = this.state.on_hold_status

    if (this.state.pageLoading) {
      return (
        <Backdrop open={true} invisible={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )
    } else {
      return (
        <div className={classes.container}>
          <div className={classes.subTitleBox}>
            <Translate id="frequency" />
          </div>
          <div className={classes.settingBox}>
            <div className={classes.descriptionBox}>
              Changes to your training days will take effect immediately, although any days with written workouts during the current week will not be overwritten.
            </div>
            <FormControl className={classes.formContainer}>
              <WorkoutDaysSelect parent={this} />
              <Button
                style={{ color: "#fff", marginTop: 20, minWidth: 250 }}
                variant="contained"
                id="workout_days_submit_button"
                color="primary"
                type="submit"
                onClick={(e) => this.handleSubmit(e, { type: 'workout_days' })}
                disabled={this.state.loading}
              >
                Change Training Days
              </Button>
            </FormControl>
          </div>
          {/* <div id="subOption" className={classes.subTitleBox}>
            <Translate id="holds" />
          </div>
          <Translate>
            {(translate) =>
              <div className={classes.settingBox}>
                <div className={classes.statusContainer}>
                  <div className={classes.descriptionBox}>
                    <Translate id="status" />
                  </div>
                  <div id="currentStatus" className={classes[this.statusClass()]}>
                    {translate.translate(`${status}.text`)}
                  </div>
                </div>
                <div className={classes.detailBox}>
                  {translate.translate(`${status}.description`)}
                </div>
                <FormControl className={classes.formContainer}>
                  <Button
                    style={{ color: "#fff", marginTop: 20, minWidth: 250 }}
                    variant="contained"
                    id="hold_submit_button"
                    color="primary"
                    type="submit"
                    onClick={(e) => this.handleSubmit(e, { type: 'hold' })}
                    disabled={this.state.loading}
                  >
                    {translate.translate(`${status}.button`)}
                  </Button>
                </FormControl>
              </div>
            }
          </Translate> */}
        </div >
      );
    }
  }
}

export default withRouter(withStyles(styles)(_WorkoutSettings));