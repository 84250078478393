import * as React from 'react';
import { DataGrid } from '@material-ui/data-grid';

const columns = [
    {
        field: 'on_hold_start_date',
        headerName: 'start',
        flex: 1,
        editable: true,
    },
    {
        field: 'on_hold_end_date',
        headerName: 'end',
        flex: 1,
        editable: true,
    },
    {
        field: 'hold_micro_count',
        headerName: 'hold_micro_count',
        type: 'number',
        flex: 1,
        editable: true,
    },

];


function mapHistory(history) {
    const result = history.map((item, index) => Object.assign(item, { id: ++index }))
    console.log('result:', result)
    return result
}

export default class DataTable extends React.Component {

    state = {
        updateHistory: null,
        setDeleteID: null
    }

    componentDidMount() {

        this.setState({
            updateHistory: this.props.updateHistory,
            setDeleteID: this.props.setDeleteID
        })
    }

    render() {


        return (
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={this.props.hold_history ? mapHistory(this.props.hold_history) : []}
                    columns={columns}
                    pageSize={5}
                    onCellEditCommit={(params, event) => {
                        this.state.updateHistory(params.row.id, params.field, params.value, params.row)
                    }}
                    onSelectionModelChange={(model) => {
                        this.state.setDeleteID(model[0])
                    }}
                />
            </div>
        );

    }



}