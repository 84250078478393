import React from 'react';
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

let options = [
  {
    "name": "justCompeted",
    "text": "I just competed and I am ready to start a new program.",
    "fitness": "low"
  },
  {
    "name": "midTraining",
    "text": "I'm in the middle of a training program but will start a new one now.",
    "fitness": "high"
  },
  {
    "name": "minimalTraining",
    "text": "I've been training but nothing very hard or structured.",
    "fitness": "medium"
  },
  {
    "name": "notTraining",
    "text": "I haven't been training.",
    "fitness": "low"
  },
]

const fitnessOptions = (classes, text, selected) => {
  return (
    <div className={classes.fitnessOption}>
      <div className={classes[`${selected}FitnessOption`]}>
        <div className={classes.radioHeader}>
          {text}
        </div>
      </div>
    </div>
  )
}

const SelectFitness = (props, handleChange) => {
  const { classes, parent } = props

  return (
    <RadioGroup
      aria-label="readiness_level"
      value={parent.state.readiness_level}
      onChange={(e) => { handleChange(e, parent) }}
      name="readiness_level"
      className={classes.radioGroup}
    >
      {options.map(opt => {
        return (
          <div className={classes.radioButton} key={opt.name}>
            <FormControlLabel
              control={
                <Radio
                  disableRipple
                  classes={{ root: classes.radio, checked: classes.checked }}
                  icon={fitnessOptions(classes, opt.text, 'unselected')}
                  checkedIcon={fitnessOptions(classes, opt.text, 'selected')}
                />
              }
              classes={{ root: classes.formControlLabelRoot }}
              name={`readiness_fitness~${opt.fitness}`}
              id={opt.name}
              value={opt.name}
              key={opt.name}
            />
          </div>
        )
      })}

    </RadioGroup>
  )
}

export default SelectFitness;