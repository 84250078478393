import { connect } from 'react-redux';
import _Menu from './layout';
import { fetchUserData } from '../../state/thunk';

const mapStateToProps = ({ userReducer }) => ({
    purchased: userReducer.charges && userReducer.charges.length > 0,
    cognito_id: userReducer.cognito_id,
    userName: userReducer.userName,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserData: (userName) => dispatch(fetchUserData(userName)),
});

const Menu = connect(mapStateToProps, mapDispatchToProps)(_Menu);

export default Menu