import React from 'react';
import MailIcon from '@material-ui/icons/Mail';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircle from '@material-ui/icons/AccountCircle';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import AssignmentIcon from '@material-ui/icons/Assignment';

export const listItems = [
  {
    link: '/workouts',
    icon: <MailIcon />,
    text: {
      'en': 'workouts',
      'ch': '运动'
    },
    text_en: 'workouts',
    text_ch: '运动',
    buyers_only: true,
    secondary_action: {
      link: '/workout-settings',
      icon: <SettingsIcon />
    },
    coaches_only: false,
    admins_only: false
  },
  {
    link: '/profile',
    icon: <AccountCircle />,
    text: {
      'en': 'profile',
      'ch': '主页'
    },
    text_en: 'profile',
    text_ch: '主页',
    buyers_only: false,
    secondary_action: null,
    coaches_only: false,
    admins_only: false
  },
  {
    link: '/programs',
    icon: <LibraryBooks />,
    text: {
      'en': 'programs',
      'ch': 'programs'
    },
    text_en: 'programs',
    text_ch: 'programs',
    buyers_only: true,
    secondary_action: null,
    coaches_only: false,
    admins_only: false
  },
  {
    link: '/coach',
    icon: <AssignmentIcon />,
    text: {
      'en': 'coach',
      'ch': '管理员'
    },
    text_en: 'coach',
    text_ch: '管理员',
    buyers_only: false,
    secondary_action: null,
    coaches_only: true,
    admins_only: false
  },
  {
    link: '/admin',
    icon: <SupervisorAccountIcon />,
    text: {
      'en': 'admin',
      'ch': 'admin'
    },
    text_en: 'admin',
    text_ch: 'admin',
    buyers_only: false,
    secondary_action: null,
    coaches_only: false,
    admins_only: true
  },
  {
    link: '/auth',
    icon: <ExitToApp />,
    text: {
      'en': 'logout',
      'ch': '退出登陆'
    },
    text_en: 'logout',
    text_ch: '退出登陆',
    buyers_only: false,
    secondary_action: null,
    coaches_only: false,
    admins_only: false
  }
];
