import axios from 'axios';
import { Auth } from 'aws-amplify';
import axiosRetry from 'axios-retry';

const adminUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin`;

export const modifyProfileStats = (results) => {
  results.map((profile) => {
    let program = profile.program
    let volumes = []
    let intensities = []
    let counts = []
    let stats = program.stats
    let PMGs = Object.keys(stats.PMG_reps)
    let micros = stats.micros
    let reps = {}

    // get weekly reps by PMG
    PMGs.map((PMG) => {
      reps[PMG] = micros.map((m) => {
        let g = {}
        g.x = parseInt(m.micro_count + 1)
        g.y = m.PMGs[PMG]
        return g
      })
    })

    // get daily reps by PMG
    reps.dailies = micros.map((m) => {
      let days_reps = {}

      PMGs.map((PMG) => {
        days_reps[PMG] = m.days.map((d) => {
          let g = {}
          g.x = parseInt(d.micro_day_count + 1)
          g.y = d.vol_PMGs[PMG]
          return g
        })
        days_reps[PMG] = days_reps[PMG].filter(d => d.y > 0)
      })

      return days_reps
    })

    stats.micros.map((micro) => {
      volumes.push(micro.volume)
      intensities.push(micro.avg_intensity * 1)
      counts.push(micro.micro_count + 1)
    })

    program.reps = reps
    program.volumes = volumes
    program.intensities = intensities
    program.counts = counts
  })

  return results
}

export const runProfiler = async (profiles, program, parent) => {
  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const params = { get: 'profiles', source: 'web', respond: true }

  let profileData = JSON.stringify(profiles)
  let profiles_copy = JSON.parse(profileData)

  profiles_copy.map((profile) => {
    profile.program.program_id = 'test_test'
    profile.program.program = program
    profile.user.cognito_id = 'test-test-test'
  })

  profileData = JSON.stringify(profiles_copy)

  const data = { profileData, program }

  while (true) {
    await axios
      .post(adminUrl, data, { params, headers })
      .then((data) => {
        let profileResults = data.data

        console.log("profileResults.length", profileResults.length)
        console.log(typeof profileResults)

        if (profileResults.length > 0) {
          profileResults = modifyProfileStats(profileResults)
          parent.setState({ profileResults })
          params.respond = false
        }
      })
      .catch(async (error) => params.respond = true)
  }
};
