import React, { Component } from 'react';
import moment from 'moment';
import { withRouter } from 'react-router';
import { withStyles } from '@material-ui/core';
import { withLocalize } from 'react-localize-redux';
import { Breadcrumbs, Link } from '@material-ui/core';
import { Auth } from "aws-amplify";
import { getProgram } from './api';

// Components
import { VariableList, ProgramView, MicroView, DayView, MovementView, PhaseView } from './components';

import { styles } from './styles';

// Utility
import { PROGRAM, PHASE, MICRO, DAY, MOVEMENT, SET } from './route_names';



const programJson = require('./program.json');

class _ProgramInterface extends Component {

    constructor(props) {
        super(props);
        this.state = {
            basepath: null,
            programData: null,
            navComponent: null,
            variables: [],
            breadcrumbLinks: [],
            testData: false
        }
    }

    async componentDidMount() {
        // I'm guessing this data will be fetched at some point I'd want to do this in a parent component \
        // A container to show a loading screen while it fetches the data...
        // we'd probably want to cache that at some point to save some time between navigations.
        console.log('params:', this.props.match.params)
        let userId = this.props.match.params.userId
        let programId = this.props.match.params.programId

        await Auth.currentSession().then((value) => {
            const payload = value.accessToken.payload
            const groups = payload['cognito:groups'] ? payload['cognito:groups'] : []
            if (!groups.includes('COACHES')) {
                return this.props.history.push("/workouts");
            }
        })

        if (!this.state.testData) {
            await getProgram(userId, programId).then((data) => {
                console.log('program data:', data)
                const basepath = `/program/edit/${userId}/${programId}`;
                data.micros = data.micros.reverse()
                this.setState({ programData: data, basepath });
            })
        } else {
            const data = require('./program.json');
            console.log('program data:', data)
            const basepath = `/program/edit/${userId}/${programId}`;
            this.setState({ programData: data, basepath });
        }
    }

    createProgramView = () => {
        console.log('running createProgramView')
        const phaseData = this.getPhaseData();
        const programId = this.props.match.params.programId;
        // in this case its all test
        const removeVarList = ["micros", "hold_history", "frequency_change_date", "on_hold_end_date", "on_hold_start_date"];
        const variables = Object.entries(this.state.programData).filter(([key, val]) => !removeVarList.includes(key)).map(([name, val]) => ({ name, val }));
        console.log('variables:', variables)

        // Set appropriate variables
        return {
            navComponent: <ProgramView basepath={this.state.basepath} programId={programId} phases={phaseData} classes={this.props.classes} parent={this} />,
            variables
        }
    }

    createPhaseView = () => {
        console.log('running createPhaseView')
        const phaseName = this.props.match.params.phaseName;
        const phaseData = this.getPhaseData()[phaseName];

        const testVarList = ["test_int", "test_float", "test_string", "test_dict", "test_arary"];
        const variables = Object.entries(this.state.programData).filter(([key, val]) => testVarList.includes(key)).map(([name, val]) => ({ name, val }));

        return {
            navComponent: <PhaseView basepath={this.state.basepath} phaseName={phaseName} phaseData={phaseData} classes={this.props.classes} parent={this} />,
            variables
        }
    }

    createMicroVeiw = () => {
        const micro = this.findMicro();
        const variables = Object.entries(micro).filter(([key, val]) => ['micro_count', 'micro_length', 'peak_micro'].includes(key)).map(([name, val]) => ({ name, val }));
        return { navComponent: <MicroView basepath={this.state.basepath} micro={micro} classes={this.props.classes} />, variables }
    }

    createDayView = () => {
        const day = this.findDay();
        day['day_index'] = Number(this.props.match.params.dayIndex);
        // TODO: get the name in the appropriate language, for now it's in english
        const variables = day.movements.reduce((acc, curr) => {
            acc['val'].push(curr.movement_name.en);
            return acc;
        }, { name: 'movement_names', val: [] });

        return { navComponent: <DayView basepath={this.state.basepath} day={day} classes={this.props.classes} />, variables: [variables] }
    }

    createMovementView = () => {
        const movement = this.findMovement();
        movement['movement_index'] = Number(this.props.match.params.dayIndex);

        return { navComponent: <MovementView basepath={this.state.basepath} movement={movement} />, variables: [{ name: 'set_count', val: movement.sets.length }, { name: 'movement_name', val: movement.movement_name.en }] }
    }

    createSetView = () => { this.findSet(); }

    findSet = () => this.findMovement().sets[this.props.match.params.setIndex];
    findMovement = () => this.findDay().movements[this.props.match.params.movementIndex];
    findDay = () => this.findMicro().days[this.props.match.params.dayIndex];
    findMicro = () => {
        const microIndex = this.state.programData.micros.findIndex((micro) => micro.micro_id === this.props.match.params.microId);
        const micro = this.state.programData.micros[microIndex];
        micro['micro_index'] = microIndex
        return micro;
    }

    getPhaseData = () => {
        const micros = this.state.programData.micros
        return micros.reduce((phases, micro) => {
            phases[micro['phase']]
                ? phases[micro['phase']].push(micro)
                : phases[micro['phase']] = [micro];
            return phases;
        }, {});
    }

    breadcrumbLinks = () => {
        const { basepath } = this.state;
        const {
            phaseName, microId, dayIndex, movementIndex, setIndex
        } = this.props.match.params;

        const microPath = basepath + `/micro/${microId}`;
        const dayPath = microPath + `/day/${dayIndex}`;
        const movementPath = dayPath + `/movement/${movementIndex}`;
        const setPath = movementPath + `/set/${setIndex}`;

        const propLinkMap = {
            programId: { path: basepath, label: () => 'Program' },
            phaseName: { path: basepath + '/phase/' + phaseName, label: () => phaseName.toUpperCase() },
            microId: { path: microPath, label: () => "Micro " + (this.findMicro().micro_index + 1) },
            dayIndex: { path: dayPath, label: () => `Day ${Number(dayIndex) + 1} (${moment(this.findDay().date_utc).format('M-D-YYYY')})` },
            movementIndex: { path: movementPath, label: () => this.findMovement().movement_name.en },
            setIndex: { path: setPath }
        }

        return Object.entries(propLinkMap).reduce((acc, [propName, propVals]) => {
            if (!this.props.match.params[propName]) return acc;
            acc.push(<Link href={propVals.path}> {propVals.label()} </Link>)
            return acc
        }, []);

    }

    render() {
        const { classes } = this.props;

        if (this.state.programData === null) {
            return null
        } else {
            console.log('this.state.programData:', this.state.programData)

            const viewMethodMap = {
                [PROGRAM]: this.createProgramView,
                [PHASE]: this.createPhaseView,
                [MICRO]: this.createMicroVeiw,
                [DAY]: this.createDayView,
                [MOVEMENT]: this.createMovementView,
                [SET]: this.createSetView
            };

            const { path } = this.props.match;
            let { navComponent, variables } = viewMethodMap[path]()

            return (
                <div className={classes.root}>
                    <div style={{ minHeight: '64px' }} />
                    <div style={{ padding: 10 }}>
                        <Breadcrumbs separator=">" aria-label="breadcrumb">
                            {
                                this.state.programData &&
                                this.breadcrumbLinks()
                            }
                        </Breadcrumbs>
                    </div>

                    <div style={{ width: '100%' }}>
                        <VariableList variables={variables} />
                    </div>


                    <div className={classes.navContainer}>
                        {
                            navComponent && navComponent
                        }
                    </div>
                </div>
            )
        }
    }

}

export default withRouter(withStyles(styles)(withLocalize(_ProgramInterface)));