import React from 'react';
import { styles } from "../styles";
import { withStyles } from "@material-ui/core";

class Codes extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.tabContainer}>
        <h1>Codes</h1>
      </div>
    );
  }
}

export default withStyles(styles)(Codes);