export const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "63px"
  },
  toggleGroup: {
    display: "flex",
  },
  appBarShit: {
    position: "fixed",
  },
  workOut: {
    color: "green",
    fontSize: 20,
  },
  table: {
    backgroundColor: 'green',
    padding: 0
  },
  buttonContainer: {
    display: "flex",
    marginTop: "30px",
    margin: "auto",
  },
  form: {
    display: "flex",
    maxWidth: 400,
    width: "80%",
    margin: "auto",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "75px",
    width: "90%",
    margin: "auto"
  },
  spacingInput: {
    margin: "0 10px 0 0",
    width: "100%",
  },
  maxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 5px 0",
  },
  metricSelect: {
    margin: 'auto'
  },
  textField: { padding: "0 5px 0 0" },
  selectField: { margin: "5px 10px 10px 0px", minWidth: "400" },
  labelRoot: {
    fontSize: 12,
  },
  root: {
    backgroundColor: 'red'
  },
  sectionText: {
    color: "#000000",
    fontFamily: "Jura",
    fontSize: 16,
    letterSpacing: 1.15,
    fontWeight: 600,
  },
  fieldText: {
    fontSize: 10,
    textAlign: "left",
    color: "#757575",
  },
  radioOptionGreen: {
    marginTop: 10,
    backgroundColor: "#e0ffde",
    padding: 10,
    margin: "auto",
    borderRadius: 10,
  },
  radioOptionYellow: {
    marginTop: 10,
    backgroundColor: "#f9ffd8",
    padding: 10,
    margin: "auto",
    borderRadius: 10,
  },
  radioOptionRed: {
    marginTop: 10,
    backgroundColor: "#f9d5d2",
    padding: 10,
    margin: "auto",
    borderRadius: 10,
  },
  radioButton: {
    margin: "auto",
  },
  radioHeader: {
    fontSize: 20,
    padding: 0,
    margin: -6,
    marginBottom: -12,
    fontWeight: "bold",
  },
  radioDescription: {
    textAlign: "left",
  },
  radio: {
    "&$checked": {
      color: "#3f51b5",
    },
  },
  checked: {
    fontColor: "#b0b0b0",
  },
});
