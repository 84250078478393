import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import './App.css';
import { withLocalize } from "react-localize-redux";
import Routes from '../routes/Router';
import { renderToStaticMarkup } from "react-dom/server";
import globalTranslations from "./global_translations.json";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { theme } from './theme'

class App extends Component {
  constructor(props) {
    super(props);

    this.props.initialize({
      languages: [
        { name: "Chinese", code: "中文", tag: "ch" },
        { name: "English", code: "ENG", tag: "en" },
        { name: "Korean", code: "한국어", tag: "kr" }
      ],
      translation: globalTranslations,
      options: {
        defaultLanguage: window.location.hostname.includes('liliang') ? '中文' : 'ENG',
        renderToStaticMarkup
      }
    });
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <Route render={props => <Routes {...props} />} />
          </MuiThemeProvider>
        </BrowserRouter>
      </div>
    );
  }
}

export default withLocalize(App);