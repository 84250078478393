import React from "react";
import { useTable } from 'react-table'
import Link from '@mui/material/Link';
import NativeSelect from '@mui/material/NativeSelect';
import { updateCoach } from '../api'

const renderText = (col, cell) => {
  let text = col !== 'Last login' ? cell.value : cell.value.split('.')[0]

  if (col !== 'Last login') {
    return (
      <div>
        {text}
      </div>
    )
  } else {
    return text
  }
}

const handleSelectChange = async (e, status, cognito, parent) => {
  console.log('value:', e.target.value)

  await updateCoach(parent.state.team, cognito, status, e.target.value)
    .then((data) => {
      window.location.href = window.location.href
    })
}

const clickButtons = (cell, parent) => {
  console.log('cell.row.values:', cell.row.values)
  let team = parent.state.team
  let coachCount = parent.state.coach_user_records.length
  let leadCoachCount = parent.state.coach_user_records.filter(x => x.leading_teams && x.leading_teams.includes(team)).length

  let email = cell.row.values.email
  let cognito = cell.row.values.cognito_id
  let leading_teams = cell.row.values.leading_teams ? cell.row.values.leading_teams : []
  let coaching_teams = cell.row.values.coaching_teams ? cell.row.values.coaching_teams : []

  let status
  if (leading_teams.includes(team)) {
    status = 'team leader'
  } else if (coaching_teams.includes(team)) {
    status = 'team coach'
  } else {
    status = 'pending coach'
  }

  let teamCoachEnabled = (leadCoachCount > 1 || status !=='team leader') && coachCount > 1
  let removeCoachEnabled = (leadCoachCount > 1 || status !=='team leader') && coachCount > 1

  return (
    <td
      {...cell.getCellProps()}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 0,
        padding: 3,
        fontSize: 13,
        backgroundColor: 'white',
        textAlign: "center",
        height: '100%'
      }}
      id={cell.value + 'actions'}
    >
      <NativeSelect
        value={status}
        disableUnderline={true}
        style={{ width: '100%' }}
        onChange={(e) => handleSelectChange(e, status, cognito, parent)}
        id={`${email}_select`}
      >
        <option value={'team leader'} style={{ textAlign: 'center' }} id={`${email}_leader`}>
          team leader
        </option>
        {teamCoachEnabled && <option value={'team coach'} style={{ textAlign: 'center' }} id={`${email}_coach`}>
          team coach
        </option>}
        {status == 'pending coach' && <option value={'pending coach'} style={{ textAlign: 'center' }} id={`${email}_pending`}>
          pending coach
        </option>}
        {removeCoachEnabled && <option value={'remove coach'} style={{ textAlign: 'center' }} id={`${email}_remove`}>
          remove coach
        </option>}
      </NativeSelect>
    </td >
  )
}

function CoachTable({ parent }) {
  let initialState = { hiddenColumns: ['cognito_id', 'leading_teams', 'coaching_teams', 'pending_coaching_teams'] }

  const data = parent.state.coach_user_records
  const columns = [
    { Header: 'First name', accessor: 'first_name' },
    { Header: 'Last name', accessor: 'last_name' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Last login', accessor: 'last_login_utc' },
    { Header: 'Status', accessor: 'start_date_utc' },
    { Header: 'Cognito', accessor: 'cognito_id' },
    { Header: 'Leading teams', accessor: 'leading_teams' },
    { Header: 'Coaching teams', accessor: 'coaching_teams' },
    { Header: 'Pending coaching teams', accessor: 'pending_coaching_teams' },
  ]

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data, initialState })

  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
      <table {...getTableProps()} style={{ border: 'solid 1px blue', width: '90%', backgroundColor: 'rgb(0, 33, 65)' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    border: 'solid 0.3px gray',
                    background: 'aliceblue',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} style={{ backgroundColor: 'white' }}>
                {row.cells.map(cell => {
                  let col = cell.column.Header

                  if (col === "Status") {
                    return clickButtons(cell, parent)
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          margin: 0,
                          padding: 3,
                          fontSize: 13,
                          backgroundColor: 'white',
                          textAlign: 'center'
                        }}
                        id={cell.value}
                      >
                        {renderText(col, cell)}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { CoachTable };