import { PaymentsTable } from "./BillingTable";
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  cancelSubscription,
  submitNewCharge,
  refundCharge,
  postXMonthPurchase,
  getStripeLink,
} from "../api";
import {
  PromoBox,
  AdminButtons,
  SubscriptionTierPurchase,
  RenderTermsAccept,
} from "../../../components/Form/paymentForm";
import { styles } from "../../onboarding/styles";

class BillingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      monthType: "",
    };
  }

  _DialogLoading = () => {
    return (
      <>
        <DialogTitle> Loading </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <CircularProgress size={25} color="inherit" />
        </DialogContent>
      </>
    );
  };

  _DialogContentCancel = () => {
    var { parent } = this.props;

    return (
      <div>
        <DialogTitle>Cancel subscription?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You may resubscribe at any time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              parent.setState({ dialogOpen: false, dialogType: "" })
            }
            autoFocus
            id="no_button"
          >
            No
          </Button>
          <Button onClick={() => cancelSubscription(parent)} id="yes_button">
            Yes
          </Button>
        </DialogActions>
      </div>
    );
  };

  _DialogContentRefund = () => {
    var { parent } = this.props;

    return (
      <div>
        <DialogTitle>Refund charge?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            This charge will be refunded and invalidated immediately.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              parent.setState({ dialogOpen: false, dialogType: "" })
            }
            autoFocus
          >
            No
          </Button>
          <Button onClick={(e) => refundCharge(e, parent, console.log)}>
            Yes
          </Button>
        </DialogActions>
      </div>
    );
  };

  _DialogContentCreateCharge = () => {
    var { parent } = this.props;
    var { classes } = parent.props;

    return (
      <div>
        <DialogTitle>Create charge</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Charge amounts are in USD pennies, so 200 = $2. Create a charge with
            amount 0 to give an account free credit. A charge keeps an account
            active for one month, so creating a charge one month in the future
            gives it two months credit.
          </DialogContentText>
          <TextField
            value={parent.state.amount}
            label="Amount"
            name="amount"
            id="amount_field"
            onChange={(evt) => parent.setState({ amount: evt.target.value })}
            style={{ flex: 1 }}
            InputLabelProps={{ classes: { root: classes.labelRoot } }}
            type="text"
            inputProps={{
              autoComplete: "off",
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
          <br />
          <TextField
            value={parent.state.payment_date}
            label="Payment date (YYYY-MM-DD)"
            name="payment_date"
            id="payment_date_field"
            onChange={(evt) =>
              parent.setState({ payment_date: evt.target.value })
            }
            style={{ flex: 1 }}
            InputLabelProps={{ classes: { root: classes.labelRoot } }}
            type="text"
            inputProps={{
              autoComplete: "off",
              autoCorrect: "off",
              autoCapitalize: "off",
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              parent.setState({ dialogOpen: false, dialogType: "" })
            }
            autoFocus
            id="cancel_button"
          >
            Cancel
          </Button>
          <Button
            onClick={(e) => submitNewCharge(e, parent, console.log)}
            id="create_button"
          >
            Create
          </Button>
        </DialogActions>
      </div>
    );
  };

  _DialogContentMonthly = (
    saleData,
    hasSubscribed,
    webSignup,
    setIsLoading
  ) => {
    var { parent } = this.props;

    return (
      <div>
        <DialogTitle>Confirm purchase</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Click yes to purchase a month of credit.</p>
            <p>
              If you have a billing method on file, it will be charged
              immediately.
            </p>
            <p>
              If you do not, you will be brought to a page to register a billing
              method.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              parent.setState({ dialogOpen: false, dialogType: "" })
            }
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              setIsLoading(true);
              if (hasSubscribed && webSignup) {
                postXMonthPurchase(parent, 1, saleData["percent"]);
              } else {
                getStripeLink(parent, 1, saleData["percent"], hasSubscribed);
              }
            }}
            id="yesButton"
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    );
  };

  _DialogContentYearly = (saleData, hasSubscribed, webSignup, setIsLoading) => {
    var { parent } = this.props;

    return (
      <div>
        <DialogTitle>Confirm purchase</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Click yes to purchase a year of credit.</p>
            <p>
              If you have a billing method on file, it will be charged
              immediately.
            </p>
            <p>
              If you do not, you will be brought to a page to register a billing
              method.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              parent.setState({ dialogOpen: false, dialogType: "" })
            }
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              setIsLoading(true);
              if (hasSubscribed && webSignup) {
                postXMonthPurchase(parent, 12, saleData["percent"]);
              } else {
                getStripeLink(parent, 12, saleData["percent"], hasSubscribed);
              }
            }}
            id="yesButton"
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    );
  };

  _renderDialogContent = (saleData, hasSubscribed, webSignup) => {
    const [isLoading, setIsLoading] = useState(false);
    // const dialogType = this.state.monthType;
    let parentDialogType = this.props.parent.state.dialogType;

    if (isLoading) {
      return this._DialogLoading();
    } else if (parentDialogType === "cancel") {
      return this._DialogContentCancel();
    } else if (parentDialogType === "createCharge") {
      return this._DialogContentCreateCharge();
    } else if (parentDialogType === "refundCharge") {
      return this._DialogContentRefund();
    } else if (parentDialogType === "1MonthPurchase") {
      return this._DialogContentMonthly(
        saleData,
        hasSubscribed,
        webSignup,
        setIsLoading
      );
    } else if (parentDialogType === "12MonthPurchase") {
      return this._DialogContentYearly(
        saleData,
        hasSubscribed,
        webSignup,
        setIsLoading
      );
    }
  };

  _renderDialog = (saleData, hasSubscribed, webSignup) => {
    const { parent } = this.props;
    const dialogOpen = parent.state.dialogOpen;
    return (
      <Dialog
        // open={this.state.modalOpen || this.props.parent.state.dialogOpen}
        open={dialogOpen}
        onClose={() => parent.setState({ dialogOpen: false })}
      >
        {this._renderDialogContent(saleData, hasSubscribed, webSignup)}
      </Dialog>
    );
  };

  render() {
    const { parent } = this.props;
    const { classes } = parent.props;
    let paymentMethod = parent.state.payment_method
      ? parent.state.payment_method.toUpperCase()
      : "NONE";
    paymentMethod =
      paymentMethod === "APP_STORE"
        ? "APP STORE"
        : paymentMethod === "PLAY_STORE"
        ? "PLAY STORE"
        : paymentMethod;

    const storeCancelStyle = {
      display: "flex",
      flexDirection: "column",
      marginTop: "13px",
      padding: "15px 20px",
      justifyContent: "center",
      alignItems: "center",
      minWidth: 300,
      maxWidth: 700,
      fontStyle: "italic",
      alignSelf: "center",
      backgroundColor: "red",
      fontSize: "medium",
      fontWeight: "700",
      color: "white",
      border: "1px solid black",
      gap: 12,
    };

    return (
      <div className={classes.container}>
        <div style={{ display: "flex", flexDirection: "column", margin: 20 }}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ margin: 5 }}>Subscription method:</div>
            <div
              style={{
                margin: 5,
                fontWeight: 700,
                color: parent.state.payment_method ? null : "red",
              }}
            >
              {paymentMethod}
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ margin: 5 }}>Next billing date:</div>
            <div style={{ margin: 5, fontWeight: 700 }} id="nextBillingDate">
              {parent.state.next_bill_date}
            </div>
          </div>
          {paymentMethod.toUpperCase() === "CARD" ||
          paymentMethod.toUpperCase() === "NONE" ? (
            <>
              <SubscriptionTierPurchase parent={parent} modalControl={this} />
              <RenderTermsAccept classes={styles} parent={parent} />
              <PromoBox parent={parent} />
            </>
          ) : null}
          {paymentMethod.toUpperCase() === "CARD" &&
          parent.state.next_bill_date ? (
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                id="cancel_button"
                color="primary"
                type="submit"
                onClick={(e) => {
                  parent.setState({
                    dialogOpen: true,
                    dialogType: "cancel",
                  });
                }}
                disabled={parent.state.loading}
                style={{ width: "100%", color: "#fff", maxWidth: 290 }}
              >
                Cancel Subscription
              </Button>
            </div>
          ) : paymentMethod.toUpperCase() === "APP STORE" ? (
            <div style={storeCancelStyle}>
              <div>
                Annual package only available for credit card purchases. <br />{" "}
                If you wish to switch over, please cancel from within the App
                first.
              </div>
              <div>
                Apple Pay subscriptions are managed in iCloud settings, under
                "Subscriptions".
              </div>
            </div>
          ) : paymentMethod.toUpperCase() === "PLAY STORE" ? (
            <div style={storeCancelStyle}>
              <div>
                Annual package only available for credit card purchases. <br />{" "}
                If you wish to switch over, please cancel from within the App
                first.
              </div>
              <div>
                To cancel your subscription, please review your subscriptions in
                the Google Play Store.
              </div>
            </div>
          ) : null}
          <AdminButtons parent={parent} />
        </div>
        <PaymentsTable admin={parent.state.admin} parent={parent} />
      </div>
    );
  }
}

export default BillingTab;
