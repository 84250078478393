import React from 'react';
import { Grid, Button, Container } from '@material-ui/core';
import VolIntChart from './Chart'

export default ({ phases, programId, classes, parent }) => {
  var total_micros = phases['hypertrophy'].length + phases['strength'].length + phases['peaking'].length

  const color_scheme = {
    "hypertrophy": "#00c500",
    "strength": "#d4d400",
    "peaking": "#f10000"
  }

  console.log('phases:', phases)

  return (
    <Container
      style={{
        height: '100%',
        flexGrow: 100,
        width: '100%',
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '5000px'
      }}>
      <div
        style={{
          marginLeft: `${35 / total_micros}vw`,
          marginRight: `${35 / total_micros}vw`
        }}
      >
        <VolIntChart parent={parent} />
      </div>
      <Grid
        container
        alignItems={'stretch'}
        wrap='nowrap'
        spacing={0}
        justifyContent={'space-around'}
        style={{ flexGrow: 100, display: 'flex' }}
      >
        {
          Object.entries(phases).map(([phase, micros]) =>
            <Grid
              item
              key={phase.substr(0)}
              style={{
                // width: 'fit-content',
                flexGrow: micros.length / total_micros * 100,
                display: 'flex'
              }} >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: 0,
                  backgroundColor: color_scheme[phase],
                  flexGrow: 100
                }}
              >
                {phase.substr(0)}
                <Grid
                  container
                  wrap='nowrap'
                  alignItems={'center'}
                  spacing={0}
                  style={{
                    flexGrow: 100,
                    display: 'flex',
                    alignItems: 'stretch',
                  }}
                >
                  {
                    micros.map((micro, i) =>
                      <Grid
                        item
                        key={micro.micro_id}
                        style={{
                          flexGrow: 100,
                          display: 'flex',
                          alignItems: 'stretch',
                          backgroundColor: 'white',
                          flexDirection: 'column',
                          border: '1px solid black',
                        }}
                        onClick={() =>
                          parent.props.history.push(`${programId}/micro/${micro.micro_id}`)
                        }
                      >
                        <div className={classes.navVariable} >
                          <div className={classes.navVariableName}>Micro</div>
                          <div className={classes.navVariableValue}>{micro.micro_count + 1}</div>
                        </div>
                        <div className={classes.navVariable}>
                          <div className={classes.navVariableName}>Intensity</div>
                          <div className={classes.navVariableSmallValue}>{micro.avg_intensity}</div>
                        </div>
                        <div className={classes.navVariable}>
                          <div className={classes.navVariableName}>Volume</div>
                          <div className={classes.navVariableSmallValue}>{micro.volume}</div>
                        </div>
                        <div className={classes.navVariable}>
                          <div className={classes.navVariableName}>Done</div>
                          <div className={classes.navVariableSmallValue}>{micro.pct_complete}%</div>
                        </div>
                      </Grid>
                    )
                  }
                </Grid>
              </div>
            </Grid>
          )
        }
      </Grid>
    </Container>
  );
}