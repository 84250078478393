import { ProgramTable } from "./ProgramTable";
import React from "react";

class ProgramTab extends React.Component {

  render() {
    var { parent } = this.props
    var { classes } = parent.props
    var paymentMethod = parent.state.payment_method ? parent.state.payment_method.toUpperCase() : "NONE"
    paymentMethod = paymentMethod === "APP_STORE" ? "APP STORE" : paymentMethod

    return (
      <div className={classes.container}>
        <ProgramTable
          coach={parent.state.coach}
          parent={parent}
        />
      </div >
    )
  }
}

export default ProgramTab;