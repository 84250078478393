import React from 'react';

import { TextField } from "@material-ui/core";

import { Translate } from "react-localize-redux";

const EnterHeight = (props, handleChange) => {
  const { classes, parent } = props

  return (
    <TextField
      value={parent.state.height}
      error={parent.state.errors.includes("height")}
      required={true}
      label={<Translate id="height" />}
      name={"height"}
      type="text"
      inputProps={{ inputMode: 'numeric' }}
      style={{ margin: "0 5px 0 0" }}
      onChange={(e) => { handleChange(e, parent) }}
      fullWidth
    />
  )
}

export default EnterHeight;