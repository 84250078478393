import React from 'react';
import { withRouter } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { SwipeableDrawer, List, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { listItems } from './helpers';
import Item from './Item';
import google_store from '../../assets/google_store.png';
import apple_store from '../../assets/apple_store.png';

class _Menu extends React.Component {
  state = {
    drawerOpen: false,
    purchased: false,
    groups: [],
    rowsPerPage: 10,
  };

  async componentDidMount() {
    const purchased = this.props.purchased

    await Auth.currentSession().then((value) => {
      const groups = value.accessToken.payload['cognito:groups']
      console.log(groups)
      this.setState({ groups, purchased })
    })
  }

  toggleDrawer = () => { this.setState({ drawerOpen: !this.state.drawerOpen }) };

  handleSignOut = () => {
    const handleHeaderSignOut = this.props.handleHeaderSignOut

    return Auth.signOut()
      .then(() => {
        this.props.history.push('/auth');
        handleHeaderSignOut()
      })
      .catch(() => console.log('error signing out...'));
  };

  renderAppCTA = () => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          margin: 15,
          gap: 7
        }}
      >
        <div
          style={{
            textAlign: 'center',
            fontWeight: '600'
          }}
        >
          It's better on the app!
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'flex-start',
          }}
        >
          <img
            style={{
              display: 'flex',
              flexShrink: 1,
              maxWidth: '45%'
            }}
            src={apple_store}
            alt=""
            onClick={() => {
              const win = window.open(`https://apps.apple.com/us/app/weightlifting-ai/id1602877760`, "_blank");
              win.focus();
            }}
          />
          <img
            style={{
              display: 'flex',
              flexShrink: 1,
              maxWidth: '45%'
            }}
            src={google_store}
            alt=""
            onClick={() => {
              const win = window.open(`https://play.google.com/store/apps/details?id=com.weightliftingHouse.rn`, "_blank");
              win.focus();
            }}
          />
        </div>
      </div>
    )
  }

  render() {

    const groups = this.state.groups ? this.state.groups : []

    return (
      <div>
        <IconButton onClick={() => this.toggleDrawer()} id='menu_button'>
          <MenuIcon style={{ color: '#fff' }} />
        </IconButton>
        <SwipeableDrawer
          anchor='right'
          open={this.state.drawerOpen}
          onOpen={() => this.toggleDrawer()}
          onClose={() => this.toggleDrawer()}
        >
          <div
            tabIndex={0}
            role='button'
            onClick={() => this.toggleDrawer()}
            onKeyDown={() => this.toggleDrawer()}
          >
            <div style={{ width: 250 }}>
              <List>
                {listItems.map(item => {
                  if ((!item.buyers_only || this.state.purchased)
                    &&
                    (!item.coaches_only || groups.includes('COACHES'))
                    &&
                    (!item.admins_only || groups.includes('SUPERADMINS'))) {
                    return (
                      <Item
                        key={item.link}
                        listItem={item}
                        handleSignOut={this.handleSignOut}
                      />
                    )
                  } else { return null }
                })}
              </List>
            </div>
          </div>
          {this.renderAppCTA()}
        </SwipeableDrawer>
      </div >
    );
  }
};

export default withRouter(_Menu);
