import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { Button, withStyles, TextField, CircularProgress } from '@material-ui/core';
import mixpanel from 'mixpanel-browser';
import { styles } from './styles';
import { Translate } from "react-localize-redux";

const _Reset = ({ classes, email, history, translate }) => {
  const [resetCode, setResetCode] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleReset = e => {
    setLoading(true)
    e.preventDefault();
    mixpanel.identify(email)
    mixpanel.track('reset password attempt');

    Auth.forgotPasswordSubmit(email, resetCode, password)
      .then(() => {
        mixpanel.track('reset password submit success');
        Auth.signIn(email, password).then(() => {
          window.location.assign(`/`)
        })
      })
      .catch(err => {
        console.log('ERROR:', err)
        mixpanel.track('reset password submit error', { 'code': err.code, 'message': err.message });
        if (err.code === 'ExpiredCodeException') {
          setError(translate("invalidCode"))
        } else {
          setLoading(false)
          setError(err.message)
        }
      });
  };

  return (
    <div className={classes.signinContainer}>
      <form className={classes.formContainer}>
        <div>
          <p><Translate id="pleaseCheck" /></p>
        </div>
        <TextField
          value={resetCode}
          label={'Reset Code'}
          name={'resetCode'}
          type='number'
          onChange={e => setResetCode(e.target.value)}
          fullWidth
        />
        <TextField
          value={password}
          label={'New Password'}
          name={'newPassword'}
          type='password'
          onChange={e => setPassword(e.target.value)}
          fullWidth
        />
        <div className={classes.buttonContainer}>
          <Button
            id="resetButton"
            classes={{ root: classes.onboardRoot, label: classes.onboardLabel }}
            onClick={handleReset}
            type="submit"
            variant="outlined"
          >
            {loading ? (
              <CircularProgress size={15} color="secondary" />
            ) : (
              <Translate id="reset" />
            )}
          </Button>
        </div>
        {error && <p className={classes.error}>{error}</p>}
      </form>
    </div>
  );
};

export const Reset = withStyles(styles)(_Reset);
