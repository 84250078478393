import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { Translate } from "react-localize-redux";

const Item = (props) => {
  const { icon, text_en, secondary_action } = props.listItem; //removed text prop
  // Handler
  const handleClick = (item) => {
    console.log('handling click for item:', item)
    if (item.link === `/auth`) {
      return props.handleSignOut();
    } else {
      return props.history.push(item.link);
    }
  };

  if (secondary_action) {
    return (
      <Translate>
        {(translate) =>
          <div>
            <ListItem
              button
              onClick={() => handleClick(props.listItem)}
              id={'menu_' + text_en}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={translate.translate(text_en)} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleClick(secondary_action)}>
                  {secondary_action.icon}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider />
          </div>
        }
      </Translate>
    );
  } else {
    return (
      <Translate>
        {(translate) =>
          <div>
            <ListItem
              button
              onClick={() => handleClick(props.listItem)}
              id={'menu_' + text_en}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={translate.translate(text_en)} />
            </ListItem>
            <Divider />
          </div>
        }
      </Translate>
    );
  }
};

export default withRouter(Item);
