import { connect } from 'react-redux';

import _Coach from './layout';
import { updateUserData, fetchUserData } from '../../state/thunk';
import { toggleLoading } from '../../state/actions';

const mapStateToProps = ({ userReducer }) => ({
  userData: userReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (pl) => dispatch(updateUserData(pl)),
  fetchUserData: (userName) => dispatch(fetchUserData(userName)),
  toggleLoading: () => dispatch(toggleLoading()),
});

const Coach = connect(mapStateToProps, mapDispatchToProps)(_Coach);
export default Coach;