import React from 'react'
import { withRouter } from 'react-router-dom'
import {
  withStyles,
  FormControl,
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  Link
} from '@material-ui/core'
import { Auth } from "aws-amplify";
import { registerCoach, submitCoachCode } from './api';
import wai_icon from '../../assets/1024.png';
import { styles } from './styles'
import { connect } from 'react-redux';
import { updateUserData, fetchUserData } from '../../state/thunk';
import { toggleLoading } from '../../state/actions';
import { getTranslate } from "react-localize-redux";
import mixpanel from 'mixpanel-browser';


class OnboardingCoach extends React.Component {
  state = {
    cognito_id: "",
    username: "",
    loading: false,
    errors: [],
    firstName: "",
    lastName: "",
    password: "",
    email: "",
    loggedIn: false,
    termsAccepted: false,
    coach_code: ""
  }

  async componentDidMount() {
    mixpanel.track(`coach signup screen`);

    // if they are logged in then get them outta here
    await Auth.currentSession()
      .then((sessionRes) => {
        console.log('sessionRes', sessionRes)
        this.setState({ cognito_id: sessionRes.accessToken.payload.sub, loggedIn: true })
      })
      .catch((e) => {
        console.log('not logged in, ready to signup')
      })

    // console.log('state after componentDidMount:', this.state)
  }

  validateAndSubmit = async () => {
    // validate
    if (this.state.coach_code === "") {
      this.setState({ plError: "Please fill out coach code!" })
    } else {
      // submit
      if (this.state.loggedIn) {
        // this user is logged in, no more validation needed
        this.setState({ plError: "", loading: true })
        await submitCoachCode(this.state.cognito_id, this.state.coach_code, this)
      } else {
        // more validation
        if (
          this.state.firstName === "" || this.state.lastName == "" || this.state.email === "" ||
          !this.state.email.includes('@')
        ) {
          this.setState({ plError: "Please fill out all fields!" })
        } else if (this.state.password.length < 6) {
          this.setState({ plError: "Password must be six or more characters" })
        } else if (!this.state.termsAccepted) {
          this.setState({ plError: "Please accept terms and conditions" })
        } else {
          await registerCoach(this)
        }
      }
    }
  }

  toggleLoading = () => {
    this.setState({ loading: !this.state.loading })
  }

  handleChange = (e) => {
    const { value, name } = e.target

    this.setState({ [name]: value })
  }

  handleError = (error) => {
    if (typeof error === 'object' && error.message) {
      error = error.message
    } else if (typeof error === 'object') {
      error = JSON.stringify(error)
    }
    this.setState({
      loading: false,
      plError: error,
    })
  }

  getErrorDiv = (plError, classes) => {
    if (plError) {
      return (
        <div className={classes.errorDiv}>
          {plError}
        </div>
      )
    }
  }

  renderTermsAccept = () => {
    let classes = this.props.classes

    return (
      <div className={classes.termsContainer}>
        <div className={classes.termsCheckbox}>
          <Checkbox
            style={{ padding: 0 }}
            checked={this.state.termsAccepted}
            onChange={() => this.setState({ termsAccepted: !this.state.termsAccepted })}
            id="acceptTerms"
            color="primary"
          />
        </div>
        <div className={classes.termsWords}>I agree to the
          <Link href="/terms" underline="none" target="_blank"> Terms and Conditions</Link> <br />and
          <Link href="/privacy" underline="none" target="_blank"> Privacy Policy</Link>.</div>
      </div>
    )
  }

  getFormComponent = (classes) => {
    if (this.state.loggedIn) {
      return (
        <div className={classes.formFieldsContainer}>
          <TextField
            value={this.state.coach_code}
            required={true}
            label="Team code"
            name="coach_code"
            onChange={e => this.handleChange(e)}
            fullWidth
            style={{ marginBottom: 13, marginTop: 20 }}
          />
        </div>
      )
    } else {
      return (
        <div className={classes.formFieldsContainer}>
          <TextField
            value={this.state.firstName}
            required={true}
            label="First name"
            name="firstName"
            onChange={e => this.handleChange(e)}
            fullWidth
            style={{ marginBottom: 13 }}
          />
          <TextField
            value={this.state.lastName}
            required={true}
            label="Last name"
            name="lastName"
            onChange={e => this.handleChange(e)}
            fullWidth
            style={{ marginBottom: 13 }}
          />
          <TextField
            value={this.state.email}
            required={true}
            label="Email"
            name="email"
            onChange={e => this.handleChange(e)}
            fullWidth
            style={{ marginBottom: 13 }}
          />
          <TextField
            inputProps={{
              autoComplete: "new-password",
              form: { autocomplete: "off" },
            }}
            value={this.state.password}
            required={true}
            label="Password"
            name="password"
            type="password"
            onChange={e => this.handleChange(e)}
            fullWidth
            style={{ marginBottom: 13 }}
          />
          <TextField
            value={this.state.coach_code}
            required={true}
            label="Team code"
            name="coach_code"
            onChange={e => this.handleChange(e)}
            fullWidth
            style={{ marginBottom: 13, marginTop: 20 }}
          />
        </div>
      )
    }
  }

  getButton = (classes, btnText, plError) => {
    return (
      <div className={classes.formButtonSmallContainer}>
        {this.getErrorDiv(plError, classes)}
        <Button
          className={(classes.formButtonSmall + ' ' + classes.nextBtnWH)}
          onClick={() => this.validateAndSubmit()}
          name="next"
        >
          {this.state.loading ? <CircularProgress
            size={25}
            classes={{ circle: classes.circularProgressPrimaryColor }}
          /> : btnText}
        </Button>
      </div>
    )
  }

  getExplainText = (loggedIn) => {
    if (loggedIn) {
      return "To register as a coach please enter the coach team code below:"
    } else {
      return `To register as a coach please fill out the form below. The team code is available to the
        team's lead coach in the Manage Team screen.`
    }
  }

  getIconAndExplain = (classes, loggedIn) => {
    return (
      <div className={classes.formStepContent}>
        <div className={classes.explainMessage}>
          <div className={classes.iconContainer}>
            <img
              className={classes.iconObject}
              src={wai_icon}
              alt=""
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div style={{ paddingBottom: 10 }}>
              {this.getExplainText(loggedIn)}
            </div>
          </div>
        </div>
      </div>
    )
  }


  render() {
    const { classes } = this.props
    const { plError, loggedIn } = this.state

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', paddingTop: 80 }}>
        {this.getIconAndExplain(classes, loggedIn)}
        <div className={classes.stepFormContainer}>
          <FormControl fullWidth={true} className={classes.formControlContainer}>
            {this.getFormComponent(classes)}
            {!loggedIn && this.renderTermsAccept()}
          </FormControl>
        </div>
        {this.getButton(classes, "SUBMIT", plError)}
      </div>
    )
  }
}

const mapStateToProps = ({ userReducer, localize }) => ({
  userData: userReducer,
  translate: getTranslate(localize)
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (pl) => dispatch(updateUserData(pl)),
  fetchUserData: (userName) => dispatch(fetchUserData(userName)),
  toggleLoading: () => dispatch(toggleLoading()),
});

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(OnboardingCoach)))
