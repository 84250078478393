import React from 'react';
import { withStyles } from '@material-ui/core';
import mixpanel from 'mixpanel-browser';
import { styles } from '../auth/styles';
import { connect } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { fetchUserData } from '../../state/thunk';
import { Auth } from 'aws-amplify'
import { convertQueryParamsToJSON } from '../../utils/helpers'
import { withRouter } from 'react-router-dom';

const ShowBackdrop = (challengeCode, classes) => {
    console.log('running showBackdrop')
    if (challengeCode) {
        return (
            <Backdrop
                className={classes.backdrop}
                open={true}
                invisible={true}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        )
    }
}

class __AutoLogin extends React.Component {
    state = { challengeCode: false }


    async componentDidMount() {
        const queryParams = convertQueryParamsToJSON()
        const email = queryParams.email || null
        const challengeCode = queryParams.challenge || null

        mixpanel.identify(email)
        mixpanel.track("autologin attempt")

        console.log('queryParams:', queryParams)
        this.setState({ challengeCode })

        if (email && challengeCode) {
            try {
                const cognitoUser = await Auth.signIn(email)
                await Auth.sendCustomChallengeAnswer(cognitoUser, challengeCode)
                const sessionRes = await Auth.currentSession()
                const cognito_id = sessionRes.accessToken.payload.sub

                if (sessionRes && sessionRes.idToken?.jwtToken) {
                    mixpanel.track("autologin success")
                    await this.props.fetchUserData(cognito_id);
                    this.props.updateAuth(true)
                    this.props.history.push('/')
                }
            } catch (err) {
                console.error('challenge login error --> ', err)
                this.props.history.push('/')
            }
        } else {
            this.props.history.push('/')
        }
    }

    render() {
        const { classes } = this.props;

        return (
            <div>
                mixpanel.track('view autologin page');
                <ShowBackdrop classes={classes} challengeCode={this.state.challengeCode} />
            </div>
        );
    }
};

const AutoLogin = withRouter(withStyles(styles)(__AutoLogin))

const mapStateToProps = ({ userReducer }) => ({
    userData: userReducer,
});

const mapDispatchToProps = (dispatch) => ({
    fetchUserData: (cognito_id) => dispatch(fetchUserData(cognito_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoLogin)