import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import {
  withStyles,
  Backdrop,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import "date-fns";
import { styles } from "./styles";
import mixpanel from "mixpanel-browser";
import BillingTab from "./components/BillingTab";
import ProgramTab from "./components/ProgramTab";
import PersonalTab from "./components/PersonalTab";
import { AppBar, Tabs, Tab } from "@material-ui/core";
import { withLocalize } from "react-localize-redux";
import { deleteUser, deleteTeam } from "./api";
import { basicMaxs } from "../../utils/constants";
import { adjustMaxs } from "../../utils/helpers";

import { Auth } from "aws-amplify";

class _Profile extends React.Component {
  state = {
    email: "",
    comp_styles: {
      squat: "low-bar back squat",
      bench: "medium-grip bench press",
      deadlift: "sumo deadlift",
      jerk: "split_jerk",
    },
    errors: [],
    charges: [],
    maxs: basicMaxs,
    loading: false,
    pageLoading: true,
    cognito_id: "",
    difficulty: "hard",
    height: "",
    bodyweight: "",
    male: true,
    birthday: "",
    birth_year: 2000,
    birth_month: 1,
    birth_day: 1,
    pageToDisplay: "Profile",
    entrymin: 0,
    entrymax: 999,
    metric: true,
    program: "weightlifting",
    programs: [],
    payment_method: null,
    next_bill_date: "",
    payTabToDisplay: "CREDITCARD",
    dialogOpen: false,
    promoCode: "",
    appliedPromo: false,
    coach: false,
    admin: false,
    dialogType: "cancel",
    deleteEmailField: "",
    deleteDialog: false,
    deleteLoading: false,
    teamCode: "",
  };

  async componentDidMount() {
    const { userData } = this.props;
    mixpanel.track("view profile");

    const urlParams = new URLSearchParams(window.location.search);
    const param_tab = urlParams.get("tab");

    if (param_tab === "payment") {
      this.setState({ pageToDisplay: "Billing" });
    }

    let cognito_id = userData.cognito_id;

    await Auth.currentSession().then((value) => {
      const payload = value.accessToken.payload;
      const groups = payload["cognito:groups"] ? payload["cognito:groups"] : [];

      console.log("AUTH payload:", payload);

      if (window.location.pathname.split("/").length > 3) {
        if (!groups.includes("COACHES")) {
          console.log("user trying to access param cognito id is not an coach");
          return this.props.history.push("/profile");
        } else {
          cognito_id = window.location.pathname.split("/")[3];
          this.setState({
            coach: groups.includes("COACHES"),
            admin: groups.includes("ADMINS"),
            cognito_id,
          });
        }
      } else {
        cognito_id = payload.sub;
        this.setState({ cognito_id });
      }
    });

    console.log("window.location:", window.location);

    await this.props
      .fetchUserData(cognito_id, this.state.coach)
      .then((data) => {
        if (!data.metric) {
          data.bodyweight = Math.round(data.bodyweight * 2.20462);
          data.maxs = adjustMaxs(data.maxs, true);
        }
        data.pageLoading = false;

        // do some just in case stuff for user profiles that somehow become corrupted
        if (data.birthday === null) {
          data.birthday = "2000-1-1";
        }
        if (data.male === null) {
          data.male = true;
        }

        // assign over variables to state
        var state = this.state;
        state = Object.assign(state, data);

        // split up date of birth
        state.birth_year = parseInt(data.birthday.split(" ")[0].split("-")[0]);
        state.birth_month = parseInt(data.birthday.split(" ")[0].split("-")[1]);
        state.birth_day = parseInt(data.birthday.split(" ")[0].split("-")[2]);

        state.state_copy = state;
        state.payTabToDisplay = "CREDITCARD";

        this.setState(state);
        // console.log('state after componentDidMount:', this.state)
      });
  }

  handleValidationError = (errors) => {
    let plError = `${this.props.translate("pleaseFillInData")} ${errors}`;

    this.setState({
      errors,
      plError,
      loading: false,
    });
  };

  handleResetPassword() {
    console.log("called function handleResetPassword");
  }

  handleSudoUser(showAll) {
    console.log("called function handleSudoUser");
    this.props.history.push(
      `/workouts?cognito_id=${this.state.cognito_id}&showAll=${showAll}`
    );
  }

  handleDeleteUser = async () => {
    console.log("called function handleDeleteUser");
    await this.setState({ deleteContent: "user" });
    this.setState({ deleteDialog: true, deleteEmailField: "" });
  };

  handleDeleteTeam = async () => {
    console.log("called function handleDeleteTeam");
    await this.setState({ deleteContent: "team" });
    this.setState({ deleteDialog: true, deleteEmailField: "" });
  };

  handleDeleteUserConfirm = async () => {
    console.log("called function handleDeleteUserConfirm");

    if (this.state.email === this.state.deleteEmailField) {
      // the correct email was typed in, we will let them delete
      await deleteUser(this)
        .then(() => this.props.history.push(`/coach`))
        .catch(() => console.log("error!"));
    }
  };

  handleDeleteTeamConfirm = async () => {
    console.log("called function handleDeleteTeamConfirm");

    await deleteTeam(this)
      .then(() => this.props.history.push(`/profile`))
      .catch(() => console.log("error!"));
  };

  RenderDialog = () => {
    if (this.state.deleteContent === "team") {
      return (
        <Dialog
          open={this.state.deleteDialog}
          onClose={() => this.setState({ deleteDialog: false })}
        >
          <DialogTitle>Leave Team</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you are sure you would like to leave this team, click CONFIRM
              below. Team coaches will no longer be able to view your training.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ deleteDialog: false })}>
              Cancel
            </Button>
            <Button
              onClick={() => this.handleDeleteTeamConfirm()}
              disabled={this.state.deleteLoading}
            >
              {this.state.deleteLoading ? (
                <CircularProgress color="black" size={20} />
              ) : (
                "CONFIRM"
              )}
            </Button>
          </DialogActions>
        </Dialog>
      );
    } else {
      return (
        <Dialog
          open={this.state.deleteDialog}
          onClose={() => this.setState({ deleteDialog: false })}
        >
          <DialogTitle>Delete Account</DialogTitle>
          <DialogContent>
            <DialogContentText>
              If you are ABSOLUTELY sure you want to delete this account, type{" "}
              {this.state.email} below and click DELETE. Please note that this
              is PERMANENT.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="DELETE"
              fullWidth
              variant="standard"
              value={this.state.deleteEmailField}
              onChange={(event) =>
                this.setState({ deleteEmailField: event.target.value })
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.setState({ deleteDialog: false })}>
              Cancel
            </Button>
            <Button
              onClick={() => this.handleDeleteUserConfirm()}
              disabled={this.state.deleteLoading}
            >
              DELETE
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  };

  handleResetSubscriptionStatus = () => {
    console.log("Resetting subscription status");

    const paymentsUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/payments`;
    const config = { headers: { "Content-Type": "application/json" } };
    const cognito_id = this.state.cognito_id;

    const url = `${paymentsUrl}/${cognito_id}?source=web&type=cancel`;

    return axios
      .delete(url, config)
      .then((data) => {
        console.log("from delete:", data);
        alert("Success! Please refresh the page to confirm");
      })
      .catch((error) => console.log("error:", error));
  };

  RenderTabBar(classes) {
    const { pageToDisplay, metric } = this.state;
    const handlePageChange = (event, page) => {
      this.setState({ pageToDisplay: page });
    };

    let tabs;
    if (this.state.admin) {
      tabs = ["Profile", "Billing", "Programs"];
    } else {
      tabs = ["Profile", "Billing"];
    }

    return (
      <div className={classes.toggleGroup}>
        <AppBar
          position="static"
          color="default"
          className={classes.appBarShit}
        >
          <Tabs
            value={pageToDisplay}
            onChange={handlePageChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            {tabs.map((tab) => (
              <Tab id={tab} key={tab} value={tab} label={tab} />
            ))}
          </Tabs>
        </AppBar>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { pageToDisplay } = this.state;

    if (this.state.pageLoading) {
      return (
        <Backdrop open={true} invisible={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      return (
        <div className={classes.container}>
          {this.RenderTabBar(classes)}
          {pageToDisplay === "Profile" && <PersonalTab parent={this} />}
          {pageToDisplay === "Billing" && <BillingTab parent={this} />}
          {pageToDisplay === "Programs" && <ProgramTab parent={this} />}
          {this.RenderDialog()}
        </div>
      );
    }
  }
}

export default withRouter(withStyles(styles)(withLocalize(_Profile)));
