import React from "react";
import { Button } from "@material-ui/core";
import {
  UserForm,
  MaxsForm,
  EmailField,
  TeamField,
} from "../../../components/Form/userForm";
import { handleSubmit } from "../api";
import { Translate } from "react-localize-redux";

class PersonalTab extends React.Component {
  RenderCoachControls = (classes, parent) => {
    let admin = parent.state.admin;
    let coach = parent.state.coach;
    console.log("payment method", parent.state.payment_method);

    console.log("rendering coach controls!!");
    return (
      <div className={classes.profileCoachSideContainer}>
        <div className={classes.smallButtonContainer}>
          <Button
            variant="contained"
            id="submit_button"
            color="default"
            type="submit"
            onClick={() => parent.handleSudoUser(false)}
            disabled={parent.state.loading}
            style={{ width: "90%" }}
          >
            SUDO USER
          </Button>
          <div className={classes.smallButtonDescription}>
            Sudo User goes to /workouts literally as the user, meaning if the
            user is overdue in payment or if their program is on hold then you
            will not be able to view the program. Good for seeing exactly what
            the user sees on the website.
          </div>
        </div>
        <div className={classes.smallButtonContainer}>
          <Button
            variant="contained"
            id="submit_button"
            color="default"
            type="submit"
            onClick={() => parent.handleSudoUser(true)}
            disabled={parent.state.loading}
            style={{ width: "90%" }}
          >
            SUDO FULL PROGRAM
          </Button>
          <div className={classes.smallButtonDescription}>
            Sudo Full Program goes to /workouts and shows the entirety of the
            users current program, regardless of whether it is on hold or if
            they have paid.
          </div>
        </div>
        {admin && (
          <div className={classes.smallButtonContainer}>
            <Button
              variant="contained"
              id="submit_button"
              color="default"
              type="submit"
              onClick={() => parent.handleDeleteUser()}
              disabled={parent.state.loading}
              style={{ width: "90%" }}
            >
              NUKE USER
            </Button>
            <div className={classes.smallButtonDescription}>
              Nuking a user is incredibly permanent and also cannot be reversed.
              Don't do it unless you're sure. But don't worry, a confirmation
              box will pop up if you click this button asking if you're sure.
            </div>
          </div>
        )}
        {admin && (
          <>
            <div className={classes.smallButtonContainer}>
              <Button
                variant="contained"
                id="submit_button"
                color="default"
                type="submit"
                onClick={() => parent.handleResetPassword()}
                // disabled={this.state.loading}
                disabled={true}
                style={{ width: "90%" }}
              >
                RESET PASSWORD
              </Button>
            </div>
            <div className={classes.smallButtonContainer}>
              <Button
                variant="contained"
                id="submit_button"
                color="default"
                type="submit"
                onClick={() => parent.handleResetSubscriptionStatus()}
                disabled={parent.state.payment_method ? false : true}
                style={{ width: "90%" }}
              >
                RESET PAYMENT METHOD
              </Button>
              <div className={classes.smallButtonDescription}>
                Reset payment status of a user SPECIFICALLY in our database
                ONLY. Meant in the case that we didn't properly reflect an
                unsubscription from the App/Play Store. IF A USER IS STILL
                SUBSCRIBED IN THE STORE, THEY WILL CONTINUE TO BE CHARGED.
                CAUTION.
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  RenderError = (parent) => {
    if (parent.state.plError) {
      return (
        <div
          style={{
            width: "100%",
            marginTop: 15,
            textAlign: "center",
            color: "red",
          }}
        >
          Error: {parent.state.plError}
        </div>
      );
    } else {
      return <div style={{ width: "100%", marginTop: 15 }} />;
    }
  };

  render() {
    var { parent } = this.props;
    var { classes, userData } = parent.props;
    const units = parent.state.metric ? "kg" : "lb";

    return (
      <div className={classes.profileSuperContainer}>
        <div className={classes.formContainer}>
          <EmailField parent={parent} />
          <TeamField parent={parent} />
          <UserForm parent={parent} units={units} />
          <MaxsForm parent={parent} units={units} />
          {this.RenderError(parent)}
          <div className={classes.buttonContainer}>
            <Button
              variant="contained"
              id="submit_button"
              color="primary"
              type="submit"
              onClick={(e) =>
                handleSubmit(e, parent, userData, parent.handleValidationError)
              }
              disabled={parent.state.loading}
              style={{ color: "#fff" }}
            >
              {<Translate id="submit" />}
            </Button>
          </div>
        </div>
        {parent.state.coach && this.RenderCoachControls(classes, parent)}
      </div>
    );
  }
}

export default PersonalTab;
