import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      onClick: null, // Disable legend onClick event
    },
    tooltip: {
      callbacks: {
        label: function (context) {
          const label = context.label || '';
          const value = context.formattedValue || '';
          return `${value}% ${label}`;
        },
      },
    },
  },
};
export const competition_data = {
  labels: ['Classic Lifts', 'Technique Work'],
  datasets: [
    {
      label: 'Exercises',
      data: [70, 30],
      backgroundColor: [
        'rgba(255, 99, 132)',
        'rgba(54, 162, 235)',
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 1,
    },
  ],
};


export const technique_data = {
  labels: ['Strength Focus', 'Technique Focus'],
  datasets: [
    {
      label: 'Exercises',
      data: [40, 60],
      backgroundColor: [
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const strength_data = {
  labels: ['Strength focus', 'Technique Work'],
  datasets: [
    {
      label: 'Exercises',
      data: [80, 20],
      backgroundColor: [
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(54, 162, 235, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

export const wl_bb_data = {
  labels: ['Strength Focus', 'Technique Work', 'Accessory Focus'],
  datasets: [
    {
      label: 'Exercises',
      data: [20, 20, 60],
      backgroundColor: [
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(255, 159, 64)'
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1,
    },
  ],
};

export const squat_data = {
  labels: ['Strength Focus', 'Technique Work', 'Accessory Focus'],
  datasets: [
    {
      label: 'Exercises',
      data: [80, 0, 20],
      backgroundColor: [
        'rgba(75, 192, 192)',
        'rgba(54, 162, 235)',
        'rgba(255, 159, 64)'
      ],
      borderColor: [
        'rgba(75, 192, 192, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 159, 64, 1)'
      ],
      borderWidth: 1,
    },
  ],
};


export function Competition() {
  return <Doughnut data={competition_data} options={options}/>;
}

export function Technique(){
  return <Doughnut data={technique_data} options={options}/>
}

export function Strength(){
  return <Doughnut data={strength_data} options={options}/>
}

export function WL_BB(){
  return <Doughnut data={wl_bb_data} options={options}/>
}

export function Squat(){
  return <Doughnut data={squat_data} options={options}/>
}