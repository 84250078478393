import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { editCharge, refundCharge } from '../api'

const styles = () => ({
  root: {
    width: '100%',
    margin: 'auto'
  },
  tableWrapper: {
    overflowX: 'auto',
    marginBottom: 50
  },
  keyCell: {
    padding: '10px 4px 6px 14px'
  },
  actionsRoot: {
    flexShrink: 0
  },
  adminContainer: {
    marginTop: 30
  },
  adminTable: {
    minWidth: 500,
    marginTop: 50
  },
  table: {
    padding: 0,
    minWidth: '100%'
  },
  tableRow: {
    maxWidth: '100%'
  },
  keyCell: {
    minWidth: '30%',
    maxWidth: '30%'
  },
  valueCell: {
    minWidth: '80%',
    maxWidth: '80%',
    whiteSpace: 'normal',
    wordBreak: 'break-word'
  },
});


class _CustomPaginationActionsTable extends React.Component {

  renderChargeUser = (row, index) => {
    const { classes } = this.props;

    return (
      <Paper style={{ width: '90%', margin: 'auto' }}>
        <Accordion key={`${row.stripe_created}-${index}`} style={{ borderWidth: 1, borderStyle: 'solid' }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            Charge Date: {new Date(row.stripe_created * 1000).toDateString()}
          </AccordionSummary>
          <AccordionDetails className={classes.table}>
            <Table className={classes.table} size="small">
              <TableBody>
                <TableRow key="id">
                  <TableCell className={classes.keyCell}>
                    ID:
                  </TableCell>
                  <TableCell>
                    {row.charge_id}
                  </TableCell>
                </TableRow>
                <TableRow key="payment_date">
                  <TableCell className={classes.keyCell}>
                    Payment Date:
                  </TableCell>
                  <TableCell>
                    {row.payment_date}
                  </TableCell>
                </TableRow>
                <TableRow key="created_date">
                  <TableCell className={classes.keyCell}>
                    Created Date UTC:
                  </TableCell>
                  <TableCell>
                    {row.date_created_utc}
                  </TableCell>
                </TableRow>
                <TableRow key="amount">
                  <TableCell className={classes.keyCell}>
                    Amount:
                  </TableCell>
                  <TableCell>
                    ${row.amount / 100}
                  </TableCell>
                </TableRow>
                <TableRow key="start_date">
                  <TableCell className={classes.keyCell}>
                    Method:
                  </TableCell>
                  <TableCell>
                    {row.payment_method.toUpperCase()}
                  </TableCell>
                </TableRow>
                <TableRow key="frequency">
                  <TableCell className={classes.keyCell}>
                    Receipt:
                  </TableCell>
                  <TableCell>
                    <a href={row.receipt_url}>Stripe</a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </AccordionDetails>
        </Accordion>
      </Paper>
    )
  }

  renderDateCreatedInput = (index) => {
    const { classes, parent } = this.props;
    let charges = parent.state.charges
    let charge = charges[index]

    return (
      <TextField
        value={parent.state.charges[index].payment_date}
        name="payment_date"
        id="payment_date"
        onChange={(evt) => {
          charge.payment_date = evt.target.value;
          charges[index] = charge;
          parent.setState({ charges });
        }}
        style={{ flex: 1 }}
        type="text"
        inputProps={{
          autoComplete: 'off',
          autoCorrect: 'off',
          autoCapitalize: 'off'
        }}
      />
    )
  }

  renderChargeAdmin = (charge, index) => {
    const { classes, parent } = this.props;
    let keys = Object.keys(charge)
    keys.sort()

    return (
      <Paper style={{ width: '90%', margin: 'auto', marginTop: 25, marginBottom: 25 }}>
        <div key={`${charge.stripe_created}-${index}`}>
          <div className={classes.table}>
            <Table className={classes.table} size="small">
              <TableBody>
                {keys.map(key => {
                  return (
                    <TableRow className={classes.tableRow} key={key}>
                      <TableCell className={classes.keyCell}>
                        {key}:
                      </TableCell>
                      <TableCell className={classes.valueCell} >
                        {key === 'payment_date' ? this.renderDateCreatedInput(index) : String(charge[key])}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <div
              style={{
                display: 'flex',
                flex: 1,
                gap: 10,
                padding: 10,
                justifyContent: 'flex-end',
                minHeight: 36
              }}
            >
              {charge.amount > 0 && !charge.refunded ? (<Button
                variant="contained"
                id="cancel_button"
                color="primary"
                type="submit"
                onClick={(e) => parent.setState({ dialogOpen: true, dialogType: 'refundCharge', chargeToRefund: charge })}
                disabled={parent.state.loading}
                style={{ color: "#fff", maxWidth: 290 }}
              >
                Issue Refund
              </Button>) : null}
              <Button
                variant="contained"
                id="cancel_button"
                color="primary"
                type="submit"
                onClick={(e) => editCharge(e, parent, console.log, charge)}
                disabled={parent.state.loading}
                style={{ color: "#fff", maxWidth: 290 }}
              >
                Submit Edit
              </Button>
            </div>
          </div>
        </div>
      </Paper>
    )
  }

  renderCharge = (row, index) => {
    const { admin } = this.props

    if (admin) {
      return this.renderChargeAdmin(row, index)
    } else {
      return this.renderChargeUser(row, index)
    }
  }

  render() {
    const { classes, parent } = this.props;
    let charges = parent.state.charges

    return (
      <div className={classes.tableWrapper}>
        {charges.map((row, index) => this.renderCharge(row, index))}
      </div>
    )
  }
}

_CustomPaginationActionsTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export const PaymentsTable = withStyles(styles)(_CustomPaginationActionsTable);