import React from 'react';
import { getUsers, getStats } from './api'
import { Auth } from "aws-amplify";
import { UserTable } from './screens/userTable/userTable';

function treatAsUTC(date) {
  var result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return result;
}


class _Coach extends React.Component {

  constructor(props) {
    super(props)
  }
  state = {
    rows: [],
    loginSortDirection: 'asc',
    loading: true,
    coach: false,
    admin: false,
    customers: null,
    promos: null,
    error: null,
    leadingTeams: [],
    coachingTeams: [],
    viewableTeams: [],
  }

  async componentDidMount() {
    this.setState({ loading: true })
    let urlParams = new URLSearchParams(window.location.search)
    let team = urlParams.get('team')
    console.log("coaching team:", team)

    await Auth.currentSession().then(async (value) => {
      // do some initial tests for basic group membership
      const payload = value.accessToken.payload
      const groups = payload['cognito:groups'] ? payload['cognito:groups'] : []
      const cognito_id = payload.sub

      if (groups.includes('ADMINS')) {
        this.setState({ admin: true })
      }
      if (groups.includes('COACHES')) {
        this.setState({ coach: true })
      }
      if (!groups.includes('COACHES') && !groups.includes('ADMINS')) {
        this.props.history.push("/workouts")
      }

      await this.props.fetchUserData(cognito_id).then((data) => {
        let leadingTeams = data.leading_teams
        let coachingTeams = data.coaching_teams
        let viewableTeams = leadingTeams.concat(coachingTeams).filter(x => x)

        this.setState({
          leadingTeams,
          coachingTeams,
          viewableTeams,
          selectedTeam: team ? team : viewableTeams[0]
        })

        this.runGetUsers()
        // console.log('state after componentDidMount:', this.state)
      })
    })
  }

  runGetUsers = async () => {
    console.log('running the runGetUsers function...')
    let selectedTeam = this.state.selectedTeam
    console.log('selected team:', selectedTeam)

    if (selectedTeam && selectedTeam !== 'Weightlifting.ai') {
      await getUsers(selectedTeam).then((data) => {
        if (data.length === 0) {
          this.setState({
            rows: [],
            loading: false,
            customers: 0,
          })
        } else {
          data.sort(this.loginCompare)

          if (process.env.REACT_APP_ENV === 'prod') {
            data = data.filter(r => !r.email.includes('syncsdxk'))
          }

          // calculate current customer count
          var d = new Date();
          d.setMonth(d.getMonth() - 1);
          d.setDate(d.getDate() - 1)
          d = d.toISOString().split('T')[0]

          let customers = data.filter(r => r.latest_charge).filter(r => r.latest_charge.payment_date > d)
          customers = customers.length
          console.log('customers:', customers)

          let promos = data.filter(r => r.latest_charge).filter(r => r.latest_charge.payment_date > d)
          promos = promos.filter(r => r.latest_charge.payment_method === 'PROMO_CODE')
          promos = promos.length
          console.log('promos:', promos)

          this.setState({
            rows: data,
            loading: false,
            customers,
          })
        }
      })
    } else if (!selectedTeam) {
      console.log("no selected team!")
      this.setState({ error: 'no_team', loading: false })
    } else {
      await getStats().then((data) => {
        console.log('stats data:', data)
        this.setState({ loading: false, customers: data.active_users })
      })
      this.setState({ loading: false })
    }
  }

  loginCompare = (a, b) => {
    const val = treatAsUTC(new Date(a.last_login_utc)) - treatAsUTC(new Date(b.last_login_utc))
    //a is newer than b
    if (val < 0) {
      return this.state.loginSortDirection === 'asc' ? 1 : -1;
    }
    //b is newer than a
    if (val > 0) {
      return this.state.loginSortDirection === 'asc' ? -1 : 1;
    }
    return 0;
  }

  handleSelectChange = async (e) => {
    // await this.setState({ loading: true, selectedTeam: e.target.value });
    window.location.href = `${window.location.origin}/coach?team=${encodeURI(e.target.value)}`
  }

  render() {
    // display data stuff /////////////////////////////////////////////////////////////////////////////////

    const data = this.state.rows
    const columns = [
      { Header: 'Email', accessor: 'email' },
      { Header: 'First name', accessor: 'first_name' },
      { Header: 'Last name', accessor: 'last_name' },
      { Header: 'Cognito', accessor: 'cognito_id' },
      { Header: 'Last login', accessor: 'last_login_utc' },
      { Header: 'Team Status', accessor: 'team_status' },
      { Header: 'Actions', accessor: 'start_date_utc' },
    ]

    // csv stuff //////////////////////////////////////////////////////////////////////////////////////////

    const csvRaw = this.state.rows
    csvRaw.map((row) => {
      if (row.latest_charge && row.latest_charge.payment_date) {
        row.latest_charge_date = row.latest_charge.payment_date
      } else {
        row.latest_charge_date = null
      }

      return row
    })
    console.log('csvRaw:', csvRaw)
    let csvHead = ["first_name", "last_name", "email", "cognito_id", "last_login_utc", "start_date_utc",
      "height", "bodyweight", "birthday", "male", "metric", "latest_charge_date"];
    let csvData = data.map(row => (csvHead.map(c => (row[c]))))
    csvData = [csvHead, ...csvData]

    // return statements //////////////////////////////////////////////////////////////////////////////////

    if (!this.props.match.isExact) {
      return null
    } else if (this.state.error) {
      return (
        <div style={{ margin: 'auto', maxWidth: 500 }} id="notCoachingAnyTeams">
          <p>You are currently not coaching any teams!</p>
          <p>If you just submitted an application then remind the lead coach to approve you!</p>
        </div>
      )
    } else {
      return <UserTable parent={this} data={data} columns={columns} csvData={csvData} handleSelectChange={this.handleSelectChange} />
    }
  }
}

export default _Coach;