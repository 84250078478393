export const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
    },
    jsPad: {
        paddingTop: '64px'
    },
    graphContaier: {
        padding: '20px 0 20px 0',
        background: 'gray'
    },
    navContainer: {
        flexGrow: 100
    },
    navParent: {
        height: '100%',
        padding: '8px'
    },
    navSubList: {
        listStyle: 'none',
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(91px, 1fr))',
        justifyItems: 'center'
    },
    navVariable: {
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        margin: 7,
        marginBottom: 0,
        border: '1px solid gray',
        backgroundColor: '#adadad',
        borderRadius: 4
    },
    navVariableName: {
        display: 'flex',
        margin: 3,
        justifyContent: 'center',
    },
    navVariableValue: {
        display: 'flex',
        flexGrow: 100,
        alignItems: 'stretch',
        margin: 5,
        backgroundColor: 'black',
        color: 'white',
        fontSize: 30,
        justifyContent: 'center',
        borderRadius: 4,
        fontWeight: 600
    },
    navVariableSmallValue: {
        display: 'flex',
        flexGrow: 100,
        alignItems: 'stretch',
        margin: 5,
        backgroundColor: 'black',
        color: 'white',
        fontSize: 20,
        justifyContent: 'center',
        borderRadius: 4
    },
    link: {
        textDecoration: 'none',
        padding: '5px'
    },
    navLink: {
        textDecoration: 'none',
        flexGrow: 100,
    }
}