import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { LocalizeProvider } from 'react-localize-redux';
import LogRocket from 'logrocket';
import { Amplify, Storage } from 'aws-amplify';
import awsconfig from './aws-info';

import App from './components/App/App';
import configureStore from './state/store';
import './index.css';
import './bootstrap';

if (process.env.REACT_APP_ENV === 'prod') {
  LogRocket.init(`pmxvbw/maxpower-${process.env.REACT_APP_ENV}`);
}

Amplify.configure(awsconfig[process.env.REACT_APP_API_VER]);

const { store, persistor } = configureStore();

// require('dotenv').config();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LocalizeProvider store={store}>
        <App />
      </LocalizeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
serviceWorker.unregister();
