import { connect } from 'react-redux';

import _Programs from './layout';
import { updateUserData, fetchUserData } from '../../state/thunk';
import { toggleLoading } from '../../state/actions';
import { getTranslate } from "react-localize-redux";

const mapStateToProps = ({ userReducer, localize }) => ({
  userData: userReducer,
  translate: getTranslate(localize)
});

const mapDispatchToProps = (dispatch) => ({
  updateUserData: (pl) => dispatch(updateUserData(pl)),
  fetchUserData: (userName) => dispatch(fetchUserData(userName)),
  toggleLoading: () => dispatch(toggleLoading()),
});

export const Programs = connect(mapStateToProps, mapDispatchToProps)(_Programs);
