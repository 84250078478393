export const styles = theme => ({
  container: {
    position: 'fixed',
    zIndex: 1199,
    top: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    height: 64,
    alignItems: 'center',
    '@media (max-width: 600px)': {
      justifyContent: 'space-between'
    }
  },
  underline: {
    borderBottom: '0px'
  },
  signInButton: {
    position: 'absolute',
    top: 12,
    right: 20
  },
  languageSelect: {
    position: 'absolute',
    top: 20,
    left: 20
  },
  menuButton: {
    position: 'absolute',
    top: 8,
    right: 8
  },
  text: {
    color: '#fff',
    fontFamily: 'Jura',
    fontSize: 18,
    letterSpacing: 1.15,
    fontWeight: 600
  },
  logo: {
    fontSize: 24,
    margin: 0,
    color: '#fff',
    fontFamily: 'Jura',
    letterSpacing: 1.15,
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer'
    },
  },
  logoWH: {
    display: 'flex',
    fontSize: 24,
    margin: 0,
    color: '#fff',
    fontFamily: 'Watchword',
    fontWeight: '600',
    letterSpacing: 1.15,
    '&:hover': {
      cursor: 'pointer'
    },
  },
  link: {
    margin: 'auto',
    textDecoration: 'none',
    letterSpacing: 1.5,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none'
    }
  },
  select: {
    borderRadius: 4,
    borderWidth: "thick",
    color: "white",
    borderColor: "white"
  }
})