// Testimonials.js
import React from "react";
import { Rating } from "@mui/material";

const Testimonial = ({ rating, date, name, subject, text }) => (
  <div style={{
    backgroundColor: 'white',
    padding: '20px',
    margin: '10px',
    borderRadius: '8px',
    boxShadow: '0 8px 16px rgba(0,0,0,0.15)',
    maxWidth: '300px',
  }}>
   <div style={{textAlign: 'left'}}>
     <Rating value={rating} readOnly />
   </div>
    <div style={{ 
      color: 'gray', 
      fontSize: '12px', 
      margin: '5px 0',
      textAlign: 'left'
    }}>
      {name} • {date}
    </div>
    <h3 style={{ 
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '8px',
      textAlign: 'left'
    }}>
      {subject}
    </h3>
    {text.split('\n').map((paragraph, index) => (
      <p key={index} style={{ 
        fontSize: '14px', 
        marginBottom: '10px',
        textAlign: 'left'
      }}>
        {paragraph}
      </p>
    ))}
  </div>
 );

const Testimonials = () => {
  const testimonials = [
    {
      rating: 5,
      name: "Richard S Greene",
      date: "01/17/2023",
      subject: "You'll find success if you put in the hard work!",
      text: "I recently started using weightlifting.ai to prepare for my comeback to competition after taking a year off. I have to say, I am thoroughly impressed with the program and it's ability to help me get back into topform.\n\nOne of the things that drew me to weightlifting.ai was the involvement of Max Aita. I had the pleasure of working with him 4 years ago and knew that anything he was a oart if would be phenomenal. I was not disappointed.\n\nThe program is tailored specifically for weightlifting and is designed to help athletes of all levels improve their technique and increase their strength. The exercises are challenging but doable, and the progressions are clearly laid out. The support from the coaches and community is excellent.\n\nI highly recommend weightlifting.ai to anyone looking to take their weightlifting to the next level. Whether you're just starting or looking to get into elite competition, weightlifting.ai has something to offer.",
    },
    {
      rating: 5,
      name: "liftwell",
      date: "01/13/2023",
      subject: "Great App!",
      text: "This app has been an amazing way to regulate my sessions day to day, manage my fatigue, and not feel discouraged on days I wasn't doing as well, because the app took care of all of it!\n\nHighly recommend.",
    },
    {
      rating: 5,
      name: "Derpitology",
      date: "09/07/2022",
      subject: "Great app and community",
      text: "This app was the perfect solution for me as I was relocating and between gyms. While I've moved on to in-person coaching (the folks at Weightlifting House do say the app is the best way to go other than having a dedicated coach for your every session), I had a great experience while I was on the app. I think the most impressive part was definitely the fact that it was constantly being updated. The team is really invested, both in developing the app to be better and better as well as providing coaching through the community platform. Highly recommended!",
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px",
        padding: "40px 20px",
      }}
    >
      {testimonials.map((testimonial, index) => (
        <Testimonial key={index} {...testimonial} />
      ))}
    </div>
  );
};

export default Testimonials;
