import React from "react";
import componentDays from "./Days";
import ListItem from "@material-ui/core/ListItem";
import ReactPlayer from "react-player";
import ListSubheader from "@material-ui/core/ListSubheader";
import { Translate } from "react-localize-redux";
import { handleNote } from "../utils";

function componentWeeks(classes, week_index, parent) {
  var micro = parent.state.items[week_index];
  return (
    <ListItem
      divider={false}
      disableGutters={true}
      className={classes.fullList}
      key={week_index}
    >
      <ul className={classes.ul}>
        <div key={week_index}>
          <ListSubheader color="primary" className={classes.li} id="weekHeader">
            <b>
              <Translate id="week" /> {micro["micro_count"] + 1}
            </b>
          </ListSubheader>
          {micro["video_url"] !== null ? (
            <ReactPlayer
              url={micro["video_url"]}
              playing={false}
              controls={true}
              width="20%"
              height="150px"
              style={{
                margin: "auto",
              }}
            />
          ) : (
            ""
          )}
          {Object.keys(micro["days"]).map((day_index) =>
            componentDays(classes, micro, [week_index, day_index], parent)
          )}
        </div>
      </ul>
    </ListItem>
  );
}

export default componentWeeks;
