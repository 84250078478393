import axios from 'axios';
import { Auth } from 'aws-amplify';

const coachUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin`;

export const getProgram = async (cognito_id, program_id) => {
  console.log('running getProgram', cognito_id, program_id)
  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const data = {
    get: 'program_record',
    cognito_id: cognito_id,
    program_id: program_id,
    source: 'web'
  }

  return axios
    .get(coachUrl, { params: data, headers })
    .then((data) => { return data.data })
    .catch((error) => console.log(error));
};