import axios from "axios";
import { Auth } from "aws-amplify";

const url = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/payments`;
const config = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const applyPromo = async (promo, parent) => {
  console.log("applying promo for promo", promo);
  parent.setState({ loading: true });

  const q = {
    checkPromo: promo,
    cognito_id: parent.state.cognito_id,
    source: "web",
  };

  setTimeout(async function () {
    q.version = "2.0.0";
    const data = JSON.stringify(q);

    var authToken = await (await Auth.currentSession()).idToken.jwtToken;
    config.headers.Authorization = authToken;

    const data_1 = await axios.put(url, data, config);
    const r = data_1.data;
    console.log("promo apply ", r);
    if (r.promo_error !== null) {
      let err = JSON.parse(r.promo_error);

      parent.setState({
        plError: err.message,
        loading: false,
        monthsCredit: null,
        daysCredit: null,
        priceNow: null,
        appliedPromo: false,
      });
    } else {
      parent.setState({
        plError: null,
        loading: false,
        monthsCredit: r.months_credit,
        daysCredit: r.days_credit,
        priceNow: r.price_now,
        appliedPromo: true,
      });
    }
  }, 100);
};

export function get_days(month, year) {
  var months = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  var days_per_month = months[month - 1];

  if (month === 2) {
    if (year % 4) {
      days_per_month = 29;
    }
  }

  days_per_month = [...Array(days_per_month).keys()].map((x) => x + 1);

  return days_per_month;
}

export const xrmOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const dayOptions = [
  { display: "Mon", value: "monday" },
  { display: "Tue", value: "tuesday" },
  { display: "Wed", value: "wednesday" },
  { display: "Thur", value: "thursday" },
  { display: "Fri", value: "friday" },
  { display: "Sat", value: "saturday" },
  { display: "Sun", value: "sunday" },
];

export const adjustWeight = (weight, toPounds) => {
  const multi = toPounds ? 2.20462262 : 0.45359237;
  let newWeight = Math.round(weight * multi);
  return newWeight;
};

export default get_days;

export function maxLifts(translate) {
  return {
    powerlifting: [
      {
        liftName: "Squat",
        styles: [
          { label: translate("low_bar"), value: "low-bar back squat" },
          { label: translate("high_bar"), value: "high-bar back squat" },
        ],
      },
      {
        liftName: "Bench",
        styles: [
          {
            label: translate("narrow_grip"),
            value: "narrow-grip bench press",
          },
          {
            label: translate("medium_grip"),
            value: "medium-grip bench press",
          },
          {
            label: translate("wide_grip"),
            value: "wide-grip bench press",
          },
        ],
      },
      {
        liftName: "Deadlift",
        styles: [
          { label: translate("sumo"), value: "sumo deadlift" },
          { label: translate("conventional"), value: "conventional deadlift" },
        ],
      },
    ],
    weightlifting: [
      { liftName: "back_squat", required: true },
      { liftName: "snatch", required: true },
      { liftName: "clean", required: true },
      {
        liftName: "jerk",
        styles: [
          { label: "power/squat", value: "power_jerk" },
          { label: "split", value: "split_jerk" },
        ],
        required: true,
      },
      {
        liftName: "split_jerk",
        required: false,
        ratioLift: "jerk",
        ratio: 0.9,
      },
      {
        liftName: "power_jerk",
        required: false,
        ratioLift: "jerk",
        ratio: 0.9,
      },
      {
        liftName: "push_press",
        required: false,
        ratioLift: "jerk",
        ratio: 0.8,
      },
      {
        liftName: "power_snatch",
        required: false,
        ratioLift: "snatch",
        ratio: 0.8,
      },
      {
        liftName: "hang_snatch_below_knee",
        required: false,
        ratioLift: "snatch",
        ratio: 0.92,
      },
      {
        liftName: "block_above_knee_snatch",
        required: false,
        ratioLift: "snatch",
        ratio: 0.92,
      },
      {
        liftName: "power_clean",
        required: false,
        ratioLift: "clean",
        ratio: 0.8,
      },
      {
        liftName: "hang_clean_below_knee",
        required: false,
        ratioLift: "clean",
        ratio: 0.92,
      },
      {
        liftName: "block_above_knee_clean",
        required: false,
        ratioLift: "clean",
        ratio: 0.92,
      },
      {
        liftName: "front_squat",
        required: false,
        ratioLift: "back_squat",
        ratio: 0.815,
      },
      {
        liftName: "overhead_squat",
        required: false,
        ratioLift: "snatch",
        ratio: 1.02,
      },
      {
        liftName: "strict_press",
        required: false,
        ratioLift: "jerk",
        ratio: 0.52,
      },
    ],
  };
}
