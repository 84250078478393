import axios from 'axios';
import { Auth } from 'aws-amplify';

const coachUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin`;

export const getTeams = async () => {
  console.log('running getTeams')

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const data = {
    get: 'list_teams',
    source: 'web'
  }

  return axios
    .get(coachUrl, { params: data, headers })
    .then((data) => data.data)
    .catch((error) => console.log(error));
};

export const deleteTeam = async (team_name) => {
  console.log('running deleteTeam for team:', team_name)

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const params = {
    post: 'delete_team',
    team_name,
    source: 'web'
  }

  return axios
    .post(coachUrl, null, { params, headers })
    .then((data) => window.location.href = window.location.href)
    .catch((error) => console.log(error));
};

export const createTeam = async (team_name) => {
  console.log('running createTeam for team:', team_name)

  if (team_name.length > 0) {
    var authToken = await (await Auth.currentSession()).idToken.jwtToken;
    var headers = {
      'Authorization': authToken,
      'Content-Type': 'application/json'
    }

    const params = {
      post: 'create_team',
      team_name,
      source: 'web'
    }

    return axios
      .post(coachUrl, null, { params, headers })
      .then((data) => window.location.href = window.location.href)
      .catch((error) => console.log(error));
  }
};