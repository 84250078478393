const awsmobile = {
    default: {
        "aws_project_region": "ap-northeast-1",
        "aws_cognito_identity_pool_id": "ap-northeast-1:7d6bbe46-3729-4976-87f8-727e0a700c70",
        "aws_cognito_region": "ap-northeast-1",
        "aws_user_pools_id": "ap-northeast-1_0sueFCWn7",
        "aws_user_pools_web_client_id": "3jfblnki974p9fmmt57qmhjg19",
        "oauth": {},
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [],
        "aws_cognito_mfa_configuration": "OPTIONAL",
        "aws_cognito_mfa_types": [
            "SMS",
            "TOTP"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 6,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [],
        "aws_user_files_s3_bucket": "wai-media122233-dev",
        "aws_user_files_s3_bucket_region": "ap-northeast-1"
    },
    prod: {
        "aws_project_region": "ap-northeast-1",
        "aws_cognito_identity_pool_id": "ap-northeast-1:4eb16963-5394-40c4-825c-cc6ed1774c47",
        "aws_cognito_region": "ap-northeast-1",
        "aws_user_pools_id": "ap-northeast-1_xBkPGHzYc",
        "aws_user_pools_web_client_id": "6e7ld5mmf1n917h47ccgo22p7n",
        "oauth": {},
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [],
        "aws_cognito_mfa_configuration": "OPTIONAL",
        "aws_cognito_mfa_types": [
            "SMS",
            "TOTP"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 6,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [],
        "aws_user_files_s3_bucket": "wai-media171738-prod",
        "aws_user_files_s3_bucket_region": "ap-northeast-1"
    }
};


export default awsmobile;
