import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { Auth } from "aws-amplify";
import PrivateRoute from './PrivateRoute';
import { Header } from '.././header/Header';
import {
  Programs,
  Profile,
  Workouts,
  Hero,
  WorkoutSettings,
  AutoLogin,
  Coach,
  Onboarding,
  Support,
  ProgramInterface,
  CoachProgram,
  CoachProfile,
  Terms,
  Privacy,
  Admin,
  SignIn,
  OnboardingCoach,
  ManageTeam,
  ManageTeams,
} from '../../screens';
import * as programInterfaceRoutes from '../../screens/coach/screens/program-interface/route_names';
import { connect } from 'react-redux';
import { fetchUserData } from '../../state/thunk';
import { Man } from '@mui/icons-material';

class Routes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuth: false
    };
  }

  updateAuth = (authState) => { this.setState({ isAuth: authState }) };

  async componentDidMount() {
    const sessionRes = await Auth.currentSession()
    console.log('sessionRes', sessionRes)

    const isAuth = sessionRes.accessToken ? true : false
    this.setState({ isAuth })
  }

  render() {
    return (
      <BrowserRouter forceRefresh={false}>
        <Header updateAuth={this.updateAuth} isAuth={this.state.isAuth} />
        <div style={{ display: 'flex', flex: 1, height: '100%', width: '100%', flexDirection: 'column' }}>
          <Switch>
            <Route exact path="/auth" component={Hero} />
            <Route
              exact
              path="/signin"
              render={(_props) => <SignIn updateAuth={this.updateAuth} {..._props} />}
            />
            <Route
              exact
              path="/autologin"
              render={(_props) => <AutoLogin updateAuth={this.updateAuth} {..._props} />}
            />
            <Route exact path="/onboard" component={Onboarding} />
            <Route exact path="/onboardCoach" component={OnboardingCoach} />
            <Route exact path="/support" component={Support} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <PrivateRoute exact path="/" component={Workouts} />
            <PrivateRoute exact path="/workouts" component={Workouts} />
            <PrivateRoute exact path="/workout-settings" component={WorkoutSettings} />
            <PrivateRoute exact path="/programs" component={Programs} />
            <PrivateRoute exact path="/coach" component={Coach} />
            <PrivateRoute exact path="/manageTeam" component={ManageTeam} />
            <PrivateRoute exact path="/manageTeams" component={ManageTeams} />
            <PrivateRoute path="/coach/programs/:id" render={_props => <CoachProgram {..._props} />} />
            <PrivateRoute path="/coach/profile/:cognito_id" render={_props => <CoachProfile {..._props} />} />
            <PrivateRoute exact path={programInterfaceRoutes.PROGRAM} render={renderProps => <ProgramInterface {...renderProps} />} />
            <PrivateRoute path={programInterfaceRoutes.PHASE} component={ProgramInterface} />
            <PrivateRoute path={programInterfaceRoutes.MICRO} component={ProgramInterface} />
            <PrivateRoute exact path={programInterfaceRoutes.DAY} component={ProgramInterface} />
            <PrivateRoute exact path={programInterfaceRoutes.MOVEMENT} component={ProgramInterface} />
            <PrivateRoute path={programInterfaceRoutes.SET} component={ProgramInterface} />
            <PrivateRoute path="/profile" render={_props => <Profile {..._props} />} />
            <PrivateRoute path="/admin" render={_props => <Admin {..._props} />} />
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ userReducer }) => ({
  userData: userReducer
});

const mapDispatchToProps = dispatch => ({
  fetchUserData: cognito_id => dispatch(fetchUserData(cognito_id))
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
