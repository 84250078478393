// CountdownTimer.js
import React, { useState, useEffect } from "react";

const CountdownTimer = () => {
  const endDate = new Date("2024-12-03T23:59:59").getTime();

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = endDate - now;

    if (difference <= 0) return null;

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const time = calculateTimeLeft();
      if (!time) {
        clearInterval(timer);
      }
      setTimeLeft(time);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  if (!timeLeft) return null;

  const TimeUnit = ({ value, label }) => (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "column",
        alignItems: "center",
        margin: "0 10px",
      }}
    >
      <div
        style={{
          backgroundColor: "black",
          color: "white",
          padding: "5px 15px",
          borderRadius: "4px",
          fontSize: "32px",
          fontWeight: "bold",
          minWidth: "50px",
          textAlign: "center",
        }}
      >
        {String(value).padStart(2, "0")}
      </div>
      <div
        style={{
          fontSize: "14px",
          marginTop: "5px",
          textTransform: "uppercase",
        }}
      >
        {label}
      </div>
    </div>
  );

  return (
    <div
      style={{
        backgroundColor: "#ffdd57",
        padding: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span style={{ fontSize: "32px", fontWeight: "bolder", marginRight: "15px" }}>
      BF Sale Extended Through Cyber Monday:
      </span>
      <TimeUnit value={timeLeft.days} label="days" />
      <TimeUnit value={timeLeft.hours} label="hours" />
      <TimeUnit value={timeLeft.minutes} label="mins" />
      <TimeUnit value={timeLeft.seconds} label="secs" />
    </div>
  );
};

export default CountdownTimer;
