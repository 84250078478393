// All caps and snake case is generally utilized in Javascript to indicate that a variable is a constant
// You may even want to consider storing this data in your database so that it's easy to update later on without having to push a client side update.
export const COMP_VARIATIONS = {
    squat: [
        {
            name: 'low-bar back squat',
            translationKey: 'low_bar'
        },
        {
            name: 'high-bar back squat',
            translationKey: 'high_bar'
        }
    ],
    bench: [
        {
            name: 'narrow-grip bench press',
            translationKey: 'narrow_grip'
        },
        {
            name: 'medium-grip bench press',
            translationKey: 'medium_grip'
        },
        {
            name: 'wide-grip bench press',
            translationKey: 'wide_grip'
        }
    ],
    deadlift: [
        {
            name: 'conventional deadlift',
            translationKey: 'conventional'
        },
        {
            name: 'sumo deadlift',
            translationKey: 'sumo'
        }
    ]
}

export const XRM_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

export const basicMaxs = {
    "low-bar back squat": { "max": 0, "xrm": 1 },
    "high-bar back squat": { "max": 0, "xrm": 1 },
    "narrow-grip bench press": { "max": 0, "xrm": 1 },
    "medium-grip bench press": { "max": 0, "xrm": 1 },
    "wide-grip bench press": { "max": 0, "xrm": 1 },
    "conventional deadlift": { "max": 0, "xrm": 1 },
    "sumo deadlift": { "max": 0, "xrm": 1 },
    "snatch": { "max": 0, "xrm": 1, "unknown": false },
    "power_snatch": { "max": 0, "xrm": 1, "unknown": true },
    "block_above_knee_snatch": { "max": 0, "xrm": 1, "unknown": true },
    "hang_snatch_below_kne": { "max": 0, "xrm": 1, "unknown": true },
    "clean": { "max": 0, "xrm": 1, "unknown": false },
    "power_clean": { "max": 0, "xrm": 1, "unknown": true },
    "hang_clean_above_knee": { "max": 0, "xrm": 1, "unknown": true },
    "block_above_knee_clean": { "max": 0, "xrm": 1, "unknown": true },
    "split_jerk": { "max": 0, "xrm": 1, "unknown": false },
    "power_jerk": { "max": 0, "xrm": 1, "unknown": true },
    "push_press": { "max": 0, "xrm": 1, "unknown": true },
    "front_squat": { "max": 0, "xrm": 1, "unknown": true },
    "back_squat": { "max": 0, "xrm": 1, "unknown": false },
    "overhead_squat": { "max": 0, "xrm": 1, "unknown": true },
    "strict_press": { "max": 0, "xrm": 1, "unknown": true },
}

// TODO: Add translations for this and kg/lb max lift units in global_translations.json
export const UNIT_OPTIONS = [{ label: 'metric', value: true }, { label: 'imperial', value: false }]