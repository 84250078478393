export const styles = () => ({
  resultTextField: {
    padding: 0,
    margin: 0,
    backgroundColor: 'red',
    maxHeight: 27,
    "& .MuiInputBase-root": {
      maxHeight: 27
    }
  },
  payButton: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid black",
    borderRadius: 4,
    backgroundColor: "rgb(1, 111, 185)",
    height: 150,
    marginTop: 30,
    marginBottom: 30,
    cursor: "pointer",
    maxWidth: 300,
    alignSelf: 'center',
    margin: 'auto'
  },
  img: {
    width: 150,
    height: "auto",
    borderRadius: "0 4px 4px 0",
  },
  btnTextContainer: {
    display: "flex",
    minWidth: 150,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  btnText: {
    fontSize: 30,
    fontWeight: 600,
    color: "white",
  },
  btnTextSmall: {
    fontSize: 15,
    fontWeight: 400,
    color: "white",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    marginTop: "63px"
  },
  toggleGroup: {
    display: "flex",
  },
  appBarShit: {
    position: "fixed",
  },
  workOut: {
    color: "green",
    fontSize: 20,
  },
  buttonContainer: {
    display: "flex",
    marginTop: 20,
    justifyContent: 'center'
  },
  form: {
    display: "flex",
    maxWidth: 400,
    width: "80%",
    margin: "auto",
  },
  formContainer: {
    display: "flex",
    flex: 2,
    flexDirection: "column",
    marginTop: "75px",
    margin: "auto"
  },
  spacingInput: {
    margin: "0 10px 0 0",
    width: "100%",
  },
  maxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 5px 0",
  },
  metricSelect: {
    margin: 'auto'
  },
  textField: { padding: "0 5px 0 0" },
  selectField: { margin: "5px 10px 10px 0px", minWidth: "400" },
  labelRoot: {
    fontSize: 12,
  },
  root: {
    backgroundColor: 'red'
  },
  sectionText: {
    color: "#000000",
    fontFamily: "Jura",
    fontSize: 16,
    letterSpacing: 1.15,
    fontWeight: 600,
  },
  fieldText: {
    fontSize: 10,
    textAlign: "left",
    color: "#757575",
  },
  radioOptionGreen: {
    marginTop: 10,
    backgroundColor: "#e0ffde",
    padding: 10,
    margin: "auto",
    borderRadius: 10,
  },
  radioOptionYellow: {
    marginTop: 10,
    backgroundColor: "#f9ffd8",
    padding: 10,
    margin: "auto",
    borderRadius: 10,
  },
  radioOptionRed: {
    marginTop: 10,
    backgroundColor: "#f9d5d2",
    padding: 10,
    margin: "auto",
    borderRadius: 10,
  },
  radioButton: {
    margin: "auto",
  },
  radioHeader: {
    fontSize: 20,
    padding: 0,
    margin: -6,
    marginBottom: -12,
    fontWeight: "bold",
  },
  radioDescription: {
    textAlign: "left",
  },
  radio: {
    "&$checked": {
      color: "#3f51b5",
    },
  },
  checked: {
    fontColor: "#b0b0b0",
  },
  profileAsCoach: {
    backgroundColor: 'blue'
  },
  profileSuperContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginBottom: 60,
    margin: 15
  },
  profileCoachSideContainer: {
    display: 'flex',
    flex: 1,
    marginTop: "75px",
    flexDirection: 'column'
  },
  smallButtonContainer: {
    marginTop: '20px',
  },
  smallButtonDescription: {
    margin: 10,
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 8,
    fontStyle: 'italic'
  },
  boxA: {
    width: '50%',
    height: 500,
    backgroundColor: 'red'
  },
  boxB: {
    width: '20%',
    height: 500,
    backgroundColor: 'blue'
  }
});
