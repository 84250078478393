export const convertQueryParamsToJSON = () => {
    const query = window.location.search.substring(1)
    const params = query.split('&')
    const queryParamsObj = {}

    if (query) {
        for (const p of params) {
            const pair = p.split('=')
            queryParamsObj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1])
        }
    }
    return queryParamsObj
}

export const adjustMaxs = (maxs, toPounds, round = true) => {
    // console.log('running adjustMaxs with max record:', maxs)
    const multi = toPounds ? 2.20462 : 0.453592
    var m;
    for (m in maxs) {

        maxs[m].max = round ? Math.round(maxs[m].max * multi) : maxs[m].max * multi
    }
    return maxs
}