import React from 'react';
import { getTeam } from './api'
import { Auth } from "aws-amplify";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { CoachTable } from './screens/coachTable'


class _ManageTeam extends React.Component {
  state = {
    rows: [],
    loginSortDirection: 'asc',
    loading: true,
    coach: false,
    admin: false,
    customers: null,
    promos: null,
    error: null,
    leadingTeams: [],
    coachingTeams: [],
  }

  async componentDidMount() {
    this.setState({ loading: true })
    let urlParams = new URLSearchParams(window.location.search)
    let team = urlParams.get('team')
    console.log("managing team:", team)

    await Auth.currentSession().then(async (value) => {
      // do some initial tests for basic group membership
      const payload = value.accessToken.payload
      const groups = payload['cognito:groups'] ? payload['cognito:groups'] : []
      const cognito_id = payload.sub

      if (!groups.includes('COACHES') && !groups.includes('ADMINS')) {
        this.props.history.push("/workouts")
      }

      await this.props.fetchUserData(cognito_id).then(async (data) => {
        let leadingTeams = data.leading_teams

        // make sure this person should be here
        if (!leadingTeams.includes(team)) {
          this.props.history.push("/coach")
        }

        await getTeam(team).then((data) => {
          console.log('team data:', data)

          this.setState({
            team,
            coach_user_records: data.coach_user_records,
            athlete_code: data.athlete_code,
            coach_code: data.coach_code,
            loading: false
          })
        })

        // console.log('state after componentDidMount:', this.state)
      })
    })
  }


  render() {
    // return statements //////////////////////////////////////////////////////////////////////////////////

    if (this.state.loading) {
      return (
        <Backdrop open={true} invisible={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 80, height: '100%', width: '100%', alignItems: 'center' }}>
          <div style={{ fontSize: 60, fontStyle: 'italic', fontWeight: '600' }}>Team: {this.state.team}</div>
          <div style={{ display: 'flex', flexDirection: 'row', width: '90%', justifyContent: 'center' }}>
            <div style={{ fontSize: 30, fontStyle: 'italic', fontWeight: '600', margin: 20 }} id="athleteCode">
              Athlete code: {this.state.athlete_code}
            </div>
            <div style={{ fontSize: 30, fontStyle: 'italic', fontWeight: '600', margin: 20 }} id="coachCode">
              Coach code: {this.state.coach_code}
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, width: '100%' }}>
            <div style={{ fontSize: 30, fontStyle: 'italic', fontWeight: '600', marginBottom: 10 }}>Coaches:</div>
            <CoachTable parent={this} />
          </div>

        </div>
      )
    }
  }
}

export default _ManageTeam;