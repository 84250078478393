// ACTION-TYPES
const SET_INITIAL_STATE = 'SET_INITIAL_STATE';
const SET_MAXS = 'SET_MAXS'
const SET_EMAIL = 'SET_EMAIL';
const SET_AUTH = 'SET_AUTH';
const SET_USER_NAME = 'SET_USER_NAME';
const TOGGLE_LOADING = 'TOGGLE_LOADING';
const SET_ERROR = 'SET_ERROR';
const SET_PURCHASE = 'SET_PURCHASE';
const SET_FREQUENCY = 'SET_FREQUENCY';
const SET_DIFFICULTY = 'SET_DIFFICULTY';
const SET_BIRTHDAY = 'SET_BIRTHDAY';
const SET_BODYWEIGHT = 'SET_BODYWEIGHT';
const SET_HEIGHT = 'SET_HEIGHT';
const SET_MALE = 'SET_MALE';
const SET_ONHOLD = 'SET_ONHOLD';
const SET_HOLDSTARTDATE = 'SET_HOLDSTARTDATE';
const SET_HOLDENDDATE = 'SET_HOLDENDDATE';
const SET_LANG_CODE = 'SET_LANG_CODE';
const SET_METRIC = 'SET_METRIC';
const SET_COMPSTYLES = 'SET_COMPSTYLES';
const SET_LAST_APP_POPUP = 'SET_LAST_APP_POPUP'
const SET_MOVEMENTS_LIBRARY = 'SET_MOVEMENTS_LIBRARY'

// ACTIONS
const setInitialState = initialState => dispatch => {
  dispatch({ type: SET_INITIAL_STATE, initialState });
};

const setEmail = email => dispatch => {
  dispatch({ type: SET_EMAIL, email });
};

const setUserName = userName => dispatch => {
  dispatch({ type: SET_USER_NAME, userName });
};

const setMaxs = maxs => dispatch => {
  dispatch({ type: SET_MAXS, maxs })
};

const setAuth = auth => dispatch => {
  dispatch({ type: SET_AUTH, auth });
};

const toggleLoading = () => dispatch => {
  dispatch({ type: TOGGLE_LOADING });
};

const setError = error => dispatch => {
  dispatch({ type: SET_ERROR, error });
};

const setPurchased = purchase => dispatch => {
  dispatch({ type: SET_PURCHASE, purchase });
};

const setFrequency = frequency => dispatch => {
  dispatch({ type: SET_FREQUENCY, frequency });
};

const setDifficulty = difficulty => dispatch => {
  dispatch({ type: SET_DIFFICULTY, difficulty });
};

const setBirthday = birthday => dispatch => {
  dispatch({ type: SET_BIRTHDAY, birthday });
};

const setBodyweight = bodyweight => dispatch => {
  dispatch({ type: SET_BODYWEIGHT, bodyweight });
};

const setHeight = height => dispatch => {
  dispatch({ type: SET_HEIGHT, height });
};

const setMale = male => dispatch => {
  dispatch({ type: SET_MALE, male });
};

const onHold = onHold => dispatch => {
  dispatch({ type: SET_ONHOLD, onHold });
}

const holdStartDate = holdStartDate => dispatch => {
  dispatch({ type: SET_HOLDSTARTDATE, holdStartDate });
}

const holdEndDate = holdEndDate => dispatch => {
  dispatch({ type: SET_HOLDENDDATE, holdEndDate });
}

const setLangCode = (lang_code) => (dispatch) => {
  dispatch({ type: SET_LANG_CODE, lang_code });
};

const setMetric = (metric) => (dispatch) => {
  dispatch({ type: SET_METRIC, metric });
};

const setCompStyles = (comp_styles) => (dispatch) => {
  dispatch({ type: SET_COMPSTYLES, comp_styles });
};

const setLastAppPopup = (lastAppPopup) => (dispatch) => {
  dispatch({ type: SET_LAST_APP_POPUP, lastAppPopup });
}

const setMovementsLibrary = (movementsLibrary) => (dispatch) => {
  dispatch({ type: SET_MOVEMENTS_LIBRARY, movementsLibrary });
}

export {
  setInitialState,
  setEmail,
  setFrequency,
  setError,
  toggleLoading,
  setAuth,
  setLangCode,
  setMaxs,
  setPurchased,
  setUserName,
  setDifficulty,
  setBirthday,
  setBodyweight,
  setHeight,
  setMale,
  onHold,
  holdStartDate,
  holdEndDate,
  setMetric,
  setCompStyles,
  setLastAppPopup,
  setMovementsLibrary,
};
