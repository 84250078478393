import React from 'react';
import { Box, Paper, Grid, Button, Container } from '@material-ui/core';

export default ({phaseName, phaseData, basepath, classes}) => {

    return (
        <Container maxWidth='lg'>
            <Box textAlign="left">
                <h4>{phaseName.toUpperCase()}</h4>
            </Box>
            <Box>
                <Box component={Grid} container justify="space-evenly" spacing={2}>
                    {
                        phaseData.map((micro, i) => 
                            <Grid item>
                               <Box component={Paper} elevation={3} p={3}>
                                   <a href={`${basepath}/micro/${micro.micro_id}`} className={classes.link}>
                                    <Box> Micro {i + 1} </Box>1
                                   </a>
                                   <Box 
                                        component={Grid} 
                                        container 
                                        wrap="nowrap" 
                                        spacing={2} 
                                        overflow='auto' 
                                        minHeight={'8rem'} 
                                        maxWidth={'20rem'}
                                        alignItems="flex-end"
                                    >
                                        {
                                            micro.days.map((day, i) => 
                                                <Grid item xs>
                                                    <a href={`${basepath}/micro/${micro.micro_id}/day/${i}`} className={classes.link}>
                                                        <Button variant="outlined">Day {i + 1} </Button>

                                                    </a>
                                                </Grid>
                                            )
                                        }
                                   </Box>
                                </Box> 
                            </Grid>
                        )
                    }
                </Box>
            </Box>
        </Container>
    )
}