import React from 'react'
import { Auth } from "aws-amplify";
import { postBuildRequest } from '../programs/api'
import { Translate } from "react-localize-redux";
import mixpanel from 'mixpanel-browser';
import { updateUser } from '../../state/api'

mixpanel.init('fb6d0cf9cf3b6d33c7c68d30106861a7');

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const registerAndBuild = async (parent) => {
  // console.log('registerAndBuild parent:', parent)
  // console.log('registerAndBuild parent keys:', Object.keys(parent))

  let product = "weightliftinghouse"

  let {
    birth_year,
    birth_month,
    birth_day,
    bodyweight,
    height,
    male,
    metric,
    maxs,
    frequency,
    promoCode,
    fitness_level,
    email,
    firstName,
    lastName,
    password,
    loading,
    comp_styles,
    length_days,
    start_date,
    workout_days,
    sessions_info,
    volume_info,
    program_focus,
  } = parent.state

  if (loading === false) {
    parent.setState({ plError: "", loading: true })

    const translate = parent.props.translate
    const baseUrl = `https://${window.location.hostname}/`
    const startenv = `${baseUrl}_${translate("language.tag")}`

    try {
      mixpanel.identify(parent.state.email)
      const authRes = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          "custom:startenv": startenv,
          "custom:product": product,
          "custom:first_name": firstName,
          "custom:last_name": lastName,
          email,
          preferred_username: email,
        },
      })

      console.log('authRes:', authRes)

      if (authRes.message && authRes.message.includes('must have length greater')) {
        parent.handleError(<Translate id="errSixOrMoreCharacters" />)
      } else if (authRes.message && authRes.message.includes('email already exists')) {
        parent.handleError(<Translate id="errEmailTaken" />)
      } else if (authRes.message) {
        parent.handleError(<Translate id="errGeneric" />)
      }

      if (authRes.userSub) {
        // Map max lift info from front end to back end format before submit
        console.log('made it through register! authRes.userSub:', authRes.userSub)
        await Auth.signIn(parent.state.email, parent.state.password)
        const submitProps = {
          program: parent.state.program,
          cognito_id: authRes.userSub,
          birthday: `${birth_year}-${birth_month}-${birth_day}`,
          email,
          firstName,
          lastName,
          birth_year,
          birth_month,
          birth_day,
          height,
          male,
          bodyweight,
          frequency: workout_days.length,
          promo_code: promoCode,
          start_date,
          fitness_level,
          maxs,
          metric,
          comp_styles,
          amount: 3500,
          autologin: true,
          url: window.location.href,
          handleError: parent.handleError,
          handleValidationError: parent.handleValidationError,
          length_days,
          workout_days,
          program_focus,
          saved_sessions: sessions_info,
          volume_info
        }
        console.log('here be the data')
        console.log(JSON.stringify(submitProps, null, 2))
        await postBuildRequest(submitProps, false)
        .then(async () => {
          parent.setState({ cognito_id: authRes.userSub, loading: false, step: parent.state.step + 1, })
          await sleep(2000)
        })
        .catch((err) => {
          parent.handleError(err.message)
          parent.setState({ loading: false })
        })
      }
    } catch (err) {
      console.error('Auth sign up error --> ', err)
      let stringErr

      if (typeof err === 'string') {
        stringErr = err
      } else if (typeof err === 'object' && err.message) {
        stringErr = err.message
      } else if (typeof err === 'object') {
        stringErr = JSON.stringify(err)
      }

      if (stringErr.toLowerCase().includes('username')) {
        stringErr = stringErr.replace(/Username|username/, 'email')
      }
      parent.setState({ loading: false })
      parent.handleError(stringErr)
    }
  }
}

export const submitCoachCode = async (cognito_id, coach_code, parent) => {
  console.log('coach_code:', coach_code)
  await updateUser({ cognito_id, coach_code })
    .then(async d => {
      console.log('user update data:', d)
      await sleep(1000)
      await Auth.currentAuthenticatedUser({ bypassCache: true }).then((res) => console.log('res', res))
      await sleep(500)
      window.location.href = window.location.href.replace('onboardCoach', 'coach')
    })
    .catch(e => {
      parent.handleError(e.response.data.error)
    })
}

export const registerCoach = async (parent) => {
  console.log('registerAndBuild parent:', parent)
  console.log('registerAndBuild parent keys:', Object.keys(parent))

  let product = "weightliftinghouse"

  let {
    email,
    firstName,
    lastName,
    password,
    loading,
    coach_code
  } = parent.state

  if (loading === false) {
    parent.setState({ plError: "", loading: true })

    const translate = parent.props.translate
    const baseUrl = `https://${window.location.hostname}/`
    const startenv = `${baseUrl}_${translate("language.tag")}`

    try {
      mixpanel.identify(parent.state.email)
      const authRes = await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          "custom:startenv": startenv,
          "custom:product": product,
          "custom:first_name": firstName,
          "custom:last_name": lastName,
          email,
          preferred_username: email,
        },
      })

      console.log('authRes:', authRes)

      // handle potential errors
      if (authRes.message && authRes.message.includes('must have length greater')) {
        parent.handleError(<Translate id="errSixOrMoreCharacters" />)
      } else if (authRes.message && authRes.message.includes('email already exists')) {
        parent.handleError(<Translate id="errEmailTaken" />)
      } else if (authRes.message) {
        parent.handleError(<Translate id="errGeneric" />)
      }

      // if instead we got a userSub then go ahead and update
      if (authRes.userSub) {
        console.log('made it through register! authRes.userSub:', authRes.userSub)
        await Auth.signIn(parent.state.email, parent.state.password)

        // here is where we need to submit a coach code
        console.log('coach_code:', coach_code)
        await updateUser({ cognito_id: authRes.userSub, coach_code })
          .then(async d => {
            console.log('user update data:', d)
            await sleep(1000)
            await Auth.currentAuthenticatedUser({ bypassCache: true }).then((res) => console.log('res', res))
            await sleep(500)
            window.location.href = window.location.href.replace('onboardCoach', 'coach')
          })
          .catch(async e => {
            parent.handleError(e.response.data.error)
            await sleep(1500)
            await Auth.currentAuthenticatedUser({ bypassCache: true }).then((res) => console.log('res', res))
            await sleep(500)
            window.location.href = window.location.href
          })
      }
    } catch (err) {
      console.error('Auth sign up error --> ', err)
      let stringErr

      if (typeof err === 'string') {
        stringErr = err
      } else if (typeof err === 'object' && err.message) {
        stringErr = err.message
      } else if (typeof err === 'object') {
        stringErr = JSON.stringify(err)
      }

      if (stringErr.toLowerCase().includes('username')) {
        stringErr = stringErr.replace(/Username|username/, 'email')
      }
      parent.setState({ loading: false })
      parent.handleError(stringErr)
    }
  }
}