import axios from 'axios';
import { Auth } from 'aws-amplify';

const coachUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin`;

export const getTeam = async (team) => {
  console.log('running getTeam with team:', team)

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const data = {
    get: 'team_record',
    team_name: team,
    source: 'web'
  }

  return axios
    .get(coachUrl, { params: data, headers })
    .then((data) => data.data)
    .catch((error) => console.log(error));
};

export const updateCoach = async (team_name, cognito_id, current_status, new_status) => {
  console.log('running updateCoach for cognito:', cognito_id)

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const params = {
    post: 'update_coach',
    team_name,
    cognito_id,
    current_status,
    new_status,
    source: 'web'
  }

  return axios
    .post(coachUrl, null, { params, headers })
    .then((data) => data.data)
    .catch((error) => console.log(error));
};