import React from 'react';

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { Translate } from "react-localize-redux";

const SelectMale = (props, handleChange) => {
  const { classes, parent } = props

  return (
    <div className={classes.radioRow}>
      <RadioGroup
        style={{}}
        aria-label="male"
        name="male"
        value={parent.state.male}
        onChange={(e) => {
          handleChange({ target: { name: "male", value: e } }, parent);
        }}
        row
      >
        <FormControlLabel
          value={true}
          control={<Radio color="primary" />}
          id="male"
          label={<Translate id="male" />}
          labelPlacement="end"
        />
        <FormControlLabel
          value={false}
          control={<Radio color="primary" />}
          id="female"
          label={<Translate id="female" />}
          labelPlacement="end"
        />
      </RadioGroup>
    </div>
  )
}

export default SelectMale;