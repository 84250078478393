import React, { Component } from "react";
import { withLocalize } from "react-localize-redux";
import { WorkoutDaysSelect } from "../../../components/Form/userForm";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography } from "@material-ui/core";
import { Slider } from "@material-ui/core";
import Button from "@mui/material/Button";
import InfoIcon from "@mui/icons-material/Info";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Tooltip from "@mui/material/Tooltip";
import Alert from "@mui/material/Alert";
import Icon from "@mdi/react";
import { mdiArmFlexOutline } from "@mdi/js";
import { SlSettings } from "react-icons/sl";
import { MdForklift } from "react-icons/md";
import { IoBarbell, IoMedalOutline } from "react-icons/io5";
import { Competition, Technique, Strength, WL_BB, Squat } from "./ExceriseData";
import { ButtonBase } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import 'lightpick/css/lightpick.css';
import Lightpick from 'lightpick';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

let programFocusTextDescription = {
  Competition: {
    summary: 'A peaking plan to squeeze every last kilo of performance out of your Snatch and Clean & Jerk on your selected competition date.',
    intensity: 'High - Very High',
    volume: 'Medium',
    breakdown: 'Competition Prep, Heavy Olympic Lifts',
    duration: '4 - 8 Weeks',
  },
  Technique: {
    summary: 'A program built to correct any out of balance lift ratios and improve the overall technique of the Snatch and Clean & Jerk.',
    intensity: 'Moderate - High',
    volume: 'Moderate - High',
    breakdown: 'Improve Technique, Eliminate Weaknesses',
    duration: '6 - 12 Weeks',
  },
  Strength: {
    summary: 'A program built to raise general strength to new levels.',
    intensity: 'Low - Moderate',
    volume: 'High',
    breakdown: 'Building Basic Strength, Classic Lifts Maintainance',
    duration: '6 - 15 Weeks',
  },
  Bodybuilding: {
    summary: 'Generate a Weightlifting program that challenges both your strength and technique in the Snatch and Clean & Jerk, while concentrating on building a physique that looks like you lift (be more like Klokov).',
    intensity: 'Low - Moderate',
    volume: 'VERY HIGH',
    breakdown: 'Hypertrophy, Basic Strength',
    duration: '6 - 15 Weeks',
  },
  Squat: {
    summary: 'ONLYSQUATS(TM) Build strength in both back and front squat using several squat variations.',
    intensity: 'Moderate - High',
    volume: 'Moderate - High',
    breakdown: 'Squat Strength, Hypertrophy',
    duration: '4 - 12 Weeks',
  },
}

let optimal_sessions_table = {
  'Technique': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 4,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 5,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 5,
        'Much stronger at Clean & Jerk than Snatch': 5,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 5,
        'Stronger at Clean & Jerk than Snatch': 5,
        'Much stronger at Clean & Jerk than Snatch': 6,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 4,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 2,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 5,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 5,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 6,
        'Stronger at Snatch than Clean & Jerk': 5,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      7: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
      9: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': 2,
        'Stronger at Clean than Back Squat': 2,
        'Balanced Back Squat and Clean': 2,
        'Stronger at Back Squat than Clean': 2,
        'Much stronger at Back Squat than Clean': 2,
      },
      7: {
        'Much stronger at Clean than Back Squat': 2,
        'Stronger at Clean than Back Squat': 2,
        'Balanced Back Squat and Clean': 2,
        'Stronger at Back Squat than Clean': 2,
        'Much stronger at Back Squat than Clean': 2,
      },
      8: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      9: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced Strict Press and Jerk': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced Strict Press and Jerk': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced Strict Press and Jerk': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced Strict Press and Jerk': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 6,
        'Stronger at Snatch than Clean & Jerk': 6,
        'Balanced Snatch and Clean & Jerk': 6,
        'Stronger at Clean & Jerk than Snatch': 6,
        'Much stronger at Clean & Jerk than Snatch': 6,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 8,
        'Stronger at Snatch than Clean & Jerk': 8,
        'Balanced Snatch and Clean & Jerk': 8,
        'Stronger at Clean & Jerk than Snatch': 8,
        'Much stronger at Clean & Jerk than Snatch': 8,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 10,
        'Stronger at Snatch than Clean & Jerk': 10,
        'Balanced Snatch and Clean & Jerk': 10,
        'Stronger at Clean & Jerk than Snatch': 10,
        'Much stronger at Clean & Jerk than Snatch': 10,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 12,
        'Stronger at Snatch than Clean & Jerk': 12,
        'Balanced Snatch and Clean & Jerk': 12,
        'Stronger at Clean & Jerk than Snatch': 12,
        'Much stronger at Clean & Jerk than Snatch': 12,
      },
    },
  },
  'Competition': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 4,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 4,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 4,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 4,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 5,
        'Stronger at Clean & Jerk than Snatch': 5,
        'Much stronger at Clean & Jerk than Snatch': 5,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 4,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 4,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 4,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 5,
        'Stronger at Snatch than Clean & Jerk': 5,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 4,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      7: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
      9: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': 2,
        'Stronger at Clean than Back Squat': 2,
        'Balanced Back Squat and Clean': 2,
        'Stronger at Back Squat than Clean': 2,
        'Much stronger at Back Squat than Clean': 2,
      },
      7: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      9: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 1,
        'Stronger at Jerk than Strict Press': 1,
        'Balanced': 1,
        'Stronger at Strict Press than Jerk': 1,
        'Much stronger at Strict Press than Jerk': 1,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 6,
        'Stronger at Snatch than Clean & Jerk': 6,
        'Balanced Snatch and Clean & Jerk': 6,
        'Stronger at Clean & Jerk than Snatch': 6,
        'Much stronger at Clean & Jerk than Snatch': 6,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 8,
        'Stronger at Snatch than Clean & Jerk': 8,
        'Balanced Snatch and Clean & Jerk': 8,
        'Stronger at Clean & Jerk than Snatch': 8,
        'Much stronger at Clean & Jerk than Snatch': 8,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 10,
        'Stronger at Snatch than Clean & Jerk': 10,
        'Balanced Snatch and Clean & Jerk': 10,
        'Stronger at Clean & Jerk than Snatch': 10,
        'Much stronger at Clean & Jerk than Snatch': 10,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 12,
        'Stronger at Snatch than Clean & Jerk': 12,
        'Balanced Snatch and Clean & Jerk': 12,
        'Stronger at Clean & Jerk than Snatch': 12,
        'Much stronger at Clean & Jerk than Snatch': 12,
      },
    },
  },
  'Bodybuilding': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 2,
        'Balanced Snatch and Clean & Jerk': 2,
        'Stronger at Clean & Jerk than Snatch': 2,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 2,
        'Balanced Snatch and Clean & Jerk': 2,
        'Stronger at Clean & Jerk than Snatch': 2,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 2,
        'Balanced Snatch and Clean & Jerk': 2,
        'Stronger at Clean & Jerk than Snatch': 2,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 2,
        'Balanced Snatch and Clean & Jerk': 2,
        'Stronger at Clean & Jerk than Snatch': 2,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 2,
        'Stronger at Clean than Back Squat': 2,
        'Balanced Back Squat and Clean': 2,
        'Stronger at Back Squat than Clean': 2,
        'Much stronger at Back Squat than Clean': 2,
      },
      7: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      9: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': 2,
        'Stronger at Clean than Back Squat': 2,
        'Balanced Back Squat and Clean': 2,
        'Stronger at Back Squat than Clean': 2,
        'Much stronger at Back Squat than Clean': 2,
      },
      7: {
        'Much stronger at Clean than Back Squat': 2,
        'Stronger at Clean than Back Squat': 2,
        'Balanced Back Squat and Clean': 2,
        'Stronger at Back Squat than Clean': 2,
        'Much stronger at Back Squat than Clean': 2,
      },
      8: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      9: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 2,
        'Stronger at Jerk than Strict Press': 2,
        'Balanced Strict Press and Jerk': 2,
        'Stronger at Strict Press than Jerk': 2,
        'Much stronger at Strict Press than Jerk': 2,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 3,
        'Stronger at Jerk than Strict Press': 3,
        'Balanced Strict Press and Jerk': 3,
        'Stronger at Strict Press than Jerk': 3,
        'Much stronger at Strict Press than Jerk': 3,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 3,
        'Stronger at Jerk than Strict Press': 3,
        'Balanced Strict Press and Jerk': 3,
        'Stronger at Strict Press than Jerk': 3,
        'Much stronger at Strict Press than Jerk': 3,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 4,
        'Stronger at Jerk than Strict Press': 4,
        'Balanced Strict Press and Jerk': 4,
        'Stronger at Strict Press than Jerk': 4,
        'Much stronger at Strict Press than Jerk': 4,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 12,
        'Stronger at Snatch than Clean & Jerk': 12,
        'Balanced Snatch and Clean & Jerk': 12,
        'Stronger at Clean & Jerk than Snatch': 12,
        'Much stronger at Clean & Jerk than Snatch': 12,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 15,
        'Stronger at Snatch than Clean & Jerk': 15,
        'Balanced Snatch and Clean & Jerk': 15,
        'Stronger at Clean & Jerk than Snatch': 15,
        'Much stronger at Clean & Jerk than Snatch': 15,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 18,
        'Stronger at Snatch than Clean & Jerk': 18,
        'Balanced Snatch and Clean & Jerk': 18,
        'Stronger at Clean & Jerk than Snatch': 18,
        'Much stronger at Clean & Jerk than Snatch': 18,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 22,
        'Stronger at Snatch than Clean & Jerk': 22,
        'Balanced Snatch and Clean & Jerk': 22,
        'Stronger at Clean & Jerk than Snatch': 22,
        'Much stronger at Clean & Jerk than Snatch': 22,
      },
    },
  },
  'Strength': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 2,
        'Balanced Snatch and Clean & Jerk': 2,
        'Stronger at Clean & Jerk than Snatch': 2,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 2,
        'Stronger at Snatch than Clean & Jerk': 2,
        'Balanced Snatch and Clean & Jerk': 2,
        'Stronger at Clean & Jerk than Snatch': 2,
        'Much stronger at Clean & Jerk than Snatch': 2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 3,
        'Stronger at Snatch than Clean & Jerk': 3,
        'Balanced Snatch and Clean & Jerk': 3,
        'Stronger at Clean & Jerk than Snatch': 3,
        'Much stronger at Clean & Jerk than Snatch': 3,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 4,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 4,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 4,
        'Stronger at Snatch than Clean & Jerk': 4,
        'Balanced Snatch and Clean & Jerk': 4,
        'Stronger at Clean & Jerk than Snatch': 4,
        'Much stronger at Clean & Jerk than Snatch': 4,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      7: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
      9: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      7: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
      9: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 3,
        'Stronger at Jerk than Strict Press': 3,
        'Balanced Strict Press and Jerk': 3,
        'Stronger at Strict Press than Jerk': 3,
        'Much stronger at Strict Press than Jerk': 3,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 3,
        'Stronger at Jerk than Strict Press': 3,
        'Balanced Strict Press and Jerk': 3,
        'Stronger at Strict Press than Jerk': 3,
        'Much stronger at Strict Press than Jerk': 3,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 3,
        'Stronger at Jerk than Strict Press': 3,
        'Balanced Strict Press and Jerk': 3,
        'Stronger at Strict Press than Jerk': 3,
        'Much stronger at Strict Press than Jerk': 3,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 4,
        'Stronger at Jerk than Strict Press': 4,
        'Balanced Strict Press and Jerk': 4,
        'Stronger at Strict Press than Jerk': 4,
        'Much stronger at Strict Press than Jerk': 4,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 8,
        'Stronger at Snatch than Clean & Jerk': 8,
        'Balanced Snatch and Clean & Jerk': 8,
        'Stronger at Clean & Jerk than Snatch': 8,
        'Much stronger at Clean & Jerk than Snatch': 8,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 10,
        'Stronger at Snatch than Clean & Jerk': 10,
        'Balanced Snatch and Clean & Jerk': 10,
        'Stronger at Clean & Jerk than Snatch': 10,
        'Much stronger at Clean & Jerk than Snatch': 10,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 11,
        'Stronger at Snatch than Clean & Jerk': 11,
        'Balanced Snatch and Clean & Jerk': 11,
        'Stronger at Clean & Jerk than Snatch': 11,
        'Much stronger at Clean & Jerk than Snatch': 11,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 12,
        'Stronger at Snatch than Clean & Jerk': 12,
        'Balanced Snatch and Clean & Jerk': 12,
        'Stronger at Clean & Jerk than Snatch': 12,
        'Much stronger at Clean & Jerk than Snatch': 12,
      },
    },
  },
  'Squat': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 3,
        'Stronger at Clean than Back Squat': 3,
        'Balanced Back Squat and Clean': 3,
        'Stronger at Back Squat than Clean': 3,
        'Much stronger at Back Squat than Clean': 3,
      },
      7: {
        'Much stronger at Clean than Back Squat': 4,
        'Stronger at Clean than Back Squat': 4,
        'Balanced Back Squat and Clean': 4,
        'Stronger at Back Squat than Clean': 4,
        'Much stronger at Back Squat than Clean': 4,
      },
      8: {
        'Much stronger at Clean than Back Squat': 5,
        'Stronger at Clean than Back Squat': 5,
        'Balanced Back Squat and Clean': 5,
        'Stronger at Back Squat than Clean': 5,
        'Much stronger at Back Squat than Clean': 5,
      },
      9: {
        'Much stronger at Clean than Back Squat': 6,
        'Stronger at Clean than Back Squat': 6,
        'Balanced Back Squat and Clean': 6,
        'Stronger at Back Squat than Clean': 6,
        'Much stronger at Back Squat than Clean': 6,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 6,
        'Stronger at Snatch than Clean & Jerk': 6,
        'Balanced Snatch and Clean & Jerk': 6,
        'Stronger at Clean & Jerk than Snatch': 6,
        'Much stronger at Clean & Jerk than Snatch': 6,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 8,
        'Stronger at Snatch than Clean & Jerk': 8,
        'Balanced Snatch and Clean & Jerk': 8,
        'Stronger at Clean & Jerk than Snatch': 8,
        'Much stronger at Clean & Jerk than Snatch': 8,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 10,
        'Stronger at Snatch than Clean & Jerk': 10,
        'Balanced Snatch and Clean & Jerk': 10,
        'Stronger at Clean & Jerk than Snatch': 10,
        'Much stronger at Clean & Jerk than Snatch': 10,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 12,
        'Stronger at Snatch than Clean & Jerk': 12,
        'Balanced Snatch and Clean & Jerk': 12,
        'Stronger at Clean & Jerk than Snatch': 12,
        'Much stronger at Clean & Jerk than Snatch': 12,
      },
    },
  },
}

let optimal_volume_table = {
  'Technique': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      7: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      8: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
  },
  'Competition': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      7: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      8: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
  },
  'Bodybuilding': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': -0.1,
        'Much stronger at Back Squat than Clean': -0.2,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      7: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      8: {
        'Much stronger at Clean than Back Squat': -0.2,
        'Stronger at Clean than Back Squat': -0.1,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0.1,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.1,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': -0.1,
        'Much stronger at Strict Press than Jerk': -0.2,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
  },
  'Strength': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': -0.2,
        'Stronger at Snatch than Clean & Jerk': -0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0.1,
        'Much stronger at Clean & Jerk than Snatch': 0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0.2,
        'Stronger at Snatch than Clean & Jerk': 0.1,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': -0.1,
        'Much stronger at Clean & Jerk than Snatch': -0.2,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 0.4,
        'Stronger at Clean than Back Squat': 0.4,
        'Balanced Back Squat and Clean': 0.4,
        'Stronger at Back Squat than Clean': 0.4,
        'Much stronger at Back Squat than Clean': 0.4,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0.3,
        'Stronger at Clean than Back Squat': 0.3,
        'Balanced Back Squat and Clean': 0.3,
        'Stronger at Back Squat than Clean': 0.3,
        'Much stronger at Back Squat than Clean': 0.3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0.3,
        'Stronger at Clean than Back Squat': 0.3,
        'Balanced Back Squat and Clean': 0.3,
        'Stronger at Back Squat than Clean': 0.3,
        'Much stronger at Back Squat than Clean': 0.3,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.2,
        'Balanced Back Squat and Clean': 0.2,
        'Stronger at Back Squat than Clean': 0.2,
        'Much stronger at Back Squat than Clean': 0.2,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': 0.4,
        'Stronger at Clean than Back Squat': 0.4,
        'Balanced Back Squat and Clean': 0.4,
        'Stronger at Back Squat than Clean': 0.4,
        'Much stronger at Back Squat than Clean': 0.4,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0.3,
        'Stronger at Clean than Back Squat': 0.3,
        'Balanced Back Squat and Clean': 0.3,
        'Stronger at Back Squat than Clean': 0.3,
        'Much stronger at Back Squat than Clean': 0.3,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0.3,
        'Stronger at Clean than Back Squat': 0.3,
        'Balanced Back Squat and Clean': 0.3,
        'Stronger at Back Squat than Clean': 0.3,
        'Much stronger at Back Squat than Clean': 0.3,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.2,
        'Balanced Back Squat and Clean': 0.2,
        'Stronger at Back Squat than Clean': 0.2,
        'Much stronger at Back Squat than Clean': 0.2,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 0.4,
        'Stronger at Jerk than Strict Press': 0.4,
        'Balanced Strict Press and Jerk': 0.4,
        'Stronger at Strict Press than Jerk': 0.4,
        'Much stronger at Strict Press than Jerk': 0.4,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 0.3,
        'Stronger at Jerk than Strict Press': 0.3,
        'Balanced Strict Press and Jerk': 0.3,
        'Stronger at Strict Press than Jerk': 0.3,
        'Much stronger at Strict Press than Jerk': 0.3,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 0.3,
        'Stronger at Jerk than Strict Press': 0.3,
        'Balanced Strict Press and Jerk': 0.3,
        'Stronger at Strict Press than Jerk': 0.3,
        'Much stronger at Strict Press than Jerk': 0.3,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 0.2,
        'Stronger at Jerk than Strict Press': 0.2,
        'Balanced Strict Press and Jerk': 0.2,
        'Stronger at Strict Press than Jerk': 0.2,
        'Much stronger at Strict Press than Jerk': 0.2,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
  },
  'Squat': {
    'snatch': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'clean_jerk': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
    'squat': {
      6: {
        'Much stronger at Clean than Back Squat': 0.5,
        'Stronger at Clean than Back Squat': 0.5,
        'Balanced Back Squat and Clean': 0.5,
        'Stronger at Back Squat than Clean': 0.5,
        'Much stronger at Back Squat than Clean': 0.5,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0.4,
        'Stronger at Clean than Back Squat': 0.4,
        'Balanced Back Squat and Clean': 0.4,
        'Stronger at Back Squat than Clean': 0.4,
        'Much stronger at Back Squat than Clean': 0.4,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0.2,
        'Stronger at Clean than Back Squat': 0.2,
        'Balanced Back Squat and Clean': 0.2,
        'Stronger at Back Squat than Clean': 0.2,
        'Much stronger at Back Squat than Clean': 0.2,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'pull': {
      6: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
      7: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
      8: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
      9: {
        'Much stronger at Clean than Back Squat': 0,
        'Stronger at Clean than Back Squat': 0,
        'Balanced Back Squat and Clean': 0,
        'Stronger at Back Squat than Clean': 0,
        'Much stronger at Back Squat than Clean': 0,
      },
    },
    'press': {
      6: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
      7: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
      8: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
      9: {
        'Much stronger at Jerk than Strict Press': 0,
        'Stronger at Jerk than Strict Press': 0,
        'Balanced Strict Press and Jerk': 0,
        'Stronger at Strict Press than Jerk': 0,
        'Much stronger at Strict Press than Jerk': 0,
      },
    },
    'accessory': {
      6: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      7: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      8: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
      9: {
        'Much stronger at Snatch than Clean & Jerk': 0,
        'Stronger at Snatch than Clean & Jerk': 0,
        'Balanced Snatch and Clean & Jerk': 0,
        'Stronger at Clean & Jerk than Snatch': 0,
        'Much stronger at Clean & Jerk than Snatch': 0,
      },
    },
  },
}

export class ChooseWeeks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loaded: true,
      leftButtonTimer: null,
      isLeftHandled: null,
      rightButtonTimer: null,
      isRightHandled: null,
      leftInterval: null,
      rightInterval: null,
      justRightClicked: false,
      advance_optionsBtn: false,
      expanded: false,
      parent: null,
      selectedRange: {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      },
      selectedAccordion: 0,
      opened: true,
      backgroundColor: "rgba(0, 0, 0, 0.04)",
    };

  }

  componentDidMount() {
    let parent = this.props.parent
    let progFocus = parent.state.program_focus
    let workout_length = parent.state.workout_days.length
    this.updateAdvOptParentState(progFocus, workout_length, parent, this.calcSessionsInfos, this.calcVolumeInfos)
    this.setupLightpick(parent);
  }

  setupLightpick = (parent) => {
    const startInput = document.getElementById('start');
    const endInput = document.getElementById('end');
    const startingDay = parent.state.start_date
    const endingDay = parent.state.end_date
    
    new Lightpick({
      field: startInput,
      secondField: endInput,
      // singleDate: false,
      numberOfMonths: 2,
      minDate: new Date(),
      format: 'YYYY-MM-DD',
      repick: true,
      startDate: startingDay,
      endDate: endingDay,
      onSelect: (start, end) => {
        parent.state.start_date = this.formatStartEndDate(start._d)
        parent.state.end_date = end === null ? '': this.formatStartEndDate(end._d)
        if(end !== null){
          parent.state.length_days = this.getDifferenceInDays(this.formatStartEndDate(start._d), this.formatStartEndDate(end._d)) + 1
        }
      },
    });
  };

  updateAdvOptParentState(progFocus, Frequency, parent, calcSessionsInfos, calcVolumeInfos) {
    Frequency = Frequency + 3
    let sessions_info_data = calcSessionsInfos(progFocus, Frequency, parent)
    let volume_info_data = calcVolumeInfos(progFocus, Frequency, parent)

    parent.setState({
      program_focus: progFocus,
      sessions_info: sessions_info_data,
      volume_info: volume_info_data
    })
  }

  calcSessionsInfos = (progFocus, Frequency, parent) => {
    let Sn_CJ_Tag = parent.state.ratio_tag
    let Cl_Sq_Tag = parent.state.ratio_tag_back_cl
    let SP_Jr_Tag = parent.state.ratio_tag_strict_press_jerk

    let optimal_sessions_template = {
      snatch: optimal_sessions_table[progFocus]['snatch'][Frequency][Sn_CJ_Tag],
      clean_jerk: optimal_sessions_table[progFocus]['clean_jerk'][Frequency][Sn_CJ_Tag],
      squat: optimal_sessions_table[progFocus]['squat'][Frequency][Cl_Sq_Tag],
      pull: optimal_sessions_table[progFocus]['pull'][Frequency][Cl_Sq_Tag],
      press: optimal_sessions_table[progFocus]['press'][Frequency][SP_Jr_Tag],
      accessory: optimal_sessions_table[progFocus]['accessory'][Frequency][Sn_CJ_Tag],
    }

    return (optimal_sessions_template)
  }

  calcVolumeInfos = (progFocus, Frequency, parent) => {
    let Sn_CJ_Tag = parent.state.ratio_tag
    let Cl_Sq_Tag = parent.state.ratio_tag_back_cl
    let SP_Jr_Tag = parent.state.ratio_tag_strict_press_jerk

    let optimal_volume_template = {
      snatch: optimal_volume_table[progFocus]['snatch'][Frequency][Sn_CJ_Tag],
      clean_jerk: optimal_volume_table[progFocus]['clean_jerk'][Frequency][Sn_CJ_Tag],
      squat: optimal_volume_table[progFocus]['squat'][Frequency][Cl_Sq_Tag],
      pull: optimal_volume_table[progFocus]['pull'][Frequency][Cl_Sq_Tag],
      press: optimal_volume_table[progFocus]['press'][Frequency][SP_Jr_Tag],
      accessory: optimal_volume_table[progFocus]['accessory'][Frequency][Sn_CJ_Tag],
    }

    return (optimal_volume_template)
  }

  formatStartEndDate(date){
    if(date !== null){
    let objectDate = new Date(date);
    let day = objectDate.getDate();
    let month = objectDate.getMonth();
    let year = objectDate.getFullYear();

    return year+"-"+(month+1)+"-"+day
    } else {
      return ""
    }
  }

  getDifferenceInDays(startDate, endDate) {
    // Create Date objects for the start and end dates
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    // Calculate the difference in milliseconds between the two dates
    const differenceInMilliseconds = endDateObj - startDateObj;

    // Convert milliseconds to days (1 day = 86400000 milliseconds)
    const differenceInDays = Math.floor(differenceInMilliseconds / 86400000);

    return differenceInDays;
  }

  renderCalendarPicker(classes, parent) {

    const labelStyle = {
      marginRight: '5px',
      marginBottom: '5px',
      fontWeight: 'bold',
    };

    const inputStyle = {
      textAlign: 'center',
      padding: '16px',
      fontSize: '16px',
      border: '1px solid #ccc',
      borderRadius: '4px',
    };

    const dashStyle = {
      fontSize: '16px',
      margin: '0 5px',
    };

    return (
      <div>
        <div>
          <input type="text" id="start" readOnly style={inputStyle} />
          <span style={dashStyle}>-</span>
          <input type="text" id="end" readOnly style={inputStyle} />
        </div>
      </div>
    );
  }

  returnIcons(icon) {
    let size = 35
    let textStyle = {
      fontSize: 20,
      width: 160
    }
    let largerContainer = {
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'center',
      width: 250,
    }
    let iconContainer = {
      width: size,
      height: size
    }

    switch (icon) {
      case "Competition":
        return (
          <div style={largerContainer}>
            <div style={iconContainer}>
              <IoMedalOutline size={size} />
            </div>
            <div style={textStyle}>
              Competition
            </div>
          </div>
        )
      case "Technique":
        return (
          <div style={largerContainer}>
            <div style={iconContainer}>
              <SlSettings size={size} />
            </div>
            <div style={textStyle}>
              Technique
            </div>
          </div>
        )
      case "Strength":
        return (
          <div style={largerContainer}>
            <div style={iconContainer}>
              <IoBarbell size={size} />
            </div>
            <div style={textStyle}>
              Strength
            </div>
          </div>
        )
      case "Bodybuilding":
        return (
          <div style={largerContainer}>
            <div style={iconContainer}>
              <Icon path={mdiArmFlexOutline} size={1.5} />
            </div>
            <div style={textStyle}>
              WL + BB
            </div>
          </div>
        )
      case "Squat":
        return (
          <div style={largerContainer}>
            <div style={iconContainer}>
              <MdForklift size={size} />
            </div>
            <div style={textStyle}>
              Squat
            </div>
          </div>
        )
    }
  }

  showProgramFocusText(value, parent, classes) {
    let focusData = programFocusTextDescription[value]

    let ProgFocusTextContainer = {
      width: 500,
      height: 250,
      border: '1px solid #ccc',
      borderRadius: '4px'
    }
    let summaryStyle = {
      height: '40%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid #ccc',
      paddingHorizontal: 10
    }
    let summaryTextStyle = {
      width: '95%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
    let sectionStyle = {
      height: '19.5%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignItems: 'center',
      borderBottom: '1px solid #ccc',
    }
    let sectionStyleBottom = {
      height: '19.5%',
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    }
    let sectionTitleStyle = {
      height: 50,
      width: '23%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold'
    }
    let sectionTitleStyleBottom = {
      height: 50,
      width: '23%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold',
    }
    let sectionInfoStyle = {
      height: 50,
      width: '77%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    let sectionR2StyleTitle = {
      height: 50,
      width: '23%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 'bold'
    }

    let sectionR2StyleMiddle = {
      height: 50,
      width: '27%',
      borderRight: '1px solid #ccc',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    let sectionR2StyleEnd = {
      height: 50,
      width: '27%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }

    return (
      <div style={ProgFocusTextContainer}>
        <div style={summaryStyle}>
          <div style={summaryTextStyle}>
            {focusData['summary']}
          </div>
        </div>

        <div style={sectionStyle}>
          <div style={sectionR2StyleTitle}>
            Intensity:
          </div>
          <div style={sectionR2StyleMiddle}>
            {focusData['intensity']}
          </div>

          <div style={sectionR2StyleTitle}>
            Volume:
          </div>
          <div style={sectionR2StyleEnd}>
            {focusData['volume']}
          </div>
        </div>

        <div style={sectionStyle}>
          <div style={sectionTitleStyle}>
            Best Use:
          </div>
          <div style={sectionInfoStyle}>
            {focusData['breakdown']}
          </div>
        </div>

        <div style={sectionStyleBottom}>
          <div style={sectionTitleStyleBottom}>
            Suggested Duration:
          </div>
          <div style={sectionInfoStyle}>
            {focusData['duration']}
          </div>
        </div>
      </div>
    )
  }

  changeProgramFocus = (event, value) => {
    let parent = this.props.parent
    value = event.target.innerText
    if (value == 'WL + BB') {
      value = 'BODYBUILDING'
    }
    value = value.toLowerCase()
    value = value.charAt(0).toUpperCase() + value.slice(1)

    this.updateAdvOptParentState(value, this.props.parent.state.workout_days.length, parent, this.calcSessionsInfos, this.calcVolumeInfos)
  }

  renderProgramFocus(classes, parent) {
    let progFocus = parent.state.program_focus
    let spacer = {
      height: 50,
      width: 50
    }

    return (
      <div className={classes.ProgFocusSuperContainer}>
        <div>
          <ToggleButtonGroup
            required
            defaultValue={[progFocus]}
            value={[progFocus]}
            color="primary"
            size="small"
            orientation="vertical"
            onChange={this.changeProgramFocus}
            className={classes.selectDaysForm}
          >
            <ToggleButton value="Competition">{this.returnIcons("Competition")}</ToggleButton>
            <ToggleButton value="Technique">{this.returnIcons("Technique")}</ToggleButton>
            <ToggleButton value="Strength">{this.returnIcons("Strength")}</ToggleButton>
            <ToggleButton value="Bodybuilding">{this.returnIcons("Bodybuilding")}</ToggleButton>
            <ToggleButton value="Squat">{this.returnIcons("Squat")}</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={classes.spacer}/>
        <div>
          {this.showProgramFocusText(progFocus, parent, classes)}
        </div>
      </div>
    )
  }

  handleTableChange(PMG, value, parent, type, operation) {

    let sessions_info_copy = {...parent.state.sessions_info}
    let volume_info_copy = {...parent.state.volume_info}
    PMG = PMG === 'C&J' ? 'clean_jerk' : PMG.toLowerCase()

    switch (type) {
      case "session":
        sessions_info_copy[PMG] = operation === 'add' ? sessions_info_copy[PMG] + 1 : sessions_info_copy[PMG] - 1
        break
      case 'volume':
        volume_info_copy[PMG] = value / 100
        break
      default:
        break
    }

    parent.setState({
      sessions_info: sessions_info_copy,
      volume_info: volume_info_copy
    })
  }

  formatValueLabel(value) {
    if (value > 0) {
      return `+${value}%`;
    }
    return `${value}%`;
  }

  renderAdvanceOptionsAdjuster(classes, parent, show) {
    const rows = ["Snatch", "C&J", "Squat", "Pull", "Press", "Accessory"]

    let row_session_mapping = {
      Snatch: parent.state.sessions_info['snatch'],
      "C&J": parent.state.sessions_info['clean_jerk'],
      Squat: parent.state.sessions_info['squat'],
      Pull: parent.state.sessions_info['pull'],
      Press: parent.state.sessions_info['press'],
      Accessory: parent.state.sessions_info['accessory'],
    }

    const row_volume_mapping = {
      Snatch: parent.state.volume_info['snatch'],
      "C&J": parent.state.volume_info['clean_jerk'],
      Squat: parent.state.volume_info['squat'],
      Pull: parent.state.volume_info['pull'],
      Press: parent.state.volume_info['press'],
      Accessory: parent.state.volume_info['accessory'],
    }

    if (show) {
      return (
        <div className={classes.dateSelectorSuperContainer}>
          <div>
            <div>
              These flexible options give you the control you to fine-tune the
              AI's focus, ensuring that your program precisely matches your
              desired training priorities.
            </div>
            <br></br>
            <br></br>
            <Alert severity="warning" sx={{}}>
              These advanced options will impact the overall program and making
              adjustments may cause the program to deviate significantly from
              the optimized plan that the Ai would build.
            </Alert>
            <br></br>
            <br></br>

            <TableContainer>
              <Table sx={{ minWidth: 750 }}>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <Tooltip
                        title="Each category includes all exercises related to specified movement."
                        placement="top-end"
                        color="initial"
                      >
                        <Typography variant="subtitle1" align="center">
                          Category
                          <InfoIcon
                            fontSize="inherit"
                            style={{ verticalAlign: "top-end" }}
                          />
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip
                        title="Frequency refers to the number of sessions per week that are programmed."
                        placement="top-end"
                      >
                        <Typography variant="subtitle1" align="center">
                          Session Frequency
                          <InfoIcon
                            fontSize="inherit"
                            style={{ verticalAlign: "top" }}
                          />
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip
                        title="Adjusting this slider affects the amount of volume that the program assigns to the workouts for the corresponding movement. Changing this number will have a direct impact on the size of each workout you recieve."
                        placement="top"
                      >
                        <Typography variant="subtitle1" align="center">
                          Volume
                          <InfoIcon
                            fontSize="inherit"
                            style={{ verticalAlign: "top" }}
                          />
                        </Typography>
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => {
                    return (
                      <TableRow
                        key={row}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" component="th" scope="row">
                          <Typography variant="body1" align="center">
                            {row}
                          </Typography>
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          <div className={classes.dateSelectorContainer}>
                            <div className={classes.dateSelector}>
                              <div
                                aria-label={row}
                                className={classes.iconDisplay}
                                style={{
                                  borderTopLeftRadius: 8,
                                  borderBottomLeftRadius: 8,
                                }}
                                onClick={(event, value) => {
                                  this.handleTableChange(
                                    event.target.ariaLabel,
                                    value,
                                    parent,
                                    "session",
                                    "subtract"
                                  );
                                }}
                              >
                                -
                              </div>
                              <div
                                className={classes.startEndDateDisplayContainer}
                              >
                                {row_session_mapping[row]}
                              </div>
                              <div
                                aria-label={row}
                                className={classes.iconDisplay}
                                style={{
                                  borderTopRightRadius: 8,
                                  borderBottomRightRadius: 8,
                                }}
                                onClick={(event, value) => {
                                  this.handleTableChange(
                                    event.target.ariaLabel,
                                    value,
                                    parent,
                                    "session",
                                    "add"
                                  );
                                }}
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell
                          align="center"
                          component="th"
                          scope="row"
                          sx={{ minWidth: 350 }}
                        >
                          <Slider
                            className={classes.slider}
                            value={row_volume_mapping[row] * 100}
                            aria-label={row}
                            valueLabelFormat={this.formatValueLabel}
                            size="large"
                            step={10}
                            marks
                            min={-50}
                            max={50}
                            valueLabelDisplay="on"
                            onChange={(event, value) => {
                              // console.log("parent 5", parent.state);
                              this.handleTableChange(
                                row,
                                value,
                                parent,
                                "volume",
                                null
                              );
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }

  handleButtonClick() {
    let advanceBtnValue = !this.state.advance_optionsBtn;
    this.setState({ advance_optionsBtn: advanceBtnValue });
  }

  renderAdvanceOptions(classes) {
    return (
      <div className={classes.dateSelectorSuperContainer}>
        <Button
          sx={{ marginTop: 2 }}
          className={classes.nextBtnWH}
          onClick={this.handleButtonClick.bind(this)}
          variant="contained"
        >
          Advanced Options
        </Button>
        <div style={{ backgroundColor: "white" }}>
          {this.renderAdvanceOptionsAdjuster(
            classes,
            this.props.parent,
            this.state.advance_optionsBtn
          )}
        </div>
      </div>
    );
  }

  render() {
    const { classes, parent } = this.props;

    return (
      <div className={classes.formStepContainerv2}>
        <WorkoutDaysSelect
          parent={this.props.parent}
          calcSessionsInfos={this.calcSessionsInfos}
          calcVolumeInfos={this.calcVolumeInfos}
          callback={this.updateAdvOptParentState}
        />
        <div className={classes.formStepContentv2}>
          <br></br>
          <div className={classes.formItemTitle}>CHOOSE PROGRAM DURATION</div>
          {this.renderCalendarPicker(classes, parent)}
          <br></br>
          <div className={classes.formItemTitle} style={{ marginBottom: 10 }}>CHOOSE PROGRAM FOCUS</div>
          {this.renderProgramFocus(classes, parent)}
          {this.renderAdvanceOptions(classes, parent)}
        </div>
      </div>
    );
  }
}

export default withLocalize(ChooseWeeks);