import { connect } from 'react-redux';

import _WorkoutSettings from './layout';
import { fetchUserData } from '../../state/thunk';
import { toggleLoading } from '../../state/actions';

const mapStateToProps = (state) => ({
  userData: state,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: (userName) => dispatch(fetchUserData(userName)),
  toggleLoading: () => dispatch(toggleLoading()),
});

export const WorkoutSettings = connect(mapStateToProps, mapDispatchToProps)(_WorkoutSettings);
