import React from 'react';
import { Grid, Button, Paper, Box, Container } from '@material-ui/core';


export default ({ movement, classes }) => {
    const showProps = ['reps', 'weight'];
    return (
        <Container maxWidth='lg'>
            <Box textAlign="left" ml={2} mb={3}>
                {/* <h3>Movemet {movement.movement_index + 1}</h3> */}
            </Box>
            <Grid container alignItems="stretch" wrap='nowrap' spacing={2} justify="space-evenly">
                {
                    movement.sets.map((set, i) =>
                        <Grid item>
                            <Box component={Paper} elevation={3} p={2}>

                                <Box textAlign="left" fontWeight='fontWeightBold' mb={3}>Set {i + 1}</Box>
                                <Box overflow='auto' maxHeight="15em">
                                    <Grid container spacing={1}>
                                        {
                                            Object.entries(set).map(([key, val]) =>
                                                showProps.includes(key) &&
                                                <Grid item xs>
                                                    {/* Variable */}
                                                    <Button variant="outlined" color="default">{key}: {val}</Button>
                                                </Grid>
                                            )
                                        }
                                    </Grid>
                                </Box>
                            </Box>
                        </Grid>
                    )
                }
            </Grid>
        </Container>
    )
}

