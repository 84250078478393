export const styles = {
  button: {
    padding: '10px 60px',
    backgroundColor: '#ddd',
    cursor: 'pointer',
    borderRadius: '3px',
    ':hover': {
      backgroundColor: '#ededed'
    }
  },
  signinContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    paddingTop: 80,
    alignItems: 'center',
    alignSelf: 'center',
    width: '80%'
  },
  buttonText: {
    margin: 0
  },
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '15px',
    width: '300px'
  },
  formContainer: {
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    maxWidth: 400,
    width: '100%'
  },
  formTitle: {
    marginTop: 0,
    fontSize: 25,
    letterSpacing: 1.35,
    fontWeight: 900,
    fontFamily: 'Watchword'
  },
  input: {
    height: 40,
    marginBottom: '10px',
    border: 'none',
    outline: 'none',
    borderBottom: '2px solid #4CAF50',
    fontSize: '16px',
    '::placeholder': {
      color: 'rgba(0, 0, 0, .3)'
    }
  },
  error: {
    color: 'red',
    textAlign: 'center'
  },
  confirmationText: {
    textAlign: 'center',
    padding: '0 20px',
    margin: 0,
    maxWidth: 200
  },
  labelRoot: {
    fontSize: 12
  },
  maxContainer: {
    display: 'flex',
    width: '100%',
    margin: '10px 0'
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: '100%',
    marginTop: 20
  },
  onboardRoot: {
    height: 40,
    width: '100%',
    backgroundColor: 'rgba(0,33,65,1)',
    border: '1px solid #fff',
    borderRadius: 30,
    '&:hover': {
      backgroundColor: 'rgba(0,33,65,.8)'
    }
  },
  onboardLabel: {
    color: '#fff',
    fontSize: 17,
    letterSpacing: 1.35,
    fontWeight: 900,
    fontFamily: 'Watchword'
  },
};
