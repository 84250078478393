let isodays = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']

export const parseStripe = (xhrResponse) => {
  const response = JSON.parse(xhrResponse);
  console.log(response);
  console.log(response['redirect']['url']);
  this.setState({
    stripeUrl: response['redirect']['url'],
  });
};

const makeDateString = (d) => {
  return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${(d.getDate()).toString().padStart(2, '0')}`
}

export const getNextMonday = (workout_days = null) => {
  // console.log('running getNextMonday')
  // get today
  let td = new Date()
  td.setSeconds(td.getSeconds() + 1) //so we can compare further down
  let today = makeDateString(td)

  // get tomorrow
  let tm = new Date(td.valueOf())
  tm.setDate(td.getDate() + 1)
  let tomorrow = makeDateString(tm)

  // calculate this coming monday to find initial start_date
  let sd = new Date();
  sd.setDate(sd.getDate() + ((1 + 7 - sd.getDay()) % 7));
  let start_date = makeDateString(sd)

  // base end_date on 12 weeks ahead of this coming monday, minus two days so it's a saturday
  let ed = new Date(sd.valueOf());
  ed.setDate(sd.getDate() + 82)
  let end_date = makeDateString(ed)

  // if workout days are provided, find the next workout day
  let next_workout_date, next_workout_day
  if (workout_days) {
    let previousMonday = new Date(sd.valueOf()); // start from monday
    previousMonday.setDate(sd.getDate() - (td.getDay() === 1 ? 0 : 7))

    let workout_dates = workout_days.map((d) => {
      // console.log('d:', d, isodays.indexOf(d))
      let workout_date = new Date(previousMonday.valueOf())
      workout_date.setDate(previousMonday.getDate() + isodays.indexOf(d))
      return workout_date
    })
    console.log('workout_dates:', workout_dates)
    workout_dates = workout_dates.filter(x => x > td)
    if (workout_dates.length > 0) {
      next_workout_date = workout_dates[0]
      next_workout_day = days[next_workout_date.getDay()]
      next_workout_date = makeDateString(next_workout_date)
      // console.log('next_workout_date', next_workout_date)
    }
  }

  return { start_date, end_date, today, tomorrow, next_workout_date, next_workout_day }
};

export const getDates = (workout_days) => {
  // this function needs to return the program today, next workout day, and next monday

  // get today
  let td = new Date()
  td.setSeconds(td.getSeconds() + 1) //so we can compare further down
  console.log('td:', td)
  let today = makeDateString(td)

  // calculate next monday
  let nm = new Date();
  nm.setDate(nm.getDate() + ((1 + 7 - nm.getDay()) % 7));
  td.getDay() === 1 && nm.setDate(nm.getDate() + 7)
  let next_monday = makeDateString(nm)

  // if workout days are provided, find the next workout day
  let next_workout_date, next_workout_day
  if (workout_days) {
    let previousMonday = new Date(nm.valueOf()); // start from monday
    previousMonday.setDate(nm.getDate() - 7)

    let workout_dates = workout_days.map((d) => {
      let workout_date = new Date(previousMonday.valueOf())
      workout_date.setDate(previousMonday.getDate() + isodays.indexOf(d))
      return workout_date
    })
    workout_dates = workout_dates.filter(x => x > td)
    if (workout_dates.length > 0) {
      next_workout_date = workout_dates[0]
      next_workout_day = days[next_workout_date.getDay()]
      next_workout_date = makeDateString(next_workout_date)
    }
  }

  return { today, next_monday, next_workout_date, next_workout_day }
};


export function get_days(month, year) {
  var months = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]
  var days_per_month = months[month - 1]

  if (month === 2) {
    if (year % 4) {
      days_per_month = 29;
    };
  };

  days_per_month = [...Array(days_per_month).keys()].map(x => x + 1);

  return days_per_month;
};

export default get_days