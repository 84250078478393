import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import "date-fns";
import { styles } from "./styles";
import mixpanel from 'mixpanel-browser';

class _Support extends React.Component {
  state = {
  };

  async componentDidMount() {
    mixpanel.track("view support");
  }

  handleValidationError = (errors) => {
    console.log("validation error object:", errors);
  };

  render() {

    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <div className={classes.formContainer}>
        </div>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(_Support));
