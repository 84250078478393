import axios from 'axios';
import { Auth } from 'aws-amplify';

export const dayOptionsTranslations = {
  一周3练: 3,
  一周4练: 4,
  一周5练: 5,
  一周6练: 6
};

let url = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/programs/`

let config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

export const submitData = async (pl) => {
  console.log('workout-settings submitData pl:', pl)

  pl['time'] = new Date().toLocaleString('en-GB')
  pl['source'] = 'web'

  let authToken = await (await Auth.currentSession()).idToken.jwtToken;
  config.headers.Authorization = authToken

  url = `${url}${pl.program_id}`

  const data = JSON.stringify(pl);
  console.log('return data:', data)

  return axios
    .put(url, data, config)
    .then(data => data.data)
};

export default submitData;