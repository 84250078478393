import React, { Component } from 'react'
import { MaxsForm } from "../../../components/Form/userForm";
import { withLocalize } from 'react-localize-redux'

export class MaxLifts extends Component {
  constructor(props) {
    super(props)
    this.state = { loaded: true }
  }

  render() {
    const { classes, props, selectedLifts } = this.props
    const metric = props.state.metric

    // determine if we should show metric select
    const showSelectMetric = selectedLifts.includes('snatch')

    return (
      <div className={classes.formStepContainer}>
        <div className={classes.formStepContent}>
          <MaxsForm
            parent={props}
            units={metric ? 'kg' : 'lb'}
            selectedLifts={selectedLifts}
            showSelectMetric={showSelectMetric}
          />
        </div>
      </div>
    )
  }
}

export default withLocalize(MaxLifts)