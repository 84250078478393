import moment from 'moment';
import React from 'react';
import { Translate } from "react-localize-redux";

export const getUniques = (movement, comp_styles) => {
  if (movement.unique_moves) {
    return movement
  } else {
    // we don't have a unique moves key, we need to make it
    let uniques = movement.movement_name.en.split(' (')[0].split(' + ')
    uniques = uniques.map(x => x === 'jerk' ? comp_styles.jerk.replace('_', ' ') : x)
    movement.unique_moves = uniques
    return movement
  }
}

export const day_of_week = [
  {},
  { 'ch': '星期天', 'en': 'Monday' },
  { 'ch': '星期一', 'en': 'Tuesday' },
  { 'ch': '星期二', 'en': 'Wednesday' },
  { 'ch': '星期三', 'en': 'Thursday' },
  { 'ch': '星期四', 'en': 'Friday' },
  { 'ch': '星期五', 'en': 'Saturday' },
  { 'ch': '星期六', 'en': 'Sunday' }
]

export const getDates = () => {
  var end_date = moment().endOf('isoweek').toDate();
  end_date = end_date.toLocaleDateString('en-US');
  var start_date = new Date();
  start_date.setDate(start_date.getDate() - 10);
  start_date = start_date.toLocaleDateString('en-US');
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow = tomorrow.toLocaleDateString('en-US');
  return { end_date, start_date, tomorrow };
};

export function flatten(arr) {
  return arr.reduce(function (flat, toFlatten) {
    return flat.concat(
      Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten
    );
  }, []);
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};


export const closeDialog = async (props) => {
  await sleep(500);

  props.setState({
    dialogOpen: false,
  });
};

export const handleSnackbar = (props) => {
  const snackbarOpen = props.state.snackbarOpen
  props.setState({
    snackbarOpen: !snackbarOpen
  })
}

export const setDialog = (message, props) => {
  props.setState({
    dialogOpen: true,
    dialogText: message,
  });
  closeDialog(props);
};

export function renderEndMessage(classes, props) {
  if (props.state.items.length === 0) {
    // your cycle will begin:
    return (
      <div className={classes.waitingContainer}>
        <br />
        <br />
        <br />
        <br />
        <Translate id="programStartsMonday" />
      </div>
    );
  } else {
    // that's all!
    return <h4>
      <Translate id="thatsAll" />
    </h4>
  }
}

export function handleNote(micro, props) {
  if (micro.micro_note[props.activeLanguage.tag] !== null) {
    return (
      micro.micro_note[props.activeLanguage.tag].split("<br />").map((item, i) => {
        return <p key={i}>{item}</p>;
      })
    )
  } else {
    return (
      null
    )
  }
};