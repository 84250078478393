import axios from 'axios';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { getUniques } from './utils'

mixpanel.init('fb6d0cf9cf3b6d33c7c68d30106861a7');

const workoutsUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/workouts`

const config = {
  headers: {
    'Content-Type': 'application/json'
  }
};

const extendMovements = async (newData, props) => {
  console.log('running extendMovements...')
  if (newData.length) {
    var newItems = props.state.items
    newItems = newItems.concat(newData)
    props.setState({
      items: newItems
    });
  } else {
    console.log('No new items, setting hasMore to false')
    props.setState({
      hasMore: false,
      message: "That's all!",
    });
  }

  console.log('this.state after extendMovements:', props.state);
};

export const fetchData = async (payload, parent, range = null) => {
  mixpanel.track('get workouts');
  const date = new Date()
  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = { 'Authorization': authToken }

  const data = {
    cognito_id: payload.cognito_id,
    program_id: parent.state.program_id,
    program: parent.state.program,
    micro_hold_offset: payload.micro_hold_offset,
    metric: parent.state.metric,
    local_date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`,
    program_start: parent.state.start_date,
    source: 'web'
  };

  if (range) {
    data['start_micro'] = range['start_micro'];
    data['end_micro'] = range['end_micro']
  }

  return axios
    .get(workoutsUrl, { params: data, headers })
    .then(data => {
      const workouts = data.data;
      return extendMovements(workouts, parent);
    })
    .catch(err => console.log(err));
};


export const sendResults = async (data, set_index, parent) => {
  mixpanel.track('send results');
  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var specialConfig = config;
  specialConfig.headers.Authorization = authToken;

  let movements = {}
  if (set_index) {
    data = getUniques(data, parent.state.comp_styles)

    let library = parent.props.userData.movementsLibrary ? parent.props.userData.movementsLibrary : {}
    data.unique_moves.map(x => movements[x] = Object.keys(library).includes(x) ? library[x] : [])
    console.log('attaching movements!', movements)
  }

  const payload = {
    metric: parent.state.metric,
    new_obj: data,
    program: parent.state.program,
    set_index,
    movements,
    source: 'web'
  }

  return axios
    .post(workoutsUrl, payload, specialConfig)
    .then(data => {
      if (data.movements_library) {
        console.log('got movements library!', data.movements_library)
        let library = parent.props.userData.movementsLibrary
        Object.keys(data.movements_library).map(x => library[x] = data.movements_library[x])
        parent.state.props.updateMovementsLibrary(library)
      }
      return data
    })
    .catch(err => { console.log(err); return 'error'; });
};

export const fetchMoreData = (items, payload, props) => {
  if (items.length) {
    const lowest_micro = items[items.length - 1]['micro_count']
    console.log(`fetching more data... with lowest_micro ${lowest_micro}`);

    const range = { 'end_micro': lowest_micro, 'start_micro': lowest_micro - 2 }

    fetchData(payload, props, range);
  } else {
    console.log('running fetchMoreData with a zero list of items...')
  }
};

export const submitResultsData = changedResults => {
  console.log(changedResults);
  return sendResults(changedResults);
};
