export const styles = () => ({

  container: {
    display: 'flex',
    maxWidth: 400,
    margin: 'auto',
    textAlign: 'left',
    flexDirection: 'column',
    marginTop: '63px',
    padding: '15px'
  },
  oj: {
    border: '1px solid black'
  },
  subTitleBox: {
    marginTop: '10px',
    paddingBottom: '7px',
    marginBottom: '10px',
    borderBottom: '1px solid #9e9e9e',
    fontWeight: 600,
    fontSize: 24

  },
  detailBox: {
    marginBottom: '10px',
    marginTop: '15px',
    textAlign: 'left',
    // border: '1px solid black'

  },
  titleBox: {
    paddingBottom: '7px',
    marginBottom: '10px',
    borderBottom: '1px solid #616161',
    fontWeight: 600,
    fontSize: 24

  },
  descriptionBox: {
    fontWeight: 400,
    fontSize: 16,
  },
  isActive: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#4caf50'

  },
  isOnHold: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#f44336'
  },
  isPending: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#ffa000'
  },
  settingBox: {
    marginTop: '0',
    padding: '5px 15px 10px 15px',
    // outline: '1px solid black'
    // backgroundColor: '#fafafa'
  },
  statusContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginBottom: '10px'
  },
  workOut: {
    color: 'green',
    fontSize: 20
  },
  buttonContainer: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'flex-end',
    verticalAlign: 'middle',
    paddingTop: '2%',
  },
  form: {
    display: 'flex',
    maxWidth: 400,
    width: '80%',
    margin: 'auto'
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  maxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 0 5px 0',
    justifyContent: 'center',
  },
  textField: {
    padding: '0 5px 0 0',
    maxWidth: 180,
    maxHeight: 200,
    height: '100%',
    display: 'flex',
    verticalAlign: 'middle'
  },
  selectField: {
    margin: '5px 10px 10px 0px',
    minWidth: '400'
  },
  labelRoot: {
    fontSize: 12
  },
  sectionText: {
    color: '#000000',
    fontFamily: 'Jura',
    fontSize: 16,
    letterSpacing: 1.15,
    fontWeight: 600
  },
  fieldText: {
    fontSize: 10,
    textAlign: 'left',
    color: '#757575'
  },
  radioOptionGreen: {
    marginTop: 10,
    backgroundColor: '#e0ffde',
    padding: 10,
    margin: 'auto',
    borderRadius: 10
  },
  radioOptionYellow: {
    marginTop: 10,
    backgroundColor: '#f9ffd8',
    padding: 10,
    margin: 'auto',
    borderRadius: 10
  },
  radioOptionRed: {
    marginTop: 10,
    backgroundColor: '#f9d5d2',
    padding: 10,
    margin: 'auto',
    borderRadius: 10
  },
  radioButton: {
    margin: 'auto'
  },
  radioHeader: {
    fontSize: 20,
    padding: 0,
    margin: -6,
    marginBottom: -12,
    fontWeight: 'bold'
  },
  radioDescription: {
    textAlign: 'left'
  },
  radio: {
    '&$checked': {
      color: '#3f51b5'
    }
  },
  checked: {
    fontColor: '#b0b0b0'
  }
});
