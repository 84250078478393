export const styles = () => ({
  form: {
    display: "flex",
    maxWidth: 400,
    width: "100%",
    margin: "auto",
  },
  formRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 0 5px 0",
  },
  workoutDaysContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  formItemTitle: {
    paddingTop: 20,
    fontSize: 16,
    fontWeight: "700",
    color: "#9e9e9e",
    alignSelf: "center",
    marginBottom: 15,
  },
  selectDaysForm: {
    display: "flex",
    alignSelf: "center",
  },
  selectDayText: {
    fontSize: 6,
  },
  selectDaysExplainText: {
    fontSize: 11,
    fontStyle: "italic",
    paddingTop: 5,
  },
  radioRow: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 0 5px 0",
  },
  formGroup: {
    width: "100%",
    padding: "0px 0 30px 0",
  },
  maxsGroup: {
    paddingBottom: "10px",
  },
  fieldText: {
    color: "rgba(0, 0, 0, 0.54)",
    margin: "10px 0px 5px 0px",
  },
  radioGroup: {
    margin: "auto",
  },
  selectedFitnessOption: {
    borderWidth: "3px",
    borderStyle: "solid",
    borderRadius: 10,
    padding: "3px",
    color: "#003466",
    borderColor: "#003466",
    backgroundColor: "rgb(0,52,102,0.13)",
  },
  unselectedFitnessOption: {
    borderWidth: "3px",
    borderRadius: 10,
    padding: "3px",
    backgroundColor: "white",
    borderColor: "rgb(0,0,0,.3)",
    borderStyle: "solid",
  },
  fitnessOption: {
    margin: "auto",
    width: "100%",
    borderRadius: 10,
    backgroundColor: "white",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "column",
  },
  radioButton: {
    margin: "auto",
    width: "100%",
    display: "flex",
    flex: "1 1 0px",
  },
  radioHeader: {
    fontSize: 12,
    padding: 0,
  },
  radioDescription: {
    textAlign: "center",
    fontSize: 14,
    padding: "2px 2px 5px 2px",
  },
  radio: {
    width: "100%",
    backgroundColor: "transparent",
  },
  checked: {
    color: "#003466",
    borderStyle: "solid",
  },
  formControlLabelRoot: {
    width: "100%",
    backgroundColor: "transparent",
    color: "black",
  },
  labelRoot: {
    fontSize: 12,
    color: "rgba(0, 0, 0, 0.54)",
    "&:focused": { color: "rgba(0, 0, 0, 0.54)" },
  },
  formHelperText: {
    fontSize: 10,
    marginBottom: "2px",
  },
  spacingInput: {
    margin: "0 10px 0 0",
    width: "100%",
  },
  wlWeightInputRequired: {
    margin: "0 0 0 0",
    width: "100%",
  },
  wlWeightInputNotRequired: {
    margin: "0 0 0 0",
    width: "50%",
  },
  wlCheckboxInput: {
    margin: "0 0 0 0",
    width: "45%",
  },
  weightInput: {
    margin: "0 5px 0 0",
    width: "30%",
  },
  xrmInput: {
    margin: "0 5px 0 5px",
    width: "30%",
  },
  styleInput: {
    margin: "0 0px 0 5px",
    width: "40%",
  },
  formContainer: {
    display: "flex",
    maxWidth: 400,
    width: "80%",
    margin: "auto",
  },
  payButton: {
    display: "flex",
    border: "1px solid black",
    borderRadius: 4,
    backgroundColor: "rgb(0, 33, 65)",
    height: 100,
    marginTop: 10,
    cursor: "pointer",
    width: "100%",
    alignSelf: "center",
    margin: "auto",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  buttonContainer: {
    display: "flex",
    marginTop: "30px",
    justifyContent: "center",
  },
  appStoreNoticeContainer: {
    display: "flex",
    marginTop: "30px",
    justifyContent: "center",
    maxWidth: 400,
    fontStyle: "italic",
    alignSelf: "center",
  },
  img: {
    width: "50%",
    height: "auto",
    borderRadius: "0 4px 4px 0",
  },
  circularProgressPrimaryColor: {
    stroke: "rgb(0, 160, 234)",
  },
  btnTextContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  btnText: {
    fontSize: 25,
    fontWeight: 600,
    color: "white",
  },
  btnTextSmall: {
    fontSize: 15,
    fontWeight: 400,
    color: "white",
    paddingTop: 5,
  },
  underButtonText: {
    fontSize: 11,
    paddingTop: 5,
    fontStyle: "italic",
  },
  purchaseButtonFormContainer: {
    width: "100%",
  },
  promoRow: {
    display: "flex",
    flexDirection: "row",
  },
  promoApplyButton: {
    marginTop: 10,
    marginLeft: 10,
  },
  teamStatusContainer: {
    padding: 5,
    borderRadius: 5,
    color: "white",
    fontWeight: "700",
    fontSize: 12,
    textTransform: "uppercase",
    margin: 5,
  },
  teamDeleteContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "red",
    borderRadius: 5,
    color: "white",
    fontWeight: "700",
    fontSize: 12,
    margin: 5,
  },
  buttonStyle: {
    flex: 1,
    width: "70%",
    paddingTop: 0,
    paddingBottom: 0,
  },
});
