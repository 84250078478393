import React from 'react';
import List from '@material-ui/core/List';
import componentMovements from './Movements'
import ListItem from '@material-ui/core/ListItem';

function mainLiftsCompleted(day) {
    var mainLiftsCompleted = true
    for (var movement of day.movements) {
        if (movement.accessory === false && movement.movement_completed === false) {
            mainLiftsCompleted = false
        }
    }
    return mainLiftsCompleted
}

function componentDays(
    classes,
    micro,
    indices,
    parent
) {
    var day = micro.days[indices[1]]
    day.mainLiftsCompleted = mainLiftsCompleted(day)

    let moveIds = day.movements.map(x => x.movement_id)
    let moveIndices = day.movement_order.map(x => moveIds.indexOf(x))

    if (day.movements.length > 0) {
        return (
            <div className={classes.days} key={day.day_id}>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    <ListItem
                        component="div"
                        id="nested-list-subheader"
                        disableGutters
                        className={classes.dateListItem}
                    >
                        {day['day_of_week'][parent.props.activeLanguage.tag]},
                        {' '}
                        {day['date_utc']}
                    </ListItem>
                    {
                        moveIndices.map((movement_index) => componentMovements(
                            classes,
                            day,
                            indices.concat(movement_index),
                            parent
                        )
                        )
                    }
                </List >
            </div >
        )
    }
}

export default componentDays