import React from 'react';
import { styles } from './styles';
import { Translate } from "react-localize-redux";
import Checkbox from '@material-ui/core/Checkbox';
import { FormControl, withStyles, TextField, FormControlLabel, FormHelperText, } from "@material-ui/core";
import { adjustWeight, dayOptions } from "./utils";
import { adjustMaxs } from '../../utils/helpers'
import { maxLifts } from './utils'
import CloseIcon from '@material-ui/icons/Close';

import SelectMetric from './Fields/selectMetric'
import EnterBirthday from './Fields/enterBirthday'
import EnterHeight from './Fields/enterHeight';
import EnterWeight from './Fields/enterWeight';
import SelectMale from './Fields/selectMale';
import SelectFitness from './Fields/selectFitness'
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


const handleChange = async (e, props) => {
  // console.log('handleChange target.name:', e.target.name, 'value:', e.target.value)
  const name = e.target.name.split("~");
  var value = e.target.value;
  var maxs = null

  // console.log('split up name:', name)

  if (name.includes('workout_days')) {
    let workout_days = props.state.workout_days
    if (workout_days.includes(value)) {
      if (workout_days.length > 3) {
        workout_days = workout_days.filter(x => x !== value)
      }
    } else {
      if (workout_days.length < 6) {
        workout_days.push(value)
      }
    }

    // these two lines are for making sure we're sorted in the right order, important elsewhere
    let days = dayOptions.map(x => x.value)
    workout_days = days.filter(x => workout_days.includes(x))
    // console.log('sorted workout_days:', workout_days)

    props.setState({ workout_days })
    return props
  } else if (name.includes("male")) {
    props.setState({
      male: !props.state.male,
    });
  } else if (name.includes("metric")) {
    maxs = adjustMaxs(props.state.maxs, props.state.metric)
    let bodyweight = adjustWeight(props.state.bodyweight, props.state.metric)
    props.setState({
      metric: !props.state.metric,
      maxs,
      bodyweight
    });
  } else if (name.includes('type')) {
    const lift = name[0]
    var comp_styles = props.state.comp_styles
    const oldCompStyle = comp_styles[lift.toLowerCase()]
    comp_styles[lift.toLowerCase()] = value

    // we must manage maxs so the weight and xrm values don't switch when
    // we switch comp style
    maxs = props.state.maxs
    var oldMax = maxs[oldCompStyle]
    maxs[value].max = oldMax.max
    maxs[value].xrm = oldMax.xrm

    // we should also maintain the original values of the oldCompStyle lift
    maxs[oldCompStyle].max = props.state.state_copy.maxs[oldCompStyle].max
    maxs[oldCompStyle].xrm = props.state.state_copy.maxs[oldCompStyle].xrm

    let theLiftNames = maxLifts(props.props.translate).weightlifting.map(x => x.liftName)

    // if this is jerk, then we need to switch "unknown" values
    if (lift.includes('jerk')) {
      maxs[oldCompStyle].unknown = true
      maxs[value].unknown = false
      maxs.jerk = maxs[value]

      // also we need to run the uknown calculation algorithm
      for (var maxName in maxs) {
        var maxRecord = maxs[maxName]
        if ("unknown" in maxRecord && maxName !== 'jerk' && theLiftNames.includes(maxName)) {
          if (maxRecord.unknown === true) {
            const liftRecord = maxLifts(props.props.translate).weightlifting.filter(x => x.liftName === maxName)[0]
            maxs[maxName].max = parseInt(maxs[liftRecord.ratioLift].max * liftRecord.ratio)
          }
        }
      }
    }

    console.log('type change maxs:', maxs)
    console.log('type change comp_styles:', comp_styles)

    props.setState({ comp_styles, maxs })
  } else if (name.includes("maxs")) {
    maxs = props.state.maxs
    console.log('maxs', maxs, 'name', name)

    if (maxs.power_jerk) {
      maxs.jerk = maxs[props.state.comp_styles.jerk]
    }

    if (name.includes("unknown")) {
      // this is an unknown checkbox change from wl maxs form, set it to
      // opposite of current value
      maxs[name[1]][name[2]] = !maxs[name[1]][name[2]]

      // toggling on means we need to fill in the weight
      if (maxs[name[1]][name[2]] === true) {
        const lifts = maxLifts(props.props.translate).weightlifting
        const lift = lifts.filter(x => x.liftName === name[1])[0]
        const ratioLift = maxs[lift['ratioLift']].max
        maxs[name[1]].max = parseInt(ratioLift * lift['ratio'])
      } else {
        maxs[name[1]].max = props.state.state_copy.maxs[name[1]].max
      }
    } else {
      // this is max or xrm
      var move_name = name[1]
      if (Object.keys(props.state.comp_styles).includes(name[1])) {
        // this is a powerlifting max, so we need to update move_name according
        // to the comp_styles dict
        move_name = props.state.comp_styles[name[1]]
      }

      var strValue = String(value).replace(/\D/g, '')
      strValue = strValue.length === 0 ? "0" : strValue
      value = parseInt(strValue)

      // make adjustments using move_name
      if (value > 999) { value = 999 } else if (value < 0) { value = 0 }
      maxs[move_name][name[2]] = value;
    }

    let theLiftNames = maxLifts(props.props.translate).weightlifting.map(x => x.liftName)

    for (var maxName in maxs) {
      var maxRecord = maxs[maxName]
      if ("unknown" in maxRecord && maxName !== 'jerk' && theLiftNames.includes(maxName)) {
        if (maxRecord.unknown === true) {
          const liftRecord = maxLifts(props.props.translate).weightlifting.filter(x => x.liftName === maxName)[0]
          maxs[maxName].max = parseInt(maxs[liftRecord.ratioLift].max * liftRecord.ratio)
        }
      }
    }
    props.setState({ maxs });
  } else if (name[0].includes("readiness_")) {
    props.setState({ readiness_level: value, fitness_level: name[1] });
  } else if (name.includes("height") || name.includes("bodyweight")) {
    console.log(`processing ${name[0]} with value ${value}`)
    const maxValue = name.includes("height") ? 299 : 499
    strValue = String(value).replace(/\D/g, '')

    if (strValue.length === 0) {
      strValue = "0"
    }

    // console.log('strValue', strValue)
    var intValue = parseInt(strValue)
    // console.log('intValue', intValue)
    if (intValue > maxValue) {
      intValue = maxValue
    }
    props.setState({ [e.target.name]: String(intValue) });
  } else {
    props.setState({ [e.target.name]: value });
  }
};

const handleOnFocusOrBlur = (e, props) => {
  console.log('handleOnClick target.name:', e.target.name, 'value:', e.target.value)
  const name = e.target.name.split("~");
  var value = e.target.value;
  var maxs = null


  if (name.includes("maxs")) {
    maxs = props.state.maxs
    console.log('maxs', maxs, 'name', name)

    if (name[2] === 'max') {
      // this is max weight field
      var move_name = name[1]
      if (Object.keys(props.state.comp_styles).includes(name[1])) {
        // this is a powerlifting max, so we need to update move_name according
        // to the comp_styles dict
        move_name = props.state.comp_styles[name[1]]
      }

      var strValue = String(value).replace(/\D/g, '')
      // console.log('strValue:', strValue === "0")

      if (e._reactName === 'onBlur') {
        strValue = strValue === "" ? "0" : strValue
      } else {
        strValue = strValue === "0" ? "" : strValue
      }
      // console.log('second strValue:', strValue)

      // make adjustments using move_name
      maxs[move_name][name[2]] = strValue;
      // console.log('setting maxs:', maxs)

      props.setState({ maxs });
    }
  }
}

const _UserForm = (props) => {
  const { classes, parent } = props

  return (
    <FormControl className={classes.form}>
      <div className={classes.formGroup}>
        {SelectMetric(props, handleChange)}
        {EnterBirthday(props, handleChange)}
        <div className={classes.formRow}>
          {EnterHeight(props, handleChange)}
          {EnterWeight(props, handleChange)}
        </div>
        {SelectMale(props, handleChange)}
      </div>
    </FormControl>
  )
}

const _OnboardUserInfoForm = (props) => {
  const { classes, parent } = props

  return (
    <FormControl className={classes.form}>
      <div className={classes.formGroup}>
        {EnterBirthday(props, handleChange)}
        <div className={classes.formRow}>
          {EnterHeight(props, handleChange)}
          {EnterWeight(props, handleChange)}
        </div>
        {SelectMale(props, handleChange)}
        <div className={classes.fieldText}>
          Readiness Level
        </div>
        {SelectFitness(props, handleChange)}
      </div>
    </FormControl>
  )
}

const _MaxsForm = (props) => {
  const checkboxRender = (parent, max) => {
    if (max.required === false) {
      let checked = parent.state.maxs[max.liftName].unknown ? true : false
      return (
        <div className={classes.wlCheckboxInput}>
          <FormControlLabel
            control={
              <Checkbox
                checked={checked}
                size="small"
                id={`${max.liftName}~${checked}`}
                name={`maxs~${max.liftName}~unknown`}
                onChange={(e) => { handleChange(e, parent) }}
                inputProps={{ 'aria-label': 'primary checkbox' }}
                color="primary"
              />
            }
            label="Estimate for me"
          />
        </div>
      )
    }
  }

  const styleRender = (parent, max) => {
    if (max.styles) {
      return (
        <div className={classes.styleInput}>
          <TextField
            select
            fullWidth
            SelectProps={{ native: true }}
            style={{ padding: '0 0 0 0px' }}
            InputLabelProps={{ classes: { root: classes.labelRoot } }}
            value={parent.state.comp_styles[max.liftName]}
            name={`${max.liftName}~type`}
            onChange={(e) => { handleChange(e, parent) }}
          >
            {
              max.styles.map(v => (
                <option value={v.value} key={v.value} id={v.value}>
                  {v.label}
                </option>
              ))
            }
          </TextField>
        </div>
      )
    }
  }

  const maxRender = (parent, max, lifts) => {
    let value
    let disabled
    let liftName

    // establish values for the above variables. This matters if the liftname is jerk
    if (max.liftName === 'jerk') {
      parent.state.comp_styles.jerk = parent.state.comp_styles.jerk ? parent.state.comp_styles.jerk : 'split_jerk'
      let max_record = lifts.filter(x => x.liftName === parent.state.comp_styles.jerk)[0]
      liftName = max_record.liftName
      value = parent.state.maxs[liftName].max
      value = value === "" ? value : parseInt(value)
      disabled = parent.state.maxs[liftName].unknown
    } else {
      liftName = max.liftName
      value = parent.state.maxs[liftName].max === "" ? "" : parseInt(parent.state.maxs[liftName].max)
      disabled = parent.state.maxs[liftName].unknown
    }

    if (max.liftName !== parent.state.comp_styles.jerk) {
      return (
        <div key={max.liftName}>
          <FormHelperText className={classes.formHelperText}>
            <Translate id={`max_${max.liftName}`} />
          </FormHelperText>
          <div className={classes.formRow}>
            <div className={(!max.required || max.liftName === 'jerk')
              ? classes.wlWeightInputNotRequired : classes.wlWeightInputRequired}>
              <TextField
                value={value}
                required={true}
                disabled={disabled}
                name={`maxs~${liftName}~max`}
                error={parent.state.errors.includes(max.liftName)}
                type="text"
                inputProps={{ inputMode: 'decimal' }}
                onChange={(e) => { handleChange(e, parent) }}
                InputLabelProps={{ classes: { root: classes.labelRoot } }}
                fullWidth
                onFocus={(e) => { handleOnFocusOrBlur(e, parent) }}
                onBlur={(e) => { handleOnFocusOrBlur(e, parent) }}
              />
            </div>
            {checkboxRender(parent, max)}
            {styleRender(parent, max)}
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  let { classes, parent, selectedLifts, showSelectMetric } = props
  let translate = parent.props.translate
  let lifts = maxLifts(translate).weightlifting

  lifts = selectedLifts ? lifts.filter(l => selectedLifts.includes(l.liftName)) : lifts

  return (
    <FormControl className={classes.form}>
      {showSelectMetric && SelectMetric(props, handleChange)}
      {lifts.map(max => maxRender(parent, max, lifts))}
    </FormControl>
  )
}

const _WorkoutDaysSelect = (props) => {
  const { classes, parent, callback, calcSessionsInfos, calcVolumeInfos } = props

  return (
    <div className={classes.workoutDaysContainer}>
      <div className={classes.formItemTitle}>
        TRAINING DAYS
      </div>
      <ToggleButtonGroup
        color="primary"
        value={parent.state.workout_days}
        exclusive={false}
        className={classes.selectDaysForm}
        onChange={(e) => {
          handleChange(e, parent).then((parent) => callback && callback(parent.state.program_focus, parent.state.workout_days.length, parent, calcSessionsInfos, calcVolumeInfos))
        }}
        name="workout_days"
        size="small"
      >
        {dayOptions.map((option) => (
          <ToggleButton
            value={option.value}
            name="workout_days"
            className={classes.selectDayText}
            key={option.value}
            id={option.value}
          >
            {option.display}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <div className={classes.selectDaysExplainText}>Minimum: 3 — Maximum: 6</div>
    </div>
  )
}

const _EmailField = (
  props
) => {
  const { classes, parent } = props

  return (
    <FormControl className={classes.form}>
      <div className={classes.formGroup}>
        <div className={classes.formRow}>
          <TextField
            label={<Translate id="email" />}
            value={parent.state.email}
            name="email"
            id="name"
            onChange={(e) => { handleChange(e, parent) }}
            fullWidth
          />
        </div>
      </div>
    </FormControl>
  )
}

const _TeamField = (
  props
) => {
  const { classes, parent } = props

  let statusBackgroundColor = parent.state.team_status === 'pending' ? '#ebbb00' : 'green'

  if ('team' in parent.state && ![null, ''].includes(parent.state.team)) {
    // they have a team status, show that
    return (
      <FormControl className={classes.form}>
        <div className={classes.formGroup}>
          <div className={classes.formRow}>
            <TextField
              label="Team"
              value={parent.state.team}
              name="team"
              id="team"
              fullWidth
              disabled={true}
            />
            <div style={{ display: 'flex' }}>
              <div className={classes.teamStatusContainer} style={{ backgroundColor: statusBackgroundColor }} id="teamStatus">
                {parent.state.team_status}
              </div>
              <div className={classes.teamDeleteContainer} onClick={() => parent.handleDeleteTeam()} id="deleteTeam">
                <CloseIcon fontSize='small' style={{ display: 'flex' }} />
              </div>
            </div>
          </div>
        </div>
      </FormControl>
    )
  } else {
    return (
      <FormControl className={classes.form}>
        <div className={classes.formGroup}>
          <div className={classes.formRow}>
            <TextField
              label="Team code"
              value={parent.state.teamCode}
              name="teamCode"
              id="teamCode"
              onChange={(e) => { handleChange(e, parent) }}
              fullWidth
            />
          </div>
        </div>
      </FormControl>
    )
  }
}

const MaxsForm = withStyles(styles)(_MaxsForm)
const UserForm = withStyles(styles)(_UserForm)
const WorkoutDaysSelect = withStyles(styles)(_WorkoutDaysSelect)
const EmailField = withStyles(styles)(_EmailField)
const TeamField = withStyles(styles)(_TeamField)
const OnboardUserInfoForm = withStyles(styles)(_OnboardUserInfoForm)

export { MaxsForm, UserForm, WorkoutDaysSelect, EmailField, OnboardUserInfoForm, TeamField }
export default UserForm