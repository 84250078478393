import { connect } from 'react-redux';

import _Profile from './layout';
import { updateUserData, fetchUserData } from '../../state/thunk';
import { toggleLoading } from '../../state/actions';

const mapStateToProps = ({userReducer}) => ({
  userData: userReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: (userName, sudo) => dispatch(fetchUserData(userName, sudo)),
  updateUserData: (pl) => dispatch(updateUserData(pl)),
  toggleLoading: () => dispatch(toggleLoading()),
});

export const Profile = connect(mapStateToProps, mapDispatchToProps)(_Profile);
