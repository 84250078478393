import React from 'react';
import { styles } from "../styles";
import { withStyles, TextField, Button, Modal } from "@material-ui/core";
import { runProfiler } from '../api';
// import AddIcon from '@mui/icons-material/Add';
import AddIcon from '@material-ui/icons/Add';
import { VictoryBar, VictoryChart, VictoryLabel, VictoryBoxPlot, VictoryStack, VictoryAxis } from "victory";
import { toInteger } from 'lodash';

let lifts = {
  weightlifting: [
    { name: 'snatch', display: 'Snatch', color: "navy" },
    { name: 'clean_jerk', display: 'Clean + Jerk', color: "red" },
    { name: 'squat', display: 'Squat', color: "green" },
    { name: 'pull', display: 'Pull', color: "goldenrod" },
    { name: 'press', display: 'Press', color: "purple" }
  ],
  powerlifting: [
    { name: 'squat', display: 'Squat', color: "#005e00" },
    { name: 'bench', display: 'Bench', color: "#009a00" },
    { name: 'deadlift', display: 'Deadlift', color: "#4e4e4e" }
  ]
}

export function sleep(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

class Profiler extends React.Component {
  state = { selectedMicro: {}, selected: {}, selectColor: {}, modalOpen: false, modalProgram: { micros: [] } }

  // async componentDidMount() {
  //   await sleep(500);
  //   console.log('this.props.parent.state.profileResults:', this.props.parent.state)
  //   let modalProgram = this.props.parent.state.profileResults[0].program
  //   this.setState({ modalProgram })
  // }

  RenderLifts = (program, name) => {
    let selected = this.state.selected
    console.log('selected[name]:', selected[name])

    return (
      <div style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        marginTop: 20,
        zIndex: 10,
        borderRadius: 5,
        paddingVertical: 10,
        shadowOffset: { height: 4, width: 3 },
        shadowRadius: 6,
        shadowOpacity: 0.5,
        shadowColor: "gray",
        elevation: 5
      }}
      >
        {lifts[program.program].map(l => {
          let isSelected = selected[name] === l.name

          return (
            <div
              key={l.name}
              onClick={() => {
                let selected = this.state.selected
                let selectColor = this.state.selectColor

                selected[name] = isSelected ? null : l.name
                selectColor[name] = isSelected ? "" : l.color

                this.setState({ selected, selectColor })
              }}
              style={{
                display: 'flex',
                flex: 1,
                backgroundColor: selected[name] && !isSelected ? "#e3e3e3" : l.color,
                padding: 3,
                margin: 5,
                alignItems: 'center',
                justifyContent: 'center',
                // minWidth: 90,
                maxWidth: 100,
                minHeight: 30,
                borderRadius: 4,
                borderColor: 'darkgray',
                borderWidth: 0
              }}
            >
              <div
                style={{
                  color: "white",
                  fontSize: 12,
                  fontWeight: '600'
                }}>
                {l.display}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  VolChart = (program, parent, name) => {
    let selectedMicro = this.state.selectedMicro[name]
    let reps = selectedMicro === undefined ? program.reps : program.reps.dailies[selectedMicro]
    let PMGs = lifts[program.program].map(l => l.name)
    let tickCount = selectedMicro === undefined ? program.stats.micros.length : program.frequency

    if (name === 'Jenny Kwon') {
      console.log('selectedMicro:', selectedMicro)
      console.log('reps object:', reps)
    }

    return (
      <div style={{ paddingBottom: 10 }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            alignContent: 'center',
            justifyContent: 'center',
            backgroundColor: 'lightgray',
            textAlign: 'center',
            marginTop: 10,
            padding: 5,
            borderRadius: 5
          }}
          onClick={() => {
            let selectedMicros = this.state.selectedMicro
            if (selectedMicros[name] !== undefined) { delete selectedMicros[name] }
            this.setState({ selectedMicro: selectedMicros })
          }}
        >
          {selectedMicro === undefined ? "Select week below" : `Unselect Week ${selectedMicro + 1}`}
        </div>
        <VictoryChart
          width={300}
          height={220}
          padding={25}
        >
          <VictoryAxis
            dependentAxis
            style={{
              axis: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
              tickLabels: { fill: "black", fontSize: 9 }
            }}
          />
          <VictoryAxis
            style={{
              axis: { stroke: "transparent" },
              ticks: { stroke: "transparent" },
              tickLabels: { fill: "black" }
            }}
            tickCount={tickCount}
            events={[{
              target: "tickLabels",
              eventHandlers: {
                onClick: (e, g) => {
                  let selectedMicros = this.state.selectedMicro
                  console.log('setting g.index:', g.index)
                  if (selectedMicros[name] !== undefined) {
                    // remove this from selectedMicro
                    delete selectedMicros[name]
                  } else {
                    selectedMicros[name] = g.index
                  }
                  this.setState({ selectedMicro: selectedMicros })
                }
              }
            }]}
            tickLabelComponent={
              <VictoryLabel
                text={({ datum }) => String(parseInt(datum))}
              />
            }
          />
          <VictoryStack
            colorScale={lifts[program.program].map(l => this.state.selected[name] ? this.state.selectColor[name] : l.color)}
            width={300}
            height={200}
          >
            {this.state.selected[name] ?
              PMGs.map(r => {
                if (r === this.state.selected[name]) {
                  return (
                    <VictoryBar
                      barRatio={0.8}
                      x="x"
                      key={r}
                      data={reps[r]}
                    />
                  )
                }
              })
              :
              PMGs.map(r => {
                return (
                  <VictoryBar
                    barRatio={0.8}
                    x="x"
                    key={r}
                    data={reps[r]}
                  />
                )
              })}
          </VictoryStack>
        </VictoryChart>
      </div>
    )
  }

  BoxPlot = (program, parent, name) => {
    let selectedMicro = this.state.selectedMicro[name]
    let selected = parent.state.selected
    let boxColor = selected ? parent.state.selectColor : "gray"

    let tickCount = selectedMicro === undefined ? program.stats.micros.length : program.frequency
    let data = []
    if (selectedMicro !== undefined) {
      console.log("SELECTED MICRO RUNNING IN BOXPLOT FOR MICRO", selectedMicro)
      data = program.stats.micros[selectedMicro].days.map((d) => d.full_intensities)
      data = data.filter(x => x !== undefined)
    } else {
      data = program.stats.micros.map((m) => m.full_intensities)
    }

    console.log('boxplot data:', data)


    return (
      <VictoryChart
        width={300}
        height={200}
        padding={25}
      >
        <VictoryAxis
          dependentAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "#c9c8c8", fontSize: 9, marginLeft: 30 }
          }}
        />
        <VictoryAxis
          style={{
            axis: { stroke: "transparent" },
            ticks: { stroke: "transparent" },
            tickLabels: { fill: "black" }
          }}
          tickCount={tickCount}
          tickLabelComponent={
            <VictoryLabel
              text={({ datum }) => String(parseInt(datum) + (selectedMicro === undefined ? 0 : 1))}
            />
          }
        />
        <VictoryBoxPlot
          animate={{ duration: 500, onLoad: { duration: 100 } }}
          width={300}
          height={200}
          data={data}
          boxWidth={10}
          style={{
            q1: { fill: boxColor },
            q3: { fill: boxColor },
          }}
        />
      </VictoryChart>
    )
  }

  Tags = (program) => {
    const { classes } = this.props;

    // console.log('program.stats.tags:', program.stats.tags)
    return (
      <div>
        <h3>Tags</h3>
        <div className={classes.profileSectionContainer}>
          {program.stats.tags.map((tag) => {
            return (
              <div>
                {tag.tag}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  Program = (program) => {
    // console.log('program.stats.tags:', program.stats.tags)
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          alignContent: 'center',
          justifyContent: 'center',
          backgroundColor: 'lightgray',
          textAlign: 'center',
          marginTop: 10,
          padding: 5,
          borderRadius: 5
        }}
        onClick={() => {
          this.setState({ modalOpen: true, modalProgram: program })
        }}
      >
        View Program
      </div>
    )
  }

  FullProgram = () => {
    const { classes } = this.props;

    return (
      <Modal
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false })}
      >
        <div className={classes.modal}>
          {this.state.modalProgram.micros.map((micro) => {
            return (
              <div>
                <div style={{ marginTop: 10 }}>Week {micro.micro_count + 1} </div>
                <div className={classes.programBottomInfo}>
                  <div className={classes.programBottomInfoBox}>
                      Micro Note: {micro.micro_note["en"]}
                  </div>
                </div>
                {micro.days.map((day) => {
                  if (day.movements.length > 0) {
                    return (
                      <div style={{ marginLeft: 20 }}>
                        <div>{day.date_utc}</div>
                        {day.movements.map((move, index) => {
                          if (move.PMG !== "accessory") {
                            let reps = move.set_size;
                            let sets = move.sets.length;
                            let pct = toInteger(move.baseline_intensity * 100)

                            return (
                              <div style={{ marginLeft: 20 }}>
                                <div key={index} className={classes.programMovement}>
                                  <div className={classes.programTopInfo}>
                                    <div className={classes.programMovementName}>
                                      {move.movement_name.en}
                                    </div>
                                    <div className={classes.programMovementInfoContainer}>
                                      <div className={classes.programMovementInfo}>
                                        <div className={classes.programMovementInfoTop}>
                                          {reps} reps
                                        </div>
                                      </div>
                                      <div className={classes.programMovementInfo}>
                                        <div className={classes.programMovementInfoTop}>
                                          {sets} sets
                                        </div>
                                      </div>
                                      <div className={classes.programMovementInfo}>
                                        <div className={classes.programMovementInfoTop}>
                                          {pct}%
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={classes.programBottomInfo}>
                                    <div className={classes.programBottomInfoBox}>
                                      tags: {move.tags.join(", ")}
                                    </div>
                                    <div className={classes.programBottomInfoBox}>
                                      intensity: {Math.round(move.baseline_intensity * 100)}%
                                    </div>
                                    <div className={classes.programBottomInfoBox}>
                                      overload: {`${move.overload}`}
                                    </div>
                                    <div className={classes.programBottomInfoBox}>
                                      move_id: {`${move.movement_id}`}
                                    </div>
                                    <div className={classes.programBottomInfoBox}>
                                      notes: {`${move.movement_note["en"]}`}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        }
                        )}
                      </div>
                    )
                  }
                })}
              </div>
            )
          }
          )}
        </div>
      </Modal>
    )
  }

  ProgramSummary = (profile) => {
    const program = profile.program
    const name = profile.user.name
    const { classes, parent } = this.props;

    return (
      <div className={classes.programSummaryContainer}>
        {this.RenderLifts(program, name)}
        {this.VolChart(program, parent, name)}
        {this.BoxPlot(program, parent, name)}
        {this.Tags(program)}
        {this.Program(program)}
      </div>
    )
  }

  KeyValue = (programIdx, title, key, value) => {
    const { classes, parent } = this.props;

    if (title === 'maxs') {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{String(key)}</div>
          <TextField
            value={parent.state.profiles[programIdx][title][key].max}
            onChange={(e) => {
              parent.state.profiles[programIdx][title][key].max = e.target.value;
              parent.setState({ profiles: parent.state.profiles });
            }}
            style={{ padding: 0, margin: 0, width: 50 }}
            size="small"
            fullWidth={false}
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: { textAlign: "right", },
              }
            }}
          />
        </div>
      )
    } else {
      return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>{String(key)}</div>
          <TextField
            value={parent.state.profiles[programIdx][title][key]}
            onChange={(e) => {
              parent.state.profiles[programIdx][title][key] = e.target.value;
              parent.setState({ profiles: parent.state.profiles });
            }}
            style={{ padding: 0, margin: 0 }}
            size="small"
            fullWidth={false}
            InputProps={{
              disableUnderline: true,
              inputProps: {
                style: { textAlign: "right" },
              }
            }}
          />
        </div>
      )
    }
  }

  ProfileSection = (programIdx, title, profile) => {
    const { classes } = this.props;

    // console.log('doing title:', title, 'profile:', profile);
    let sectionHead = title === 'user' ? profile[title].name : title;

    return (
      <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
        <h3>{sectionHead}</h3>
        <div className={classes.profileSectionContainer}>
          {Object.keys(profile[title]).map((key, index) => {
            if (title === 'maxs') {
              return (
                <div key={index}>
                  {this.KeyValue(programIdx, title, key, profile[title][key]['max'])}
                </div>
              )
            } else {
              if (key !== 'name') {
                return (
                  <div key={index}>
                    {this.KeyValue(programIdx, title, key, profile[title][key])}
                  </div>
                )
              }
            }
          })}
        </div>
      </div>
    )
  }

  render() {
    const { classes, parent } = this.props;

    // console.log('parent.state.profileResults:', parent.state.profileResults)

    return (
      <div className={classes.tabContainer}>
        <h1>Profiler</h1>
        <div className={classes.profilesContainer}>
          {parent.state.profiles.map((profile, programIdx) => {
            return (
              <div className={classes.profileCard} key={programIdx}>
                {Object.keys(profile).map((title, index) => {
                  return this.ProfileSection(programIdx, title, profile)
                })}
              </div>
            )
          })}
          <div
            className={classes.profileAddCard}
            key={"add"}
            onClick={() => {
              let profiles = parent.state.profiles
              let data = require('../data/empty-profile.json');
              profiles.push(data);
              parent.setState({ profiles })
            }}
          >
            <AddIcon fontSize="large" color="lightblue" />
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            className={classes.button}
            onClick={() => runProfiler(parent.state.profiles, "weightlifting", parent)}
          >
            Profile Weightlifting
          </Button>
        </div>
        <div className={classes.profilesContainer}>
          {parent.state.profileResults.map((profile, programIdx) => {
            console.log('profile:', profile);
            return (
              <div key={programIdx} className={classes.profileCard}>
                <div className={classes.profileResultTitle}>
                  {profile.user.name}
                </div>
                <div className={classes.programCardContainer} key={programIdx}>
                  {parent.state.showProfiles && Object.keys(profile).map((title, index) => {
                    return this.ProfileSection(programIdx, title, profile)
                  })}
                </div>
                {this.ProgramSummary(profile)}
              </div>
            )
          })}
        </div>
        {this.FullProgram()}
      </div>
    );
  }
}

export default withStyles(styles)(Profiler);