import React, { Component } from 'react'
import { TextField, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux';
import { Translate, getTranslate } from "react-localize-redux";
import { postBuildRequest } from '../../programs/api'
import { Auth } from "aws-amplify";
import mixpanel from 'mixpanel-browser';

export class OnboardingFormSubmit extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      isAuth: false,
      loading: false
    }
  }

  async componentDidMount() {
    const sessionRes = await Auth.currentSession()
    console.log('sessionRes', sessionRes)
    const cognito_id = sessionRes.accessToken.payload.sub

    const isAuth = sessionRes.accessToken ? true : false
    this.setState({ isAuth, cognito_id })

    mixpanel.track(`render registration form - not yet a customer`);
  }

  buildProgram = async () => {
    console.log('heck yeah, building program!')
    this.setState({ loading: true })
    const {
      handleError,
      handleValidationError,
      parent
    } = this.props
    const {
      birth_year,
      birth_month,
      birth_day,
      bodyweight,
      height,
      male,
      metric,
      maxs,
      promoCode,
      fitness_level,
      comp_styles,
      program,
      length_days,
      start_date,
      workout_days,
      program_focus,
      sessions_info,
      volume_info
    } = parent.state

    const submitProps = {
      program,
      length_days,
      cognito_id: this.state.cognito_id,
      birthday: `${birth_year}-${birth_month}-${birth_day}`,
      birth_year,
      birth_month,
      birth_day,
      height,
      male,
      bodyweight,
      workout_days,
      frequency: workout_days.length,
      promo_code: promoCode,
      start_date,
      fitness_level,
      maxs,
      metric,
      comp_styles,
      autologin: true,
      handleError: handleError,
      handleValidationError: handleValidationError,
      program_focus,
      sessions_info,
      volume_info,
    }
    // console.log('submitting with submitProps:', submitProps)
    postBuildRequest(submitProps, true, true).then(() => this.setState({ loading: false }))
  }

  RenderBuild() {
    const {
      classes,
      parent
    } = this.props
    const { start_date } = parent.state

    return (
      <div className={classes.formFieldsContainer}>
        <div
          id={"pay_submit_button"}
          name={"pay_submit_button"}
          className={classes.payButton}
          onClick={this.buildProgram}
        >
          <div className={classes.btnTextContainer}>
            {
              this.state.loading ? (
                <CircularProgress
                  size={50}
                  classes={{ circle: classes.circularProgressPrimaryColor }}
                  color="primary"
                />
              ) : (
                <div>
                  <div className={classes.btnText}>
                    <Translate id="buildProgram" />
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }

  RenderRegistration() {
    const {
      classes,
      handleChange,
      parent
    } = this.props
    const {
      firstName,
      lastName,
      email,
      password,
    } = parent.state

    return (
      <div className={classes.formFieldsContainer}>
        <TextField
          value={firstName}
          required={true}
          label={"First name"}
          name={"firstName"}
          onChange={handleChange}
          fullWidth
          style={{ marginBottom: 13 }}
        />
        <TextField
          value={lastName}
          required={true}
          label={"Last name"}
          name={"lastName"}
          onChange={handleChange}
          fullWidth
          style={{ marginBottom: 13 }}
        />
        <TextField
          value={email}
          required={true}
          label={<Translate id="email" />}
          name={"email"}
          onChange={handleChange}
          fullWidth
          style={{ marginBottom: 13 }}
        />
        <TextField
          inputProps={{
            autoComplete: "new-password",
            form: { autocomplete: "off" },
          }}
          value={password}
          required={true}
          label={<Translate id="password" />}
          name={"password"}
          type="password"
          onChange={handleChange}
          fullWidth
          style={{ marginBottom: 13 }}
        />
      </div>
    )
  }

  render() {
    const { classes } = this.props
    const isAuth = this.state.isAuth

    return (
      <div className={classes.formStepContainer}>
        <div className={classes.formStepContent}>
          {isAuth ? this.RenderBuild() : this.RenderRegistration()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ localize }) => ({
  translate: getTranslate(localize)
});

export default connect(mapStateToProps)(OnboardingFormSubmit);
