import React from "react";
import { Chart } from "react-charts";
import { Doughnut } from 'react-chartjs-2';

// TODO: rewrite this with react-chartjs-2

class VolIntChart extends React.Component {
    state = {
        series: { type: "line" },
        axes: [
            { primary: true, position: "bottom", type: "linear", show: false },
            { position: "left", min: 0, type: "linear", show: false, id: 'Volume' },
            { position: "right", min: 0, type: "linear", show: false, id: 'Intensity', format: d => `${d}%` },
        ],
        data: [
            {
                label: "Volume",
                datums: [],
                secondaryAxisID: 'Volume'
            },
            {
                label: "Intensity",
                datums: [],
                secondaryAxisID: 'Intensity'
            }
        ],
        options: { padding: 0 }
    };

    async componentDidMount() {
        let parent = this.props.parent
        let micros = parent.state.programData.micros

        let data = [
            {
                label: "Intensity",
                secondaryAxisID: 'Intensity',
                datums: micros.map((micro) => {
                    return { x: micro['micro_count'] + 1, y: micro['avg_intensity'] }
                })
            },
            {
                label: "Volume",
                secondaryAxisID: 'Volume',
                datums: micros.map((micro) => {
                    return { x: micro['micro_count'] + 1, y: micro['volume'] }
                })
            }
        ]

        this.setState({ data })
    }

    render() {
        return (
            <div>
                <div style={{ width: "100%", height: "200px", margin: "5px" }}>
                    <Chart
                        data={this.state.data}
                        series={this.state.series}
                        axes={this.state.axes}
                        options={this.state.options}
                        tooltip
                    />
                </div>
            </div>
        )
    }
}

export default VolIntChart;