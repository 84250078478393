import React from 'react';

import { TextField } from "@material-ui/core";

import { Translate } from "react-localize-redux";

import { get_days } from "../utils";

const EnterBirthday = (props, handleChange) => {
  const { classes, parent } = props

  return (
    <div className={classes.formRow}>
      <TextField
        select
        fullWidth
        value={parent.state.birth_year}
        label={<Translate id="birthYear" />}
        name="birth_year"
        placeholder="birth year"
        onChange={(e) => { handleChange(e, parent) }}
        SelectProps={{ native: true }}
        style={{ margin: "0 0 0 0px" }}
      >
        {Array.from({ length: 100 }, (x, i) => i + 1920)
          .reverse()
          .map((option) => (
            <option key={option} id={option} value={option}>
              {option}
            </option>
          ))}
      </TextField>
      <TextField
        select
        fullWidth
        value={parent.state.birth_month}
        label={<Translate id="birthMonth" />}
        name="birth_month"
        onChange={(e) => { handleChange(e, parent) }}
        SelectProps={{ native: true }}
        style={{ margin: "0 0 0 10px" }}
      >
        {Array.from({ length: 12 }, (x, i) => i + 1).map((option) => (
          <option key={option} id={option} value={option}>
            {option}
          </option>
        ))}
      </TextField>
      <TextField
        select
        fullWidth
        value={parent.state.birth_day}
        label={<Translate id="birthDay" />}
        name="birth_day"
        onChange={(e) => { handleChange(e, parent) }}
        SelectProps={{ native: true }}
        style={{ margin: "0 0 0 10px" }}
      >
        {get_days(parent.state.birth_month, parent.state.birth_year).map(
          (option) => (
            <option key={option} id={option} value={option}>
              {option}
            </option>
          )
        )}
      </TextField>
    </div>
  )
}

export default EnterBirthday;