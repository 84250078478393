import React, { useState } from 'react';
import { Modal, Paper, Button, List, ListItem, ListItemText } from '@material-ui/core';


export default ({ variable }) => {
  const [showModal, setShowModal] = useState(false);
  const varType = typeof variable.val == 'object'
    ? Array.isArray(variable.val) ? 'array' : 'object'
    : typeof variable.val;

  return (
    <div style={{ margin: 5 }} key={variable.name}>
      <div>
        <div
          style={{
            border: '1px solid gray',
            display: 'flex',
            borderRadius: 5,
            padding: 4,
            flexDirection: 'column'
          }}
        >
          <div
            style={{
              display: 'flex',
              borderRadius: 5,
              padding: 2,
              marginLeft: 3,
              justifyContent: 'center',
              fontSize: 12
            }}
          >
            {variable.name}
          </div>
          {
            varType !== 'object' && varType !== 'array' ?
              <div
                style={{
                  backgroundColor: 'black',
                  color: 'white',
                  display: 'flex',
                  borderRadius: 5,
                  padding: 4,
                  marginLeft: 3,
                  justifyContent: 'center'
                }}
              >
                {String(variable.val)}
              </div>
              :
              <div
                onClick={() => setShowModal(true)}
              >
                {'{. . .}'}
              </div>
          }
        </div>
      </div>

      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <div component={Paper} p={4} mt={30} m="0 auto" maxWidth="26%">
          <h4>{variable.name}: </h4>
          <div component={List} overflow='scroll' maxHeight="15rem" p={1}>
            {
              varType === 'array' &&
              variable.val.map(value =>
                <div
                  component={ListItem}
                  button
                  borderBottom={'1px solid gray'}
                  p={1}
                  key={value}
                >
                  <ListItemText>{value}</ListItemText>
                </div>
              )
            }
            {
              varType === 'object' &&
              Object.entries(variable.val).map(([key, val]) =>
                <div
                  component={ListItem}
                  button
                  borderBottom={'1px solid gray'}
                  p={1}
                  key={key}
                >
                  <ListItemText>{key} - {val}</ListItemText>
                </div>
              )
            }
          </div>
        </div>
      </Modal>
    </div>
  );
}