import React from 'react';
import { getTeams, createTeam, deleteTeam } from './api'
import { Auth } from "aws-amplify";
import {
  Backdrop, CircularProgress, Button, Dialog, DialogTitle, DialogContent, DialogContentText,
  TextField, DialogActions
} from "@material-ui/core";
import { TeamTable } from './screens/teamTable'

class _ManageTeams extends React.Component {
  state = {
    rows: [],
    loginSortDirection: 'asc',
    loading: true,
    coach: false,
    admin: false,
    customers: null,
    promos: null,
    error: null,
    leadingTeams: [],
    coachingTeams: [],
    newTeamName: ''
  }

  async componentDidMount() {
    this.setState({ loading: true })

    await Auth.currentSession().then(async (value) => {
      // do some initial tests for basic group membership
      const payload = value.accessToken.payload
      const groups = payload['cognito:groups'] ? payload['cognito:groups'] : []
      const cognito_id = payload.sub

      if (!groups.includes('ADMINS')) {
        this.props.history.push("/workouts")
      }

      await this.props.fetchUserData(cognito_id).then(async (data) => {
        await getTeams().then((data) => {
          let teams = data.teams
          // teams = teams.filter(x => !['TEST TEAM_pounds', 'TEST TEAM_kilograms', 'TEST TEAM'].includes(x.team_name))

          this.setState({
            teams,
            loading: false
          })
        })

        // console.log('state after componentDidMount:', this.state)
      })
    })
  }

  handleDeleteTeam = (team_name) => {
    console.log("called function handleDeleteTeam")
    this.setState({ deleteDialog: true, deleteTeamField: '', teamDeleting: team_name })
  }

  handleDeleteTeamConfirm = async () => {
    console.log("called function handleDeleteUserConfirm")

    if (this.state.teamDeleting === this.state.deleteTeamField) {
      // the correct email was typed in, we will let them delete
      this.setState({ deleteLoading: true })
      await deleteTeam(this.state.teamDeleting)
    }
  }

  RenderDialog = () => {
    return (
      <Dialog open={this.state.deleteDialog} onClose={() => this.setState({ deleteDialog: false })}>
        <DialogTitle>Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            If you are ABSOLUTELY sure you want to delete this team, type {this.state.teamDeleting} below and click DELETE.
            Please note that this is PERMANENT.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="DELETE"
            fullWidth
            variant="standard"
            value={this.state.deleteTeamField}
            onChange={(event) => this.setState({ deleteTeamField: event.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.setState({ deleteDialog: false })}>Cancel</Button>
          <Button
            onClick={() => this.handleDeleteTeamConfirm()}
            disabled={this.state.deleteLoading}
            id="deleteButton"
          >
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderCreateTeam = () => {
    let newTeamName = this.state.newTeamName

    return (
      <div style={{ display: 'flex', margin: 20, justifyContent: 'center', alignItems: 'center' }}>
        <TextField
          value={newTeamName}
          onChange={(e) => this.setState({ newTeamName: e.target.value })}
          placeholder='Team name'
          id="team_name"
        />
        <Button
          style={{ backgroundColor: 'green', marginLeft: 10, color: 'white' }}
          onClick={() => createTeam(newTeamName)}
          id="create_team_button"
        >
          Create Team
        </Button>
      </div>
    )
  }

  render() {
    const data = this.state.teams
    const columns = [
      { Header: 'Team name', accessor: 'team_name' },
      { Header: 'Coach code', accessor: 'coach_code' },
      { Header: 'Athlete code', accessor: 'athlete_code' },
      { Header: 'Actions', accessor: 'coaches_cognitos' },
    ]

    // return statements //////////////////////////////////////////////////////////////////////////////////

    if (this.state.loading) {
      return (
        <Backdrop open={true} invisible={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    } else {
      return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 80, height: '100%', width: '100%', alignItems: 'center' }}>
          {this.RenderDialog()}
          <div style={{ fontSize: 60, fontStyle: 'italic', fontWeight: '600' }}>Team Management</div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 30, width: '100%' }}>
            <div style={{ fontSize: 30, fontStyle: 'italic', fontWeight: '600', marginBottom: 10 }}>Teams:</div>
            <TeamTable parent={this} columns={columns} data={data} handleDeleteTeam={this.handleDeleteTeam} />
            <this.renderCreateTeam />
          </div>
        </div>
      )
    }
  }
}

export default _ManageTeams;