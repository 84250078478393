import React from 'react';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import { Auth } from 'aws-amplify'


class Hero extends React.Component {
  async componentDidMount() {
    console.log('wow in Hero componentdidmount')

    Auth.currentAuthenticatedUser()
      .then(() => {
        console.log('we have a sessionres')
        this.props.history.push('/workouts')
      })
      .catch(() => {
        console.log('we do NOT have a sessionres')
        this.props.history.push('/signin')
      });
  }

  render() {
    return <div />
  }
}

export default withStyles(styles)(Hero);
