export const styles = {
  coachInfoBox: {
    margin: '5px',
    textAlign: 'left',
    color: 'white'
  },
  movementAccordionDetail: {
    padding: 0
  },
  scrollComponent: {
    backgroundColor: '#ffffff'
  },
  cardActive: {
    zIndex: 100,
    margin: "10px 10px 15px 10px !important",
    borderRadius: "5px !important",
    boxShadow: "2px 3px 12px 2px #dadada",
    "&:before": {
      height: "0px",
    }
  },
  cardInactiveUnwritten: {
    margin: "10px 10px 15px 10px",
    borderRadius: "5px !important",
    boxShadow: "4px 3px 8px 2px #dadada",
    "&:before": {
      height: "0px",
    },
    background: "linear-gradient(90deg, #a7a7a7, #a7a7a7 7px, #fff 7px, #fff 20px)"
  },
  cardInactivePartiallyWritten: {
    margin: "10px 10px 15px 10px",
    borderRadius: "5px !important",
    boxShadow: "4px 3px 8px 2px #dadada",
    "&:before": {
      height: "0px",
    },
    background: "linear-gradient(90deg, #ffeb00, #ffeb00 7px, #fff 7px, #fff 20px)"
  },
  cardInactiveWritten: {
    margin: "10px 10px 15px 10px",
    borderRadius: "5px !important",
    boxShadow: "4px 3px 8px 2px #dadada",
    "&:before": {
      height: "0px",
    },
    background: "linear-gradient(90deg, #40943e, #40943e 7px, #fff 7px, #fff 20px)"
  },
  loading: {
    margin: "0px 0px 0px 3px",
  },
  waitingContainer: {
    margin: "auto",
    marginTop: "200px",
  },
  backdrop: {
    zIndex: 3,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  movementNoteContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "15px",
    backgroundColor: "#f1f1f17d"
  },
  movementNote: {
    width: "100%"
  },
  movementIcons: {
    textAlign: "right",
    margin: "10px 0px 10px 10px",
    float: "right",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
  individualIconContainer: {
    margin: "0px 5px 0px 5px",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
    padding: "0px 4px 10px 4px",
    borderRadius: "4px",
    boxShadow: "3px 3px 5px 1px #cacaca"
  },
  individualIconText: {
    margin: "-7px",
    fontSize: "9px",
    textAlign: "center"
  },
  movementExpansionPanelDetail: {
    display: "flex",
    width: "100%",
    padding: 0,
  },
  days: {
    margin: 0,
  },
  adornmentDisabled: {
    color: "rgba(0, 0, 0, 0.34)"
  },
  root: {
    width: "100%",
    backgroundColor: "white",
    position: "relative",
    overflow: "auto",
    maxHeight: "calc(100% - 64px)",
    margin: "auto"
  },
  bottomSpacer: {
    paddingBottom: "300px"
  },
  scrollPad: {
    paddingTop: "64px",
    backgroundColor: "#000000"
  },
  buttonContainer: {
    margin: "auto",
  },
  li: {
    background: "linear-gradient(60deg, #a4bdd4, #769BBD)",
    color: "#000000",
    fontSize: 24,
    margin: "auto",
    listStyleType: "circle",
  },
  addResultRow: {
    height: "15px",
    backgroundColor: "#f1f1f17d",
    borderRadius: "10px"
  },
  movementList: {
    width: "100%",
  },
  movementListItem: {
    paddingLeft: "5px",
    paddingRight: "10px",
  },
  fullList: {
    paddingTop: 0,
  },
  dialogContainer: {
    backgroundColor: "rgba(0,0,0,0)",
    paddingTop: "264px",
  },
  dialogText: {
    "::-webkit-text-stroke": "1px rgb(1, 111, 185)",
    color: "rgb(1, 111, 185)",
    textShadow: [
      "3px 3px 0 #000",
      "-1px -1px 0 #000",
      "1px -1px 0 #000",
      "-1px 1px 0 #000",
      "1px 1px 0 #000",
    ],
    margin: "auto",
  },
  maxSketchContainer: {
    width: "100px",
    margin: 10
  },
  smallMaxSketchContainer: {
    maxWidth: "30px",
  },
  snackbarContent: {
    backgroundColor: "green",
    width: "100%",
    margin: "auto"
  },
  snackbarGrids: {
    width: "100%"
  },
  snackbar: {
    zIndex: 100000
  },
  maxSketchGrid: {
    width: "100px"
  },
  maxSketch: {
    width: "100%",
    margin: 0,
    top: "50%",
    position: "relative",
    transform: "translateY(-50%)",
    maxWidth: "100px",
  },
  maxSketchWaiting: {
    width: "100%",
    margin: "auto",
    top: "50%",
    position: "relative",
    transform: "translateY(-25%)",
    maxWidth: "150px",
  },
  movementNameText: {
    marginRight: "9px"
  },
  movementNameInactive: {
    paddingLeft: 10,
    paddingRight: 10,
    // background: "linear-gradient(60deg, #78BD76, #a3cea1)",
    borderRadius: 5,
    margin: "0px 8px"
  },
  movementNameInactiveUnwritten: {
    paddingLeft: 10,
    paddingRight: 10,
    // background: "linear-gradient(60deg, #769BBD, #a4bdd4)",
    borderRadius: 5,
    margin: "0px 8px"
  },
  movementNameActive: {
    fontSize: 24,
    paddingLeft: 10,
    paddingRight: 10,
    boxShadow: "0px 9px 10px -2px #dedede",
  },
  dateListItem: {
    paddingLeft: 10,
    backgroundColor: "black",
    margin: "0px 0px 10px 10px",
    borderRadius: "5px 0px 0px 5px",
    color: "white",
    width: "calc(100% - 10px)",
  },
  ul: {
    backgroundColor: "white",
    padding: 0,
    width: "100%",
  },
  container: {
    height: "auto",
    borderColor: "#016fb9",
    border: "1px",
    margin: "auto",
    marginBottom: "25px",
    borderRadius: 5,
  },
  dateTitle: {
    fontSize: 34,
    color: "#FFFFFF",
    backgroundColor: "#016fb9",
    padding: 0,
    letterSpacing: 1.3,
    margin: "auto",
    borderRadius: 5,
    marginBottom: 15,
    marginTop: 35,
  },
  noteContainer: {
    width: "94%",
    paddingTop: 1,
    display: "flex",
    flexDirection: "row",
    marginLeft: 20
  },
  noteText: {
    fontSize: 18,
    margin: "auto",
    width: '100%'
  },
  repsAdornment: {
    color: "black",
  },
  exercise: {
    margin: 0,
    borderRadius: 5,
  },
  pad: {
    position: "absolute",
    top: 56,
  },
  programText: {
    textAlign: "center",
    margin: 0,
    fontSize: 12,
  },
  resultTextField: {
    width: "100%",
    marginLeft: 5,
    marginRight: 5,
  },
  resultButton: {
    width: "70%",
    marginLeft: 15,
    marginRight: 5,
  },
  resultContainer: {
    display: "flex",
    flexDirection: "row",
    width: "95%",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "auto"
  },
  topResultContainer: {
    width: '100%'
  },
  resultListItemGreen: {
    display: "flex",
    flexDirection: "column",
    margin: "1px 0px 2px 0px",
    background: "linear-gradient(60deg, #78BD76, #a3cea1)",
    "&:hover": {
      background: "linear-gradient(60deg, #78BD76, #a3cea1)",
    },
    "&:click": {
      background: "linear-gradient(60deg, #78BD76, #a3cea1)",
    },
    "&:focusVisible": {
      background: "linear-gradient(60deg, #78BD76, #a3cea1)",
    },
  },
  resultListItemRed: {
    display: "flex",
    flexDirection: "column",
    margin: "1px 0px 2px 0px",
    background: "linear-gradient(60deg, #becfde, #a4bdd4)",
    "&:hover": {
      background: "linear-gradient(60deg, #becfde, #a4bdd4)",
    },
    "&:click": {
      background: "linear-gradient(60deg, #becfde, #a4bdd4)",
    },
    "&:focusVisible": {
      background: "linear-gradient(60deg, #becfde, #a4bdd4)",
    },
  },
  resultSubmitButton: {
    margin: "auto",
    backgroundColor: "rgb(0 0 0 / 75%)",
  },
  statusGreen: {
    color: "green",
    fontWeight: "bold",
  },
  statusYellow: {
    color: "orange",
    fontWeight: "bold",
  },
  statusRed: {
    color: "red",
    fontWeight: "bold",
  },
  accessoryDivider: {
    margin: "auto",
    textAlign: "center",
    borderBottom: '2px solid #c5c5c5',
    line: '0.1em',
    width: "10%"
  },
  changedMovementText: {
    fontSize: 24,
    paddingLeft: 10,
    paddingRight: 10,
    position: "absolute"
  },
  setMovementNameMismatch: {
    fontSize: '10px',
    fontStyle: 'italic',
    fontAlign: 'left',
    width: "95%",
    margin: "0px 0px -6px 0px",
    fontWeight: '300'
  },
  appPopupContainer: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 10,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: 400,
    backgroundColor: 'white',
    border: '3px solid #000',
    boxShadow: 24,
    padding: 10,
    borderRadius: 10
  },
  appPopupBackdrop: {
    zIndex: '5 !important'
  },
  appPopupHeader: {
    display: 'flex',
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '600',
    fontSize: 22
  },
  storeImageSuperContainer: {
    display: 'flex',
    flex: 1,
    flexGrow: 1,
    flexShrink: 1,
    marginTop: 10,
    justifyContent: 'space-around'
  },
  storeImageContainer: {
    display: 'flex',
    flexShrink: 1,
    flexGrow: 1,
    maxWidth: '45%',
    flexDirection: 'column'
  },
  storeImage: {
    display: 'flex',
    flexShrink: 1,
    maxWidth: '100%',
    flexGrow: 1,
  },
  storeText: {
    display: 'flex',
    flexShrink: 1,
    fontSize: 9,
    textAlign: 'center'
  },
  waitUntilSundayContainer: {
    display: 'flex',
    backgroundColor: 'red',
    padding: 10,
    color: 'white',
    justifyContent: 'center',
    fontWeight: 700,
  }
};
