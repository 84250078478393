import React from 'react';
import { createStyles } from '@material-ui/core';
import Variable from './Variable';


const randColor = () => '#' + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6)

export default ({ variables }) => {

    return (
        <>
            <div style={styles.variableContainer}>
                {
                    variables.map((variable) => <Variable variable={variable} color={randColor()} />)
                }
            </div>
        </>
    );
}

const styles = createStyles({
    variableContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gridGap: '12px',
        width: '100%',
        margin: '0',
        minHeight: '23vh',
        borderColor: 'gray',
        borderWidth: "1px 0px 1px 0px",
        borderStyle: 'solid'
    },
    variableOption: {
        padding: '12px',
        background: 'white',
        borderWidth: '2px',
        borderRadius: '10px',
        borderStyle: 'solid'
    }
});