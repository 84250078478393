export const styles = () => ({
  EndDateTextContainer: {
    paddingTop: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },

  descriptionText: {
    fontSize: 16,
    textAlign: "center",
    paddingTop: 5,
    marginBottom: 5,
    width: "100%",
  },

  descriptionTextBolded: {
    fontWeight: "bold",
    fontSize: 24,
    textAlign: "center",
    paddingTop: 5,
    marginBottom: 5,
    width: "100%",
  },
  startEndDateDisplayContainer: {
    display: "flex",
    flex: 2,
    paddingTop: 20,
    paddingBottom: 20,
    flexDirection: "column",
    boxShadow:
      "5px 0px 5px -3px #dadada inset, -5px 0px 5px -3px #dadada inset",
    fontSize: 18,
  },
  startEndDateDisplay: {
    fontWeight: "bold",
    color: "black",
    fontSize: 26,
    flex: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  weekNumberDisplay: {
    fontWeight: "bold",
    color: "black",
    height: 125,
    fontSize: 60,
    flex: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textDisplay: {
    fontWeight: "bold",
    fontSize: 60,
    textAlign: "center",
    color: "black",
    display: "flex",
  },
  iconDisplay: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#edf4fc",
    color: "black",
    fontSize: 36,
    flex: 0,
    flexShrink: 1,
    paddingLeft: 20,
    paddingRight: 20,
    alignItems: "center",
  },
  ProgFocusSuperContainer: {
    display: "flex",
    width: 800,
    flexDirection: "row",
    marginTop: 5,
    alignItems: "center",
  },
  dateSelectorSuperContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginTop: 20,
    alignItems: "center",
  },
  dateSelectorContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    boxShadow: "2px 3px 10px 2px #dadada",
    borderRadius: 8,
  },
  dateSelector: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
  },
  dateSelectorTitle: {
    paddingTop: 20,
    fontSize: 16,
    fontWeight: "700",
    color: "#9e9e9e",
    alignSelf: "center",
    marginBottom: 15,
  },
  dateSelectorDay: {
    fontSize: 11,
    fontStyle: "italic",
  },
  programChoiceContainer: {
    width: "100%",
    maxHeight: 125,
    minHeight: 75,
    display: "flex",
    flexDirection: "column",
    paddingVertical: 25,
    marginBottom: 20,
    borderRadius: 5,
    boxShadow: "2px 3px 12px 2px #dadada",
  },
  programChoiceButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    backgroundColor: "blue",
    flex: 1,
    fontSize: 20,
    fontWeight: 600,
    color: "white",
    borderRadius: 9,
    background: "linear-gradient(90deg,#07A819, green)",
  },
  hideView: {
    opacity: "0.0",
    height: "100%",
    width: "100%",
  },
  toplevel: {
    display: "flex",
    height: "100%",
    justifyContent: "flex-start",
  },
  errorDiv: {
    display: "flex",
    width: "100%",
    paddingBottom: 10,
    justifyContent: "center",
    color: "red",
  },
  progressBarContainer: {
    width: "100%",
    margin: "64px auto 0 auto",
    position: "sticky",
    top: 63,
    zIndex: 200,
  },
  progressBar: {
    height: 15,
  },
  circularProgressPrimaryColor: {
    stroke: "#ffffff",
  },
  formButton: {
    backgroundColor: "red",
    color: "#fff",
    width: "100%",
    height: 60,
  },
  formButtonSmall: {
    color: "#fff",
    width: "95%",
    height: 50,
    marginLeft: "2.5%",
    marginRight: "2.5%",
    alignSelf: "center",
    maxWidth: 500,
  },
  formButtonSmallContainer: {
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    left: 0,
    bottom: 0,
    width: "100%",
    paddingBottom: 8,
    paddingTop: 10,
    backgroundColor: "white",
    boxShadow: "0px -2px 8px 1px #dadada",
    zIndex: 10,
  },
  nextBtn: {
    margin: "auto",
    fontWeight: "600",
    fontSize: 18,
    borderRadius: 25,
  },
  nextBtnWH: {
    margin: "auto",
    fontWeight: "600",
    fontSize: 18,
    borderRadius: 25,
    background: "linear-gradient(90deg, #002141, #003466)",
  },
  btnText: {
    fontSize: 30,
    fontWeight: 600,
    color: "white",
  },
  btnTextSmall: {
    fontSize: 15,
    fontWeight: 400,
    color: "white",
  },
  img: {
    width: 150,
    height: "auto",
    borderRadius: "0 4px 4px 0",
  },
  btnTextContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
  },
  payButton: {
    display: "flex",
    justifyContent: "space-between",
    border: "1px solid black",
    borderRadius: 4,
    backgroundColor: "rgb(0, 34, 67)",
    height: 150,
    margin: "0 auto",
    marginTop: "30px",
    cursor: "pointer",
  },
  formFieldsContainer: {
    paddingTop: "1vh",
    gridRowStart: 1,
    margin: "auto 0",
    overflowY: "auto",
    minWidth: 350,
  },
  fitnessFieldsContainer: {
    "@media (max-width: 600px)": {
      margin: "0 auto",
    },
  },
  stepFormContainer: {
    width: "90%",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "auto",
    alignItems: "center",
    justifyContent: "flex-start",
    paddingBottom: 100,
  },
  fitnessFormContainer: {
    display: "flex",
    minHeight: "73vh",
    margin: "auto",
    "@media (max-width: 600px)": {
      minHeight: "70vh",
    },
  },
  fitnessRadioGroup: {
    margin: "50px 0",
    "@media (max-width: 600px)": {
      margin: "5px 0",
    },
  },

  outerContainerForBackButton: {
    display: "flex",
    flex: 0,
    justifyContent: "center",
    width: "90%",
    margin: "auto",
  },
  formStepContainer: {
    display: "flex",
    flex: 1,
    width: "95%",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "500px",
  },
  formStepContainerv2: {
    display: "flex",
    flex: 1,
    width: "95%",
    justifyContent: "center",
    flexDirection: "column",
    maxWidth: "750px",
  },
  backPressContainer: {
    position: "fixed",
    zIndex: 1003,
    top: 100,
    left: 0,
    height: 50,
    width: 50,
    alignItems: "center",
    justifyContent: "center",
  },
  formControlContainer: {
    height: "100%",
    alignItems: "center",
  },
  formStepContent: {
    display: "flex",
    flex: 1,
    flexGrow: 0,
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    maxWidth: "500px",
  },
  formStepContentv2: {
    display: "flex",
    flex: 1,
    flexGrow: 0,
    flexDirection: "column",
    margin: "auto",
    width: "100%",
    maxWidth: "750px",
  },
  formStepContentPayment: {
    margin: "auto",
    width: "100%",
    maxWidth: "500px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  explainMessage: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    alignItems: "center",
    margin: 20,
    marginBottom: 20,
  },
  iconContainer: {
    width: "22%",
    margin: "auto",
    marginBottom: 13,
    position: "relative",
    top: "0px",
    borderRadius: 10,
  },
  iconObject: {
    width: "100%",
    borderRadius: 20,
  },
  backdrop: {
    zIndex: 3,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
  },
  labelRoot: {
    fontSize: 12,
  },
  spacingInput: {
    width: "100%",
    margin: "0 10px 0 0",
  },
  root: {
    backgroundColor: "red",
  },
  maxContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    margin: "10px 0",
  },
  radioButton: {
    margin: "auto",
  },
  radioHeader: {
    fontSize: 20,
    padding: 0,
    margin: -6,
    fontWeight: "bold",
  },
  radioDescription: {
    textAlign: "left",
  },
  radio: {
    "&$checked": {
      color: "#3f51b5",
    },
  },
  checked: {
    fontColor: "#b0b0b0",
  },
  fieldText: {
    fontSize: 10,
    textAlign: "left",
    color: "#757575",
  },
  callToActionContainer: {
    width: 300,
    marginTop: 10,
  },
  registerRoot: {
    height: 60,
    width: 300,
    backgroundColor: "rgba(0,0,0,.3)",
    border: "1px solid #fff",
    borderRadius: "1px",
    fontFamily: "Jura",
    fontWeight: 900,
    fontSize: "28px",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,.5)",
    },
  },
  registerLabel: {
    color: "#fff",
    fontSize: 22,
    letterSpacing: 1.35,
  },
  weightInput: {
    margin: "0 5px 0 0",
    width: "30%",
  },
  xrmInput: {
    margin: "0 5px 0 5px",
    width: "30%",
  },
  styleInput: {
    margin: "0 0px 0 5px",
    width: "40%",
  },
  termsContainer: {
    maxWidth: 400,
    minWidth: 400,
    padding: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    gap: 20,
    borderWidth: 2,

    borderRadius: 15,

    backgroundColor: "red",
  },
  termsCheckbox: {
    display: "flex",
    alignItems: "flex-start",
  },
  selectDaysExplainText: {
    fontSize: 11,
    fontStyle: "italic",
    paddingTop: 5,
  },
  programFocus: {
    paddingTop: 20,
    fontSize: 16,
    fontWeight: "700",
    color: "#9e9e9e",
    alignItems: "center",
    marginBottom: 15,
  },
  programFocusTextDescriptionText: {
    fontSize: 15,
    textAlign: "center",
    marginTop: 30,
    marginBottom: 10,
    width: "100%",
  },
  advanceOptionsFieldText: {
    fontSize: 13,
    fontStyle: "italic",
    paddingTop: 10,
  },
  dateCalendarPickerContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    marginTop: 20,
    alignItems: "center",
    transform: `translate(-25%)`,
    justifyContent: "center",
  },
  rdrDefinedRangesWrapper: {
    display: null,
  },
  dateCalendarSelectorTitle: {
    paddingTop: 20,
    fontSize: 16,
    fontWeight: "700",
    color: "#9e9e9e",
    alignSelf: "center",
    marginBottom: 15,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  startDate: {
    borderColor: "black",
    borderWidth: 10,
    borderStyle: "solid",
    marginRight: 60,
  },
  endDate: {
    marginLeft: 220,
    borderColor: "black",
    borderWidth: 10,
    borderStyle: "solid",
  },
  displayGraph: {
    display: "flex",
    flexDirection: "row",
  },
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "green",
    },
  },
  gaugeContainer: {
    height: 200,
    width: 200,
    // borderColor: "black",
    // borderWidth: 10,
    // borderStyle: "solid",
  },
  gauge: {
    height: 200,
    width: 200,
    // borderColor: "black",
    // borderWidth: 10,
    // borderStyle: "solid",
  },
  chartDiv: {
    borderColor: "black",
    borderWidth: 10,
    borderStyle: "solid",
  },
  formItemTitle: {
    paddingTop: 20,
    fontSize: 16,
    fontWeight: "700",
    color: "#9e9e9e",
    alignSelf: "center",
    marginBottom: 20,
  },
  spacer: {
    height: 50,
    width: 50,
  },
});
