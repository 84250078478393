export const styles = () => ({
  textInputRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row'

  },

  coachProgramContainer: {
    width: '100%',
    height: 800,
    backgroundColor: 'grey'

  },

  breakLine: {

  },

  dataGridContainer: {

  },
});
