import axios from 'axios';
import { Auth } from 'aws-amplify';
import { adjustMaxs } from '../../utils/helpers'

const coachUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/admin`;
const workoutsUrl = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/workouts`;

export const getUsers = async (selectedTeam) => {
  console.log('running getUsers with selectedTeam:', selectedTeam)

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const data = {
    get: 'user_list',
    count: 200,
    page: 1,
    sortBy: 'last_active',
    sortOrder: 'desc',
    selectedTeam: selectedTeam,
    source: 'web'
  }

  return axios
    .get(coachUrl, { params: data, headers })
    .then((data) => data.data)
    .catch((error) => console.log(error));
};

export const searchUsers = async (searchTerm) => {
  console.log('running searchUsers with searchTerm:', searchTerm)

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = { 'Authorization': authToken, 'Content-Type': 'application/json' }


  const data = {
    get: 'user_search',
    count: 200,
    page: 1,
    sortBy: 'last_active',
    sortOrder: 'desc',
    search_term: searchTerm,
    source: 'web'
  }

  return axios
    .get(coachUrl, { params: data, headers })
    .then((data) => data.data)
    .catch((error) => console.log(error));
};

export const getStats = async () => {
  console.log('running getStats')

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = { 'Authorization': authToken, 'Content-Type': 'application/json' }

  const data = { get: 'stats', source: 'web' }

  return axios
    .get(coachUrl, { params: data, headers })
    .then((data) => data.data)
    .catch((error) => console.log(error));
};

export const getUser = async (cognito_id) => {
  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const data = {
    get: 'user_record',
    cognito_id: cognito_id,
    source: 'web'
  }

  return axios
    .get(coachUrl, { params: data, headers })
    .then((data) => {
      data.maxs = adjustMaxs(data.maxs, !data.metric)
      return data.data
    })
    .catch((error) => console.log(error));
};

export const confirmTeam = async (cognito_id) => {
  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  const data = {
    post: 'confirm_team',
    cognito_id: cognito_id,
    source: 'web'
  }

  return axios
    .post(coachUrl, null, { params: data, headers })
    .then((data) => window.location.href = window.location.href)
    .catch((error) => console.log(error));
};

export const reloadProgram = async (cognito_id) => {
  const data = { cognito_id: cognito_id, splice_on: 'day', source: 'web' }
  var authToken = await (await Auth.currentSession()).idToken.jwtToken;
  var headers = {
    'Authorization': authToken,
    'Content-Type': 'application/json'
  }

  return axios
    .put(workoutsUrl, data, { params: data, headers })
    .then((data) => data.data)
    .catch((error) => console.log(error));
};

