import React, { Component } from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { withLocalize } from "react-localize-redux";
import {
  user_report_mapping_snatch_to_clean_jerk,
  user_report_mapping_jerk_clean,
  user_report_mapping_power_clean_clean,
  user_report_mapping_power_snatch_snatch,
  user_report_mapping_strict_press_jerk,
  user_report_mapping_back_cl
} from "./userReportMapping";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { wrap } from "lodash";

export class UserReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      left_row: [
        "Snatch",
        "Power Snatch",
        "Power Clean",
        "Clean",
        "Strict Press",
        "Back Squat"
      ],
      right_row: ["Clean & Jerk", "Snatch", "Clean", "Jerk", "Jerk", "Clean"],
      sliderDefaultValues: [],
      displayInfo: [],
      descriptionText: [],
    };
  }

  componentDidMount() {
    let parent = this.props.parent;

    this.state.displayInfo.push(parent.state.ratio_tag)
    this.state.displayInfo.push(parent.state.ratio_tag_power_snatch_snatch)
    this.state.displayInfo.push(parent.state.ratio_tag_power_clean_clean)
    this.state.displayInfo.push(parent.state.ratio_tag_jerk_clean)
    this.state.displayInfo.push(parent.state.ratio_tag_strict_press_jerk)
    this.state.displayInfo.push(parent.state.ratio_tag_back_cl)

    user_report_mapping_snatch_to_clean_jerk(parent.state.ratio_tag, this.state.sliderDefaultValues, this.state.descriptionText)
    user_report_mapping_power_snatch_snatch(parent.state.ratio_tag_power_snatch_snatch, this.state.sliderDefaultValues,this.state.descriptionText)
    user_report_mapping_power_clean_clean(parent.state.ratio_tag_power_clean_clean, this.state.sliderDefaultValues,this.state.descriptionText)
    user_report_mapping_jerk_clean(parent.state.ratio_tag_jerk_clean, this.state.sliderDefaultValues,this.state.descriptionText)
    user_report_mapping_strict_press_jerk(parent.state.ratio_tag_strict_press_jerk, this.state.sliderDefaultValues,this.state.descriptionText)
    user_report_mapping_back_cl(parent.state.ratio_tag_back_cl, this.state.sliderDefaultValues,this.state.descriptionText)
    // console.log('this state', this.state)

  }
  // formatLabel = (value) => {
  //   if (value === 0) {
  //     return "Low";
  //   } else if (value === -1) {
  //     return "Medium";
  //   } else if (value === 1) {
  //     return "High";
  //   }
  // };

  renderLinearProgress(classes, values){

    // const number_mapping = {
    //   10:90,
    //   30:70,
    //   // 90:10,
    //   // 70:30
    // }

    // console.log("number_mapping", number_mapping[10])

      if(values === 90 || values === 70){
      return(
        <LinearProgress variant="determinate"  sx={{
                  transform: " scale(-1)",
                  borderRadius: '-4px',
                  marginTop: 3.5,
                  height: 15,
                  borderRadius: 10,
                  backgroundColor: 'red',
                  '& .MuiLinearProgress-bar': {
                    height: "100%",
                    backgroundColor: 'green',
                    borderRadius: 10,
                  }
                }}value={values} />
      )}else if(values === 900 || values === 700 || values === 500){
          return(
        <LinearProgress variant="determinate"  sx={{
                  borderRadius: '-4px',
                  marginTop: 3.5,
                  height: 15,
                  borderRadius: 10,
                  backgroundColor: 'red',
                  '& .MuiLinearProgress-bar': {
                    height: "100%",
                    backgroundColor: 'green',
                    borderRadius: 10,
                  }
                }}value={values/10} />
      )
      }
    }


renderUserReport(classes) {
  let parent = this.props.parent;

  return (
    <div>
      <TableContainer>
        <Table sx={{ minWidth: 650, minHeight: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Ratios</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.left_row.map((row, index) => {
              return (
                <TableRow
                  key={row}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    height: 40,
                  }}
                >
                  <TableCell align="center" component="th" scope="row">
                    <Typography variant="body1" align="center">
                      {row}
                    </Typography>
                  </TableCell>
                  <TableCell
                    align="center"
                    component="th"
                    scope="row"
                    sx={{ minWidth: 300}} // Center everything in the second cell
                  >
                    {this.renderLinearProgress(classes, this.state.sliderDefaultValues[index])}
                    <Typography variant="body2" align="center" sx={{ marginTop: 1}}>
                      {this.state.descriptionText[index]}
                    </Typography>
                  </TableCell>
                  <TableCell align="center" component="th" scope="row">
                    <div>
                      <Typography variant="body1" align="left">
                        {this.state.right_row[index]}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

  render() {
    const { classes } = this.props;
    return <div>{this.renderUserReport(classes)}</div>;
  }
}

export default withLocalize(UserReport);