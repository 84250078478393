import componentResultForm from './ResultForm'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { Translate } from "react-localize-redux";

function isMovementName(set, movement, classes, lang) {
  if (Object.keys(set).includes('movement_name') && set.movement_name.en !== movement.movement_name.en) {
    console.log('rendered set:', set)
    return (
      <Translate>
        {(translate) => (
          <div
            className={classes.setMovementNameMismatch}
            id={`${movement.movement_id}_setMovementNameMismatch`}
          >
            {translate.translate(
              "completedAsMovement",
              { movement_name: set.movement_name[lang] }
            )}
          </div>
        )}
      </Translate>

    )
  }
}

export default function componentResults(
  classes,
  movement,
  handleClick,
  indices,
  parent
) {
  const set = movement.sets[indices[3]];
  const lang = parent.props.activeLanguage.tag
  return (
    <ListItem
      className={
        set.written
          ? classes.resultListItemGreen
          : classes.resultListItemRed
      }
      classes={{
        focusVisible: classes.resultListItemGreen,
        selected: classes.resultListItemGreen,
      }}
      key={set.set_id}
      selected={false}
      disableGutters={true}
      id={set.set_id}
    >
      {isMovementName(set, movement, classes, lang)}
      <ListItemText
        primary={componentResultForm(
          classes,
          movement,
          indices,
          handleClick,
          parent
        )}
        className={classes.topResultContainer}
      />
    </ListItem>
  );
};