import React from 'react';
import { FormControl, TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import NativeSelect from '@material-ui/core/NativeSelect';
import { sendResults } from '../api';
import { setDialog } from '../utils';
import { Translate } from "react-localize-redux";
import Button from '@material-ui/core/Button';

function feedback(translate, feedback_type) {
  var feedback = {
    'rir': [
      { value: '', id: 'rir', disabled: true, label: `RIR` },
      { value: -1, id: 'rir_-1', disabled: false, label: `💀 ${translate("fail")}` },
      { value: 0, id: 'rir_0', disabled: false, label: `😱 0 RIR` },
      { value: 1, id: 'rir_1', disabled: false, label: `😰 1 RIR` },
      { value: 2, id: 'rir_2', disabled: false, label: `😧 2 RIR` },
      { value: 3, id: 'rir_3', disabled: false, label: `😯 3 RIR` },
      { value: 4, id: 'rir_4', disabled: false, label: `🙂 4 RIR` },
      { value: 5, id: 'rir_5', disabled: false, label: `😀 5 RIR` }
    ],
    'white_red_lights': [
      { value: '', id: 'rate', disabled: true, label: `Rate...` },
      { value: 0, id: 'rir_0', disabled: false, label: `🔴 🔴 🔴` },
      { value: 1, id: 'rir_1', disabled: false, label: `⚪️ 🔴 🔴` },
      { value: 2, id: 'rir_2', disabled: false, label: `⚪️ ⚪️ 🔴` },
      { value: 3, id: 'rir_3', disabled: false, label: `⚪️ ⚪️ ⚪️` }
    ]
  }

  if (feedback_type) {
    return feedback[feedback_type]
  } else {
    return feedback['rir']
  }
}

function handleResult(
  e,
  week_index,
  day_index,
  movement_index,
  set_index,
  parent,
  handleClick
) {
  var items = parent.state.items;
  var errors = parent.state.errors;
  console.log(e.target, week_index, day_index, movement_index);

  console.log(e.target.name, e.target.value, e.target.id)

  const name = e.target.name;
  const value = e.target.value;
  const id = e.target.id;

  if (errors.includes(id)) {
    errors.splice(errors.indexOf(id), 1);
    parent.setState(() => ({ errors }))
  }


  items[week_index].days[day_index]
    .movements[movement_index].sets[set_index][name] = value;
  items[week_index].days[day_index]
    .movements[movement_index].sets[set_index].written = false;
  // not saved

  parent.setState(() => ({ items }))

  if (name === 'rir') {
    submitResult(
      week_index,
      day_index,
      movement_index,
      set_index,
      handleClick,
      parent
    )
  }
};

function submitResult(
  week_index,
  day_index,
  movement_index,
  set_index,
  handleClick,
  parent,
) {
  var micro = parent.state.items[week_index]
  var day = micro.days[day_index];
  var movement = day.movements[movement_index];
  var next_movement = day.movements[parseInt(movement_index) + 1];
  var set = JSON.parse(JSON.stringify(movement.sets[set_index]));
  var err_var = null
  var errors = parent.state.errors

  parent.setState({ loadingMovement: movement.movement_id })

  console.log('SUBMIT SET:', set)

  // check for validation errors
  if (set.weight === null || set.weight === '') {
    err_var = set.set_id + '_weight'
    errors.push(err_var)
    parent.setState({ errors })
  }
  if (set.reps === null || set.reps === '') {
    err_var = set.set_id + '_reps'
    errors.push(err_var)
    parent.setState({ errors })
  }
  if (set.rir === null || set.rir === '') {
    err_var = set.set_id + '_rir'
    errors.push(err_var)
    parent.setState({ errors })
  }

  if (err_var === null) {
    set.rir = parseInt(set.rir)
    set.written = true;

    movement.sets[set.set_order] = set
    var send_obj = parent.state.program === 'weightlifting' ? movement : set

    send_obj['cognito_id'] = parent.state.cognito_id;
    send_obj['program_id'] = parent.state.program_id;

    console.log('sending results with send_obj:', send_obj)

    sendResults(send_obj, set_index, parent).then((e) => {
      console.log('then e:', e);
      parent.setState({ loadingMovement: '' })
      if (e === 'error') {
        set.written = false;
        console.log('ERROR! ' + e);
        setDialog(<Translate id="error" />, parent); //error
      } else {
        console.log('No error on result submission, e:', e)

        // fill in parent items with the new data
        var items = parent.state.items;
        items[week_index] = e.data.program;
        var new_move = items[week_index].days[day_index].movements[movement_index]

        // if this is the last set in a movement, then close this movement and
        // open the next
        if (new_move.sets.length === set.set_order + 1) {
          console.log('next_movement:', next_movement);
          if (day.movements.length === movement.movement_order + 1) {
            parent.setState({ items, expandedMovement: null });
          } else {
            handleClick(movement, movement.movement_id, parent)

            setTimeout(function () {
              handleClick(next_movement, next_movement.movement_id, parent)
            }, 50)
          }
        }

        // set items to the new items
        parent.setState({ items });

        // run snackbar shit
        if ('snackbar_message' in e.data) {
          parent.setState({
            snackbarMessage: e.data.snackbar_message,
            snackbarOpen: true
          })
        }
      }
    });
  }
};

function isDisabled(movement, this_set, set_before) {
  if (Object.keys(this_set).includes('movement_name') &&
    movement.movement_name.en !== this_set.movement_name.en) {
    return true
  } else {
    return set_before ? (set_before.written ? false : true) : false
  }
}

function isWeightDisabled(movement) {
  // if tags is in movement
  if (movement.tags) {
    // and movement.tags has an "unweighted" tag
    return movement.tags.includes("unweighted")
  } else {
    // otherwise, weight is not disabled
    return false
  }
}

function feedback_mechanism(
  state,
  this_set,
  setDisabled,
  classes,
  indices,
  parent,
  translate,
  movement,
  handleClick
) {
  if (movement.feedback_type === 'completion') {
    var value = { target: { name: 'rir', value: 1, id: this_set.set_id } }
    return (
      <Button
        disabled={setDisabled}
        variant="contained"
        className={classes.resultButton}
        onClick={() =>
          handleResult(
            value,
            indices[0],
            indices[1],
            indices[2],
            indices[3],
            parent,
            handleClick,
          )
        }
      >
        Submit
      </Button>
    )
  } else {
    return (
      <NativeSelect
        required={true}
        error={state.errors.includes(this_set.set_id + '_rir')}
        value={this_set.rir !== null ? this_set.rir : ''}
        disabled={setDisabled}
        className={classes.resultTextField}
        onChange={(e) =>
          handleResult(
            e,
            indices[0],
            indices[1],
            indices[2],
            indices[3],
            parent,
            handleClick,
          )
        }
        inputProps={{
          name: 'rir',
          id: this_set.set_id + '_rir',
        }}
      >
        {
          feedback(translate, movement.feedback_type).map(item => {
            return (
              <option
                value={item.value}
                id={item.id}
                disabled={item.disabled}
                key={item.id}
              >
                {item.label}
              </option>
            )
          })
        }

      </NativeSelect>
    )
  }
}


function componentResultForm(
  classes,
  movement,
  indices,
  handleClick,
  parent
) {
  var state = parent.state;
  const translate = parent.props.translate
  const this_set = movement.sets[indices[3]];
  const set_before = movement.sets[indices[3] - 1];
  const setDisabled = isDisabled(movement, this_set, set_before)

  return (
    <FormControl className={classes.resultContainer}>
      <TextField
        required={true}
        error={state.errors.includes(this_set.set_id + '_reps')}
        type="text"
        inputProps={{ inputMode: 'numeric' }}
        value={this_set.reps}
        disabled={setDisabled}
        name="reps"
        fullWidth
        id={this_set.set_id + '_reps'}
        onChange={(e) => {
          handleResult(
            e,
            indices[0],
            indices[1],
            indices[2],
            indices[3],
            parent,
            handleClick
          );
        }
        }
        className={classes.resultTextField}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              className={setDisabled ? classes.adornmentDisabled : classes.repsAdornment}
              disableTypography={true}
            >
              {movement.movement_name.en.includes('plank') ? 'sec' : 'reps'}
            </InputAdornment>
          ),
        }}
      />
      <TextField
        required={true}
        error={state.errors.includes(this_set.set_id + '_weight')}
        type="text"
        inputProps={{ inputMode: 'decimal' }}
        value={this_set.weight}
        disabled={setDisabled || isWeightDisabled(movement)}
        name="weight"
        fullWidth
        id={this_set.set_id + '_weight'}
        onChange={(e) =>
          handleResult(
            e,
            indices[0],
            indices[1],
            indices[2],
            indices[3],
            parent,
            handleClick,
          )
        }
        className={classes.resultTextField}
        InputLabelProps={{
          classes: {
            root: classes.labelRoot,
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              className={setDisabled ? classes.adornmentDisabled : classes.repsAdornment}
              disableTypography={true}
            >
              {state.metric ? "kg" : "lb"}
            </InputAdornment>
          ),
        }}
      />
      <Translate>
        {({ translate }) =>
          feedback_mechanism(
            state,
            this_set,
            setDisabled,
            classes,
            indices,
            parent,
            translate,
            movement,
            handleClick
          )
        }
      </Translate>
    </FormControl>
  );
};

export default componentResultForm