import React, { Component } from 'react'
import { OnboardUserInfoForm } from "../../../components/Form/userForm";
import { withLocalize } from 'react-localize-redux'
import mixpanel from 'mixpanel-browser';

export class LifterInfo extends Component {

  constructor(props) {
    super(props)
    this.state = { loaded: true }

    mixpanel.track('lifter info screen');
  }

  render() {
    const { classes, props } = this.props
    const metric = props.state.metric
    const visibleStyle = this.state.loaded ? classes.outerContainerForBackButton : classes.hideView

    return (
      <React.Fragment>
        <div className={visibleStyle}>
          <div className={classes.formStepContainer}>
            <div className={classes.formStepContent}>
              <OnboardUserInfoForm
                parent={props}
                units={metric ? 'kg' : 'lb'}
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withLocalize(LifterInfo)