import React from "react";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { withStyles } from "@material-ui/core";
import { getDates, handleSnackbar, renderEndMessage } from "./utils";
import { fetchData, fetchMoreData } from "./api";
import Modal from '@mui/material/Modal';
import { styles } from "./styles";
import List from "@material-ui/core/List";
import componentWeeks from "./components/Weeks";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { Translate } from "react-localize-redux";
import { withLocalize } from "react-localize-redux";
import { Auth } from "aws-amplify";
import LogRocket from 'logrocket';
import google_store from '../../assets/google_store.png';
import apple_store from '../../assets/apple_store.png';
import mixpanel from 'mixpanel-browser';
import { connect } from "react-redux";

class _Workouts extends React.Component {
  state = {
    cognito_id: "",
    items: [],
    hasMore: true,
    dialogOpen: false,
    snackbarOpen: false,
    snackbarMessage: null,
    dialogText: "Hey!",
    expandedMovement: "",
    loadingMovement: "",
    errors: [],
    changedMovement: "",
    changedMovementFadeIn: false,
    newMovementOption: null,
    oldMovementOption: null,
    changedMovementNewName: "",
    coach: false,
    sudo: false,
    showAll: false,
    appPopupOpen: false,
    programStartsInFuture: false
  };

  async componentDidMount() {
    mixpanel.track('view workouts')

    var cognito_id = null
    var param_cognito = null
    var coach = false
    let sudo = false
    var showAll = false

    // if this is not an coach and they're dialing in with parameterized URL
    // then kick them out
    await Auth.currentSession().then((value) => {
      const urlParams = new URLSearchParams(window.location.search)
      param_cognito = urlParams.get('cognito_id')

      const payload = value.accessToken.payload
      const groups = payload['cognito:groups'] ? payload['cognito:groups'] : []

      coach = groups.includes('COACHES') || groups.includes('ADMINS') ? true : false

      if (param_cognito) {
        if (!coach) {
          return this.props.history.push("/workouts");
        } else {
          console.log('parameterized cognito_id:', param_cognito)
          sudo = true
          cognito_id = param_cognito
          showAll = urlParams.get('showAll') === 'true'
          this.setState({ coach, showAll, sudo })
        }
      } else {
        // Identify on LogRocket just in case
        LogRocket.identify(
          value.idToken.payload.sub,
          { email: value.idToken.payload.email }
        );
        cognito_id = payload.username
      }
    })

    // handle the popup telling people they should use the app
    let ts = new Date().getTime();
    if (ts - this.props.lastAppPopup > 306000000) {
      this.setState({ appPopupOpen: true })
    }

    let { start_date, end_date, tomorrow } = await getDates();

    await this.props.fetchUserData(cognito_id, sudo).then((data) => {
      // Check if user has already paid for services
      // If not, redirect to payments page
      console.log('fetchUserData with cognito_id', cognito_id, 'gave us data', data)
      mixpanel.identify(data.email)

      if ((data.payment_overdue || !data.charges.length > 0) && !showAll) {
        console.log('freeloader!')
        if (coach) {
          return this.props.history.push("/coach");
        } else {
          return this.props.history.push("/profile?tab=payment");
        }
      } else {
        // user has paid
        this.setState({
          cognito_id: data.cognito_id,
          coach: coach,
          minDate: start_date,
          maxDate: end_date,
          tomorrow: tomorrow,
          ...data,
        });

        var range = showAll ? { "start_micro": 0, "end_micro": 99 } : null

        fetchData(
          {
            cognito_id,
            start_date,
            end_date,
            tomorrow,
            micro_hold_offset: this.state.micro_hold_offset,
          },
          this,
          range
        );

        // console.log("state after componentdidmount:", this.state);
      }
    });
  }

  loadingProgress = () => {
    if (this.state.items.length === 0) {
      return (
        <Backdrop open={true} invisible={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      );
    }
    return (<h4>That's all!</h4>);
  };

  handleHolds() {
    const { classes, translate } = this.props;
    if (this.state.on_hold_status === "on_hold") {
      return (
        <div className={classes.waitingContainer}>
          <Translate id="programOnHold1" />
          <div onClick={() => this.props.history.push("/workout-settings")}>
            <Translate id="programOnHold2" />
            <Translate id="programOnHold3" />
          </div>
        </div>
      );
    } else if (this.state.on_hold_status === "hold_pending_active") {
      return (
        <div className={classes.waitingContainer}>
          <div>
            {translate(
              "programPendingReactivation",
              { on_hold_end_date: this.state.on_hold_end_date }
            )}
          </div>
        </div>
      );
    }
  }

  handleWorkoutInfo(classes) {
    let data

    if (this.state.sudo) {
      if (this.state.items.length > 0) {
        if (this.state.items[0].program_data) {
          data = this.state.items[0].program_data

          return (
            <div>
              <div className={classes.coachInfoBox}>
                USER ID: {this.state.cognito_id} <br />
                USER EMAIL: {this.state.email} <br />
                PROGRAM START DATE: {this.state.start_date}<br />
                USER WEIGHT: {this.state.bodyweight}<br />
                USER HEIGHT: {this.state.height}<br />
                USER MALE: {String(this.state.male)}<br />
              </div>
              <div className={classes.coachInfoBox}>
                QUALIFICATION: {data.qualification} <br />
                VOL LANDMARKS MEV: {JSON.stringify(data.volume_landmarks.mev)} <br />
                VOL LANDMARKS MRV: {JSON.stringify(data.volume_landmarks.mrv)} <br />
                VOL LANDMARKS MV: {JSON.stringify(data.volume_landmarks.mv)} <br />
              </div>
            </div>
          )
        } else {
          return (
            <div>
              <div className={classes.coachInfoBox}>
                USER ID: {this.state.cognito_id} <br />
                USER EMAIL: {this.state.email} <br />
                PROGRAM START DATE: {this.state.start_date}<br />
                USER WEIGHT: {this.state.bodyweight}<br />
                USER HEIGHT: {this.state.height}<br />
                USER MALE: {String(this.state.male)}<br />
              </div>
              <div className={classes.coachInfoBox}>
                RESUBMIT PROFILE TO VIEW FULL STATS
              </div>
            </div>
          )
        }
      }
    }
  }

  handleAppPopupClose = () => {
    this.setState({ appPopupOpen: false })

    let ts = new Date().getTime();
    this.props.updateLastAppPopup(ts)
  }

  renderAppPopup = (classes) => {
    return (
      <Modal
        open={this.state.appPopupOpen}
        onClose={() => this.handleAppPopupClose()}
        classes={{ root: classes.appPopupBackdrop }}
      >
        <div className={classes.appPopupContainer}>
          <div className={classes.appPopupHeader}>
            It's better on the app!
          </div>
          <div>
            Use your same account to login on the app and unlock a better experience.
          </div>
          <div className={classes.storeImageSuperContainer}>
            <div className={classes.storeImageContainer}>
              <img
                className={classes.storeImage}
                src={apple_store}
                alt=""
                onClick={() => {
                  const win = window.open(`https://apps.apple.com/us/app/weightlifting-ai/id1602877760`, "_blank");
                  win.focus();
                }}
              />
            </div>
            <div className={classes.storeImageContainer}>
              <img
                className={classes.storeImage}
                src={google_store}
                alt=""
                onClick={() => {
                  const win = window.open(`https://play.google.com/store/apps/details?id=com.weightliftingHouse.rn`, "_blank");
                  win.focus();
                }}
              />
            </div>
          </div>
        </div>
      </Modal>
    )
  }


  render() {
    const { classes, activeLanguage } = this.props;
    const hold_status = this.state.on_hold_status;
    const { showAll } = this.state

    // snackbar message
    const snack = this.state.snackbarMessage ? this.state.snackbarMessage[`snackbar_${activeLanguage.tag}`] : null

    if (["hold_pending_active", "on_hold"].includes(hold_status) && !showAll) {
      return this.handleHolds();
    } else {
      return (
        <div className={classes.scrollPad}>
          <Snackbar
            open={this.state.snackbarOpen}
            autoHideDuration={5000}
            className={classes.snackbar}
            onClose={() => handleSnackbar(this)}
          >
            <SnackbarContent
              className={classes.snackbarContent}
              classes={{ message: classes.snackbarGrids }}
              message={
                <Grid container spacing={1} className={classes.snackbarGrids}>
                  <Grid item xs={9} className={classes.noteText}>
                    {snack}
                  </Grid>
                </Grid>
              }
            />
          </Snackbar>
          {this.renderAppPopup(classes)}
          <Dialog
            onClose={() => {
              this.setState({ dialogOpen: false });
            }}
            transitionDuration={0}
            open={this.state.dialogOpen}
            fullWidth={true}
            maxWidth={"md"}
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            PaperProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "none",
              },
            }}
            style={{
              width: "100%",
              height: "700px",
              backgroundColor: "transparent",
              lineHeight: "230px",
              fontSize: "16vw",
              margin: "auto",
            }}
          >
            <div className={classes.dialogText}>{this.state.dialogText}</div>
          </Dialog>
          {this.handleWorkoutInfo(classes)}
          <InfiniteScroll
            dataLength={this.state.items.length}
            next={() => console.log('end')}
            hasMore={this.state.hasMore}
            loader={this.loadingProgress()}
            endMessage={renderEndMessage(classes, this)}
            className={classes.scrollComponent}
          >
            <List
              className={classes.root}
              disablePadding={true}
              subheader={<div className={classes.li} />}
            >
              {Object.keys(this.state.items).map((week_index) =>
                componentWeeks(classes, week_index, this)
              )}
              <div className={this.state.expandedMovement ? classes.bottomSpacer : null} />
            </List>
          </InfiniteScroll>
        </div>
      );
    }
  }
}

const mapStateToProps = ({ userReducer }) => ({
  userData: userReducer,
});

export default withRouter(withStyles(styles)(withLocalize(connect(mapStateToProps)(_Workouts))));
