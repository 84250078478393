import React from 'react';
import { TextField } from "@material-ui/core";
import { Translate } from "react-localize-redux";


const EnterWeight = (props, handleChange) => {
  const { parent } = props

  let metric = parent.state.metric

  return (
    <TextField
      value={parent.state.bodyweight}
      error={parent.state.errors.includes("bodyweight")}
      required={true}
      label={<Translate id={`weight${metric ? 'kg' : 'lb'}`} />}
      name={"bodyweight"}
      type="text"
      inputProps={{ inputMode: 'numeric' }}
      style={{ margin: "0 0 0 5px" }}
      onChange={(e) => { handleChange(e, parent) }}
      fullWidth
    />
  )
}

export default EnterWeight;