import { createTheme } from "@material-ui/core/styles";

export const theme = createTheme({
  // here are default values in ms
  palette: {
    primary: {
      main: "#003466",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  },
  transitions: {
    duration: {
      shortest: 50,
      shorter: 150,
      short: 200,
      standard: 250,
      complex: 325,
      enteringScreen: 175,
      leavingScreen: 145,
    }
  },
  overrides: {
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0)"
        }
      }
    },
    MuiNativeSelect: {
      select: {
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0)"
        }
      }
    },
    MuiInput: {
      underline: {
        borderBottom: 'black',
        '&:after': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        },
        '&:focused': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        },
        '&:hover:not($disabled):before': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        }
      }
    },
    MuiFormLabel: {
      root: {
        fontSize: 12,
        color: 'rgba(0, 0, 0, 0.54)',
        '&$focused': {
          color: 'rgba(0, 0, 0, 0.54)',
        }
      }
    }
  }
});