import React from "react";
import { Auth } from "aws-amplify";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Button,
  withStyles,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { fetchUserData } from "../../state/thunk";
import { styles } from "./styles";
import { Reset } from "./Reset";
import mixpanel from 'mixpanel-browser';
import { Translate, getTranslate } from "react-localize-redux";
import LogRocket from 'logrocket';

class _SignIn extends React.Component {
  state = {
    username: "",
    email: "",
    password: "",
    authCode: "",
    loading: false,
    error: "",
    resetPassword: false,
  };

  async componentDidMount() {
    const sessionRes = await Auth.currentSession()
    mixpanel.track("view sign in");

    if (sessionRes && sessionRes.idToken?.jwtToken) {
      this.props.history.push('/')
    }
  }

  onChange = (key, value) => {
    this.setState({
      [key]: value,
    });
  };

  signIn = async () => {
    const { translate } = this.props
    await this.setState({ loading: true });
    console.log(this.state.email);
    LogRocket.identify(null, { email: this.state.email })
    mixpanel.identify(this.state.email)
    mixpanel.track("sign in attempt");

    return Auth.signIn(this.state.email, this.state.password)
      .then(async (user) => {
        console.log('user:', user)
        mixpanel.track("sign in success");
        LogRocket.identify(user.username, { email: user.email });

        await this.props.fetchUserData(user.signInUserSession.accessToken.payload.sub);
        await this.setState({ loading: false });

        this.props.updateAuth(true)
        this.props.history.push("/workouts");
      })
      .catch((err) => {
        console.log(err);
        mixpanel.track("sign in error", { 'code': err.code })
        const err_msg = err.code

        var error = ""

        if (err_msg === "NotAuthorizedException") {
          error = translate("incorrectUnOrPw")
        } else {
          // error = translate("tryAgain")
          error = err.message
        }

        this.setState({
          error: error,
          loading: false
        });
      });
  };

  handleForgot = async () => {
    const { translate } = this.props
    if (this.state.email) {
      Auth.forgotPassword(this.state.email)
        .then(() => {
          mixpanel.track('reset password initiate success');
          this.setState({ resetPassword: true });
        })
        .catch((err) => {
          mixpanel.track('reset password initiate error', { 'code': err.code, 'message': err.message })
        })
    } else {
      this.setState({ error: translate("emailRequiredReset") });
    }
  };

  handleSignIn = (e) => {
    e.preventDefault();
    this.signIn();
  };

  render() {
    const { classes, translate } = this.props;
    const { loading, error, email, password, resetPassword } = this.state;

    if (resetPassword) {
      return <Reset
        email={email}
        history={this.props.history}
        translate={translate}
      />;
    }

    return (
      <div className={classes.signinContainer}>
        <form
          className={classes.formContainer}
          onSubmit={this.handleSignIn}
        >
          <div className={classes.formTitle}>
            <Translate id="signIn" />
          </div>
          <TextField
            value={email}
            type="email"
            label={<Translate id="email" />}
            name={"email"}
            onChange={(evt) =>
              this.onChange("email", evt.target.value.toLowerCase())
            }
            fullWidth
          />
          <TextField
            value={password}
            label={<Translate id="password" />}
            name={"password"}
            type="password"
            onChange={(evt) => this.onChange("password", evt.target.value)}
            fullWidth
          />
          <div className={classes.buttonContainer}  >
            <Button
              variant="outlined"
              classes={{ root: classes.onboardRoot, label: classes.onboardLabel }}
              onClick={this.handleSignIn}
              id="sign_in_submit_button"
              disabled={loading}
              type="submit"
            >
              {loading ? (
                <CircularProgress size={15} color="secondary" />
              ) : (
                <Translate id="signIn" />
              )}
            </Button>
            <p id="forgotPassword" onClick={this.handleForgot} style={{ color: "rgb(0, 33, 65)" }}>
              Forgot Password
            </p>
          </div>
          {error && <p className={classes.error}>{error}</p>}
        </form>
      </div>
    );
  }
}

const __SignIn = withRouter(withStyles(styles)(_SignIn));

const mapStateToProps = ({ userReducer, localize }) => ({
  userData: userReducer,
  translate: getTranslate(localize)
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: (cognito_id) => dispatch(fetchUserData(cognito_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(__SignIn);
