export function user_report_mapping_snatch_to_clean_jerk(value, sliderDefaultValues, descriptionText){
  
  if(value == "Much stronger at Clean & Jerk than Snatch"){
    descriptionText.push("Your Snatch is much lower than ideal.")
    return sliderDefaultValues.push(90)
  }
  else if(value == "Stronger at Clean & Jerk than Snatch"){
    descriptionText.push("Your Snatch is lower than ideal.")
    return sliderDefaultValues.push(70)
  }
  else if(value == "Stronger at Snatch than Clean & Jerk"){
    descriptionText.push("Your Clean & Jerk is lower than ideal.")
    return sliderDefaultValues.push(700)
  }
  else if(value == "Much stronger at Snatch than Clean & Jerk"){
    descriptionText.push("Your Clean & Jerk is much lower than ideal.")
    return sliderDefaultValues.push(900)
  }
  else if(value == "Balanced Snatch and Clean & Jerk"){
    descriptionText.push("Your Snatch and Clean & Jerk are within an ideal range.")
    return sliderDefaultValues.push(500)
  }
}

export function user_report_mapping_back_cl(value, sliderDefaultValues, descriptionText){
  
  if(value == "Much stronger at Clean than Back Squat"){
    descriptionText.push("Your Squat is much lower than ideal.")
    return sliderDefaultValues.push(90)
  }
  else if(value == "Stronger at Clean than Back Squat"){
    descriptionText.push("Your Squat is lower than ideal.")
    return sliderDefaultValues.push(70)
  }
  else if(value == "Stronger at Back Squat than Clean"){
    descriptionText.push("Your Squat strength is more than ideal.")
    return sliderDefaultValues.push(700)
  }
  else if(value == "Much stronger at Back Squat than Clean"){
    descriptionText.push("Your Squat strength is much more than ideal.")
    return sliderDefaultValues.push(900)
  }
  else if(value == "Balanced Back Squat and Clean"){
    descriptionText.push("Your Squat strength is in an ideal range.")
    return sliderDefaultValues.push(500)
  }
}

export function user_report_mapping_strict_press_jerk(value, sliderDefaultValues, descriptionText){
  console.log()
  if(value == "Much stronger at Jerk than Strict Press"){
    descriptionText.push("Your Strict Press is much lower than ideal.")
    return sliderDefaultValues.push(90)
  }
  else if(value == "Stronger at Jerk than Strict Press"){
    descriptionText.push("Your Strict Press is lower than ideal.")
    return sliderDefaultValues.push(70)
  }
  else if(value == "Stronger at Strict Press than Jerk"){
    descriptionText.push("Your Jerk is is lower than ideal.")
    return sliderDefaultValues.push(700)
  }
  else if(value == "Much stronger at Strict Press than Jerk"){
    descriptionText.push("Your Jerk is much lower than ideal.")
    return sliderDefaultValues.push(900)
  }
  else if(value == "Balanced Strict Press and Jerk"){
    descriptionText.push("Your Strict Press and Jerk are within an ideal range.")
    return sliderDefaultValues.push(500)
  }
}

export function user_report_mapping_power_snatch_snatch(value, sliderDefaultValues, descriptionText){
  
  if(value == "Much stronger at Snatch than Power Snatch"){
    descriptionText.push("Your Power Snatch is much lower than ideal.")
    return sliderDefaultValues.push(90)
  }
  else if(value == "Stronger at Snatch than Power Snatch"){
    descriptionText.push("Your Power Snatch is lower than ideal.")
    return sliderDefaultValues.push(70)
  }
  else if(value == "Stronger at Power Snatch than Snatch"){
    descriptionText.push("Your Snatch is lower than ideal.")
    return sliderDefaultValues.push(700)
  }
  else if(value == "Much stronger at Power Snatch than Snatch"){
    descriptionText.push("Your Snatch is much lower than ideal.")
    return sliderDefaultValues.push(900)
  }
  else if(value == "Balanced Power Snatch and Snatch"){
    descriptionText.push("Your Power Snatch and Snatch are within an ideal range.")
    return sliderDefaultValues.push(500)
  }
}

export function user_report_mapping_power_clean_clean(value, sliderDefaultValues, descriptionText){
  
  if(value == "Much stronger at Clean than Power Clean"){
    descriptionText.push("Your Power Clean is much lower than ideal.")
    return sliderDefaultValues.push(90)
  }
  else if(value == "Stronger at Clean than Power Clean"){
    descriptionText.push("Your Power Clean is lower than ideal.")
    return sliderDefaultValues.push(70)
  }
  else if(value == "Stronger at Power Clean than Clean"){
    descriptionText.push("Your Clean is lower than ideal.")
    return sliderDefaultValues.push(700)
  }
  else if(value == "Much stronger at Power Clean than Clean"){
    descriptionText.push("Your Clean is much lower than ideal.")
    return sliderDefaultValues.push(900)
  }
  else if(value == "Balanced Power Clean and Clean"){
    descriptionText.push("Your Power Clean and Clean are within an ideal range.")
    return sliderDefaultValues.push(500)
  }
}

export function user_report_mapping_jerk_clean(value, sliderDefaultValues, descriptionText){
  
  if(value == "Much stronger at Jerk than Clean"){
    descriptionText.push("Your Clean is much lower than ideal.")
    return sliderDefaultValues.push(90)
  }
  else if(value == "Stronger at Jerk than Clean"){
    descriptionText.push("Your Clean is lower than ideal.")
    return sliderDefaultValues.push(70)
  }
  else if(value == "Stronger at Clean than Jerk"){
    descriptionText.push("Your Jerk is lower than ideal.")
    return sliderDefaultValues.push(700)
  }
  else if(value == "Much stronger at Clean than Jerk"){
    descriptionText.push("Your Jerk is much lower than ideal.")
    return sliderDefaultValues.push(900)
  }
  else if(value == "Balanced Jerk and Clean"){
    descriptionText.push("Your Clean and Jerk are within an ideal range.")
    return sliderDefaultValues.push(500)
  }
}