import React from 'react';
import { Auth } from "aws-amplify";
import { styles } from "./styles";
import { withRouter } from "react-router-dom";
import { withStyles, AppBar, Tabs, Tab } from "@material-ui/core";

import Dashboard from './tabs/dashboard'
import Profiler from './tabs/profiler'
import Codes from './tabs/codes'

import { modifyProfileStats } from './api'

class _Admin extends React.Component {
  state = {
    tab: 'profiler',
    profilerText: 'hello',
    profiles: [],
    profileResults: [],
    bannedPMGs: ["accessory"],
    selected: null,
    selectColor: ''
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search)
    const tab = urlParams.get('tab')

    if (tab) { this.setState({ tab }) }

    this.setState({ loading: true })

    await Auth.currentSession().then((value) => {
      const payload = value.accessToken.payload
      const groups = payload['cognito:groups'] ? payload['cognito:groups'] : []
      if (!groups.includes('SUPERADMINS')) {
        return this.props.history.push("/workouts");
      }
    })

    const data = require('./data/default-profiles.json');
    this.setState({ profilerText: JSON.stringify(data), profiles: data });

    let testResponse = require('./data/test-response.json');
    testResponse = modifyProfileStats(testResponse)
    this.setState({ profileResults: testResponse });

  }

  async componentWillUnmount() {
    console.log('unmounting admin!!')
  }

  handlePageChange = (event, page) => {
    this.setState({ tab: page });
  };


  render() {
    const { classes, userData } = this.props;
    const { tab } = this.state;

    if (!this.props.match.isExact) {
      return null
    } else {
      return (
        <div className={classes.container}>
          <div className={classes.toggleGroup}>
            <AppBar
              position="static"
              color="default"
              className={classes.appBarShit}
            >
              <Tabs
                value={tab}
                onChange={this.handlePageChange}
                indicatorColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  key={1}
                  value="users"
                  label="Users"
                />
                <Tab
                  key={2}
                  value="profiler"
                  label="Profiler"
                />
                <Tab
                  key={2}
                  value="codes"
                  label="Codes"
                />
              </Tabs>
            </AppBar>
          </div>
          <div className={classes.content}>
            {tab === 'users' && <Dashboard parent={this} />}
            {tab === 'profiler' && <Profiler parent={this} />}
            {tab === 'codes' && <Codes parent={this} />}
          </div>
        </div>
      );
    }
  }
}

export default withRouter(withStyles(styles)(_Admin));;