import { connect } from 'react-redux';
import { updateUserData, fetchUserData } from '../../../../state/thunk';
import { toggleLoading } from '../../../../state/actions';
import _Profile from '../../../profile/layout'

const mapStateToProps = ({ userReducer }) => ({
  userData: userReducer,
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: (userName, sudo) => dispatch(fetchUserData(userName, sudo)),
  updateUserData: (pl) => dispatch(updateUserData(pl)),
  toggleLoading: () => dispatch(toggleLoading()),
});

const CoachProfile = connect(mapStateToProps, mapDispatchToProps)(_Profile);
export default CoachProfile;