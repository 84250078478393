export const styles = () => ({
  hero: {
    height: `100vh`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  heroWH: {
    height: `100vh`,
    backgroundColor: '#002141'
  },
  logoContainer: {
    width: '80%',
    maxWidth: '280px',
    marginBottom: 30
  },
  logo: {
    width: '100%'
  },
  heroOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.35)',
    zIndex: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    maxWidth: 600,
    width: '100%',
    color: '#fff',
    fontFamily: 'Jura',
    fontSize: 24,
    letterSpacing: 1.15,
    fontWeight: 600,
    margin: 20
  },
  error: {
    color: "red",
    textAlign: "center",
  },
  callToActionContainer: {
    width: 300,
    marginTop: 10
  },
  formButton: {
    backgroundColor: '#016FB9',
    color: '#fff',
    width: '100%',
    height: 60,
    '&:hover': {
      backgroundColor: '#60859e'
    }
  },
  onboardRoot: {
    height: 60,
    width: 300,
    backgroundColor: 'rgba(0,0,0,.3)',
    border: '1px solid #fff',
    borderRadius: 30,
    fontFamily: 'Jura',
    fontWeight: 900,
    fontSize: '28px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,.5)'
    }
  },
  onboardLabel: {
    color: '#fff',
    fontSize: 22,
    letterSpacing: 1.35,
    fontFamily: 'Watchword'
  },
});
