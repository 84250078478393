import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';
import { styles } from './styles';
import { updateLangCode } from '../../state/thunk';

const _LanguageToggle = ({
  userData,
  languages,
  activeLanguage,
  setActiveLanguage,
  updateLanguageCode,
  classes,
}) => {
  useEffect(() => {
    if (userData && userData.lang_code) {
      // bug somewhere in the data gave some people "ch" lang codes, which
      // breaks everything, so this is to fix that.
      var lang_code = userData.lang_code
      lang_code = lang_code === 'ch' ? '中文' : lang_code
      lang_code = lang_code === 'en' ? 'ENG' : lang_code

      setActiveLanguage(lang_code);
    }
  });

  const handleLanguageChange = (lang_code) => {
    setActiveLanguage(lang_code);
    // I think this is what the payload for update user looks like, but this may 
    // Need to get updated.
    console.log("lang_code:", lang_code)
    const userPayLoad = {
      lang_code,
      cognito_id: userData.cognito_id,
      height: userData.height,
      bodyweight: userData.bodyweight,
      birthday: userData.birthday,
      email: userData.email,
      male: userData.male,
    };

    updateLanguageCode(userPayLoad);
  };

  if (window.location.hostname.includes('liliang')) {
    return (
      <FormControl variant="standard">
        <Select
          id="language_select"
          value={activeLanguage ? activeLanguage.code : ''}
          onChange={(lang) => handleLanguageChange(lang.target.value)}
          className={classes.select}
          disableUnderline={true}
          style={{ zIndex: 500 }}
          classes={{
            root: classes.select,
            icon: classes.select,
          }}
          inputProps={{
            classes: {
              root: classes.select,
            },
          }}
        >
          {languages.map((lang) => (
            <MenuItem key={lang.code} value={lang.code} id={lang.code}>
              {lang.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  } else {
    return null
  }
};

const LanguageToggle = withStyles(styles)(withLocalize(_LanguageToggle));

const mapStateToProps = ({ userReducer }) => ({ userData: userReducer });

const mapDispatchToProps = (dispatch) => ({
  updateLanguageCode: (lang_code) => dispatch(updateLangCode(lang_code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageToggle);