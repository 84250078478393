import React from "react";
import { useTable } from 'react-table'
import Link from '@mui/material/Link';
import { deleteTeam } from '../api'

const renderText = (col, cell) => {
  let text = col !== 'Last login' ? cell.value : cell.value.split('.')[0]

  if (col !== 'Last login') {
    return (
      <div>
        {text}
      </div>
    )
  } else {
    return text
  }
}

const clickButtons = (cell, parent, handleDeleteTeam) => {
  let team_name = cell.row.values.team_name

  return (
    <td
      {...cell.getCellProps()}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        margin: 0,
        padding: 3,
        fontSize: 13,
        backgroundColor: 'white',
        textAlign: "center",
        height: '100%'
      }}
      id={cell.value + 'actions'}
    >
      <Link onClick={() => handleDeleteTeam(team_name)} id={`${team_name}_delete`}>
        Delete
      </Link>
    </td >
  )
}

const TeamTable = ({ parent, columns, data, handleDeleteTeam }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({ columns, data })

  return (
    <div style={{ display: 'flex', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
      <table {...getTableProps()} style={{ border: 'solid 1px blue', width: '90%', backgroundColor: 'rgb(0, 33, 65)' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    border: 'solid 0.3px gray',
                    background: 'aliceblue',
                    color: 'black',
                    fontWeight: 'bold',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} style={{ backgroundColor: 'white' }}>
                {row.cells.map(cell => {
                  let col = cell.column.Header
                  let textAlign = { 'Email': 'left', 'Cognito': 'center', 'Last login': 'center' }

                  console.log('cell:', cell)

                  if (col === "Actions") {
                    return clickButtons(cell, parent, handleDeleteTeam)
                  } else {
                    return (
                      <td
                        {...cell.getCellProps()}
                        style={{
                          margin: 0,
                          padding: 3,
                          fontSize: 13,
                          backgroundColor: 'white',
                          textAlign: textAlign[col]
                        }}
                        id={`${cell.column.id}_${row.values.team_name}`}
                      >
                        {renderText(col, cell)}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

export { TeamTable };