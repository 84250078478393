import React, { useState, Component } from "react";
import { connect } from "react-redux";
import { getTranslate } from "react-localize-redux";
import { Auth } from "aws-amplify";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import { postXMonthPurchase, getStripeLink } from "../../profile/api";
import {
  PromoBox,
  SubscriptionTierPurchase,
  RenderTermsAccept,
} from "../../../components/Form/paymentForm";
import mixpanel from "mixpanel-browser";
import Checkbox from "@mui/material/Checkbox";
import CountdownTimer from '../../../components/Form/countdownTimer';
import Testimonials from '../../../components/Form/testimonials';

export class OnboardingPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      isAuth: false,
      modalOpen: false,
      monthType: "",
    };
  }

  async componentDidMount() {
    const sessionRes = await Auth.currentSession();
    console.log("sessionRes", sessionRes);
    const cognito_id = sessionRes.accessToken.payload.sub;

    const isAuth = sessionRes.accessToken ? true : false;
    this.setState({ isAuth, cognito_id });

    mixpanel.track(`render onboarding payment`);
  }

  _DialogLoading = () => {
    return (
      <>
        <DialogTitle> Loading </DialogTitle>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingBottom: 20,
          }}
        >
          <CircularProgress size={25} color="inherit" />
        </DialogContent>
      </>
    );
  };

  _DialogContentMonthly = (
    saleData,
    hasSubscribed,
    webSignup,
    setIsLoading
  ) => {
    var { parent } = this.props;

    return (
      <div>
        <DialogTitle>Confirm purchase</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Click yes to purchase a month of credit.</p>
            <p>
              If you have a billing method on file, it will be charged
              immediately.
            </p>
            <p>
              If you do not, you will be brought to a page to register a billing
              method.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ modalOpen: false, monthType: "" })}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              setIsLoading(true);
              if (hasSubscribed && webSignup) {
                postXMonthPurchase(parent, 1, saleData["percent"]);
              } else {
                getStripeLink(parent, 1, saleData["percent"], hasSubscribed);
              }
            }}
            id="yesButton"
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    );
  };

  _DialogContentYearly = (saleData, hasSubscribed, webSignup, setIsLoading) => {
    var { parent } = this.props;

    return (
      <div>
        <DialogTitle>Confirm purchase</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p>Click yes to purchase a year of credit.</p>
            <p>
              If you have a billing method on file, it will be charged
              immediately.
            </p>
            <p>
              If you do not, you will be brought to a page to register a billing
              method.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => this.setState({ modalOpen: false, monthType: "" })}
            autoFocus
          >
            No
          </Button>
          <Button
            onClick={(e) => {
              setIsLoading(true);
              console.log(
                hasSubscribed && webSignup
                  ? "postXMonthPurchase"
                  : "getStripeLink"
              );
              if (hasSubscribed && webSignup) {
                postXMonthPurchase(parent, 12, saleData["percent"]);
              } else {
                getStripeLink(parent, 12, saleData["percent"], hasSubscribed);
              }
            }}
            id="yesButton"
          >
            Yes
          </Button>
        </DialogActions>
      </div>
    );
  };

  _renderDialogContent = (saleData, hasSubscribed, webSignup) => {
    const [isLoading, setIsLoading] = useState(false);
    let dialogType = this.state.monthType;

    if (isLoading) {
      return this._DialogLoading();
    } else if (dialogType === "cancel") {
      return this._DialogContentCancel();
    } else if (dialogType === "1MonthPurchase") {
      return this._DialogContentMonthly(
        saleData,
        hasSubscribed,
        webSignup,
        setIsLoading
      );
    } else if (dialogType === "12MonthPurchase") {
      return this._DialogContentYearly(
        saleData,
        hasSubscribed,
        webSignup,
        setIsLoading
      );
    }
  };

  _renderDialog = (saleData, hasSubscribed, webSignup) => {
    return (
      <Dialog
        open={this.state.modalOpen}
        onClose={() => this.setState({ modalOpen: false })}
      >
        {this._renderDialogContent(saleData, hasSubscribed, webSignup)}
      </Dialog>
    );
  };

  render() {
    const { classes, prevStep, parent } = this.props;

    return (
      <>
      <CountdownTimer />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <SubscriptionTierPurchase parent={parent} modalControl={this} />
        <RenderTermsAccept classes={classes} parent={parent} />
        <PromoBox parent={parent} />
        <Testimonials />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ localize }) => ({
  translate: getTranslate(localize),
});

export default connect(mapStateToProps)(OnboardingPayment);
