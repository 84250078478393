export const styles = () => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    paddingTop: 70,
    flexDirection: 'column'
  },
  card: {
    display: 'flex',
    margin: 15,
    boxShadow: "4px 6px 12px 2px #dadada",
    padding: 20,
    borderRadius: 5,
    justifyContent: 'space-between'
  },
  newProgramCard: {
    display: 'flex',
    margin: 15,
    backgroundColor: 'green',
    color: 'white',
    boxShadow: "4px 6px 12px 2px #dadada",
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 5,
  },
  newProgramCardContent: {
    display: 'flex',
    padding: 16,
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
