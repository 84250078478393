import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloseIcon from "@material-ui/icons/Close";
import List from "@material-ui/core/List";
import componentResults from "./Results";
import ListItem from "@material-ui/core/ListItem";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { sendResults } from "../api";
import Slide from "@material-ui/core/Slide";
import { Translate } from "react-localize-redux";

function calcAccordionClass(this_movement, isActive, classes) {
  if (isActive) {
    return classes.cardActive;
  } else {
    if (this_movement.movement_completed === true) {
      return classes.cardInactiveWritten;
    } else if (this_movement.movement_written) {
      return classes.cardInactivePartiallyWritten;
    } else {
      return classes.cardInactiveUnwritten;
    }
  }
}

function swapMovement(this_movement, indices, parent) {
  const move_name = this_movement.movement_name.en;
  const options = this_movement.options;

  for (const opt in options) {
    const option = options[opt];
    if (move_name === option.name.en) {
      const sel_opt = options[(parseInt(opt) + 1) % options.length];

      console.log("new movement:", sel_opt);

      this_movement.movement_name = sel_opt.name;
      this_movement.tags = sel_opt.tags ? sel_opt.tags : [];
      this_movement.movement_note = sel_opt.movement_note
        ? sel_opt.movement_note
        : { en: "" };
      this_movement.set_size = sel_opt.reps;

      for (const idx in this_movement.sets) {
        var set = this_movement.sets[idx];

        if (set.written !== true) {
          this_movement.sets[idx].weight = sel_opt.weight;
          this_movement.sets[idx].movement_name = sel_opt.name;
          this_movement.sets[idx].reps = sel_opt.reps;
        }
      }

      console.log(this_movement);

      var items = parent.state.items;
      items[indices[0]].days[indices[1]].movements[indices[2]] = this_movement;

      parent.setState({
        items,
        changedMovement: this_movement.movement_id,
        changedMovementFadeIn: true,
        changedMovementNewName: this_movement.movement_name.en,
        newMovementOption: (parseInt(opt) + 1) % options.length,
        oldMovementOption: parseInt(opt),
      });

      setTimeout(function () {
        var move =
          parent.state.items[indices[0]].days[indices[1]].movements[indices[2]];

        if (move.movement_name.en === sel_opt.name.en) {
          this_movement.cognito_id = parent.state.cognito_id;
          this_movement.program_id = parent.state.program_id;

          sendResults(this_movement, null, parent).then((e) => {
            console.log("result:", e);
          });
        }
      }, 2000);
    }
  }
}

function MovementNote(this_movement, lang, classes) {
  if (this_movement.movement_note) {
    const rawHTML = this_movement.movement_note[lang];

    return (
      <div
        dangerouslySetInnerHTML={{
          __html: rawHTML,
        }}
      />
    );
  }
}

function swapMovementIcon(this_movement, classes, parent, indices) {
  if (this_movement.options && this_movement.options.length > 1) {
    return (
      <div
        className={classes.individualIconContainer}
        name="swapButton"
        id={this_movement.movement_id + "_swap"}
        onClick={() => {
          swapMovement(this_movement, indices, parent);
        }}
      >
        <MenuOpenIcon />
        <div className={classes.individualIconText}>
          <Translate id="swap" />
        </div>
      </div>
    );
  }
}

function MovementNoteContainer(
  this_movement,
  move_after,
  parent,
  indices,
  lang,
  classes
) {
  return (
    <ListItem className={classes.movementNoteContainer}>
      <div className={classes.movementIcons}>
        {swapMovementIcon(this_movement, classes, parent, indices)}
      </div>
    </ListItem>
  );
}

function handleClick(this_movement, movement_id, parent) {
  // console.log('handling click for movement_id', movement_id);
  // console.log('handling click for this_movement', this_movement);
  if (!parent.state.programStartsInFuture) {
    var currentMovement = parent.state.expandedMovement;
    var opening = movement_id !== currentMovement;

    var newMovementOption = null;
    if (Object.keys(this_movement).includes("options")) {
      const options = this_movement.options.map((o) => o.name.en);
      newMovementOption = options.indexOf(this_movement.movement_name.en);
    }

    var expandedMovement = opening ? movement_id : "";
    var changedMovement = "";

    parent.setState({ expandedMovement, changedMovement, newMovementOption });
  }
}

function AccessoryDivider(this_movement, next_movement, isActive, classes) {
  if (
    this_movement.accessory === false &&
    next_movement.accessory &&
    !isActive
  ) {
    return <div className={classes.accessoryDivider} />;
  }
}

function coachRepSchemeData(movement) {
  if (movement.sets.length > 0) {
    return (
      <div style={{ display: "flex", gap: 10 }}>
        <div style={{ display: "flex" }}>{movement.sets.length} sets</div>
        <div style={{ display: "flex" }}>{movement.sets[0].reps} reps</div>
        <div style={{ display: "flex" }}>
          {Math.round(movement.baseline_intensity * 100)}%
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function coachTagObject(text) {
  return (
    <div
      style={{
        borderStyle: "solid",
        borderColor: "gray",
        borderRadius: 5,
        padding: 3,
        fontSize: 10,
      }}
    >
      {text}
    </div>
  );
}

function coachTagData(movement) {
  return (
    <div style={{ display: "flex", gap: 10, marginLeft: 20, marginTop: -10 }}>
      {movement.tags.map((tag) => coachTagObject(tag))}
      {movement.written_utc &&
        coachTagObject(`Written: ${movement.written_utc.split(".")[0]}`)}
    </div>
  );
}

function componentMovementsContent(classes, day, indices, parent, option) {
  var state = parent.state;
  var this_movement = day.movements[indices[2]];
  var move_after = day.movements[parseInt(indices[2]) + 1];
  const coach = state.coach;
  const isActive = state.expandedMovement === this_movement.movement_id;
  const isLoading = state.loadingMovement === this_movement.movement_id;
  const lang = parent.props.activeLanguage.tag;
  const m_id = isActive
    ? this_movement.movement_id + this_movement.movement_name.en
    : this_movement.movement_id;

  return (
    <div
      className={classes.exercise}
      key={this_movement.movement_id}
      id={m_id}
      style={{ position: "relative" }}
      name="movement_item"
    >
      <Accordion
        expanded={isActive}
        onChange={() =>
          handleClick(this_movement, this_movement.movement_id, parent)
        }
        className={calcAccordionClass(this_movement, isActive, classes)}
        TransitionProps={{ unmountOnExit: true, timeout: 25 }}
      >
        <AccordionSummary
          id={this_movement.movement_id + "_movement_name"}
          name={
            isActive
              ? "active_movement_name"
              : this_movement.movement_id + "_movement_name"
          }
          expandIcon={
            isLoading ? (
              <div className={classes.loading}>
                <CircularProgress size={19} color="inherit" />
              </div>
            ) : isActive ? (
              <CloseIcon />
            ) : (
              <ExpandMoreIcon />
            )
          }
          className={
            isActive
              ? classes.movementNameActive
              : this_movement.movement_completed
              ? classes.movementNameInactive
              : classes.movementNameInactiveUnwritten
          }
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex" }}>
              {option ? option.name[lang] : this_movement.movement_name[lang]}
            </div>
            {coach && coachRepSchemeData(this_movement)}
          </div>
        </AccordionSummary>
        <AccordionDetails className={classes.movementAccordionDetail}>
          <List
            component="div"
            dense={true}
            disablePadding
            className={classes.movementList}
          >
            {MovementNoteContainer(
              this_movement,
              move_after,
              parent,
              indices,
              lang,
              classes
            )}
            {Object.keys(this_movement.sets).map((set_index) =>
              componentResults(
                classes,
                this_movement,
                handleClick,
                indices.concat([set_index]),
                parent
              )
            )}
            <ListItem
              key={1}
              selected={false}
              className={classes.addResultRow}
            />
          </List>
        </AccordionDetails>
      </Accordion>
      {coach && coachTagData(this_movement)}
      {AccessoryDivider(this_movement, move_after, isActive, classes)}
    </div>
  );
}

function getExpandedCards(classes, day, indices, parent, this_movement) {
  if (Object.keys(this_movement).includes("options")) {
    return this_movement.options.map((option) => {
      const optionIndex = this_movement.options.indexOf(option);
      var newMovementOption = parent.state.newMovementOption;
      var oldMovementOption = parent.state.oldMovementOption;
      var enterTimeout = parent.state.changedMovement.length ? 600 : 0;

      return (
        <div
          key={this_movement.movement_id + option.name.en + "g"}
          style={{ position: "static" }}
        >
          <Slide
            in={newMovementOption === optionIndex}
            timeout={{ enter: enterTimeout, exit: 200 }}
            direction={newMovementOption === optionIndex ? "left" : "right"}
            style={{
              zIndex: 1000,
              position: "absolute",
              width: "100%",
              marginTop: "-10px",
            }}
            hidden={
              ![newMovementOption, oldMovementOption].includes(optionIndex)
            }
            key={this_movement.movement_id + option.name.en}
            id={this_movement.movement_id}
          >
            <div>
              {componentMovementsContent(classes, day, indices, parent, option)}
            </div>
          </Slide>
        </div>
      );
    });
  } else {
    return componentMovementsContent(classes, day, indices, parent);
  }
}

function componentMovements(classes, day, indices, parent) {
  var state = parent.state;
  var this_movement = day.movements[indices[2]];
  const isActive = state.expandedMovement === this_movement.movement_id;

  if (isActive) {
    return (
      <div
        key={this_movement.movement_id + "f"}
        style={{ position: "static", height: "53px" }}
      >
        {getExpandedCards(classes, day, indices, parent, this_movement)}
        <Backdrop
          className={classes.backdrop}
          open={state.expandedMovement === this_movement.movement_id}
        />
      </div>
    );
  } else {
    return componentMovementsContent(classes, day, indices, parent);
  }
}

export default componentMovements;
