import axios from 'axios';
import { Auth } from 'aws-amplify';
import pako from 'pako'

const usersURL = `${process.env.REACT_APP_BACKEND_ENDPOINT}/api/users`

const getConfig = async () => {
  const date = new Date()
  const local_date = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${(date.getDate()).toString().padStart(2, '0')}`

  var authToken = await (await Auth.currentSession()).idToken.jwtToken;

  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': authToken
    },
    params: { 'local_date': local_date }
  };
}


// GET
export const makeUserRequest = async (cognito_id) => {
  const config = await getConfig()

  let url = usersURL.concat(`/${cognito_id}`) + `?source=web`

  return axios
    .get(url, config)
    .then(data => {
      // console.log('very raw makeRequest data:', data.data);
      return data.data })
    .catch(error => console.log(error));
};

export const makeMovementRequest = async (cognito_id) => {
  const movementsConfig = await getConfig()
  let movementsUrl = usersURL.concat(`/${cognito_id}`) + `?source=web&get_object=movements`

  movementsConfig.headers['Content-Type'] = 'application/gzip'
  movementsConfig['responseType'] = 'arraybuffer'
  movementsConfig['decompress'] = false

  return axios
    .get(movementsUrl, movementsConfig)
    .then(data => {
      // console.log('raw movement data:', data)
      let d = Buffer.from(data.data, 'base64');
      d = JSON.parse(pako.inflate(d, { to: 'string' }));
      // console.log('movements data decoded:', d)
      return d
    })
    .catch(error => console.log('movements data error:', error));
}

// PUT
export const updateUser = async (payload) => {
  const config = await getConfig()

  payload.source = 'web'

  return axios
    .put(usersURL.concat(`/${payload.cognito_id}`), payload, config)
    .then(data => { return data.data })
    .catch(error => { throw error });
};
