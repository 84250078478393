import { connect } from 'react-redux';
import _Workouts from './layout';
import { fetchUserData, updateLastAppPopup } from '../../state/thunk';
import { setMovementsLibrary } from '../../state/actions'

const mapStateToProps = ({ userReducer }) => ({
  purchased: userReducer.program_id,
  cognito_id: userReducer.cognito_id,
  userName: userReducer.userName,
  lastAppPopup: userReducer.lastAppPopup
});

const mapDispatchToProps = (dispatch) => ({
  fetchUserData: (userName, sudo) => dispatch(fetchUserData(userName, sudo)),
  updateLastAppPopup: (lastAppPopup) => dispatch(updateLastAppPopup(lastAppPopup)),
  updateMovementsLibrary: (library) => dispatch(setMovementsLibrary(library))
});

export const Workouts = connect(mapStateToProps, mapDispatchToProps)(_Workouts);
