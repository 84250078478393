import React from "react";
import { useTable, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { CSVLink } from "react-csv";
import NativeSelect from '@mui/material/NativeSelect';
import Link from '@mui/material/Link';
import { confirmTeam } from '../../api'
import { deleteTeam } from '../../../profile/api'
import { searchUsers } from '../../api'
import { Backdrop, CircularProgress } from "@material-ui/core";

const sleep = (ms) => { return new Promise(resolve => setTimeout(resolve, ms)); }

const todayDate = () => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  return `${yyyy}-${mm}-${dd}`
}

const searchWrapper = async (searchTerm, parent) => {
  searchTerm = searchTerm.trim()
  let searchTime = Date.now()
  parent.setState({ searchTime })

  await sleep(1000)

  if (parent.state.searchTime === searchTime) {
    if (searchTerm !== "") {
      parent.setState({ loading: true })
      searchUsers(searchTerm).then(data => {
        parent.setState({
          rows: data ? data : [],
          loading: false,
        })
      })
    } else {
      parent.setState({
        rows: [],
        loading: false,
      })
    }
  }
}

// Define a default UI for filtering
function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, parent }) {
  const count = preGlobalFilteredRows.length
  const [value, setValue] = React.useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    let selectedTeam = parent.state.selectedTeam

    if (selectedTeam && selectedTeam !== 'Weightlifting.ai') {
      // if this is a coach situation, we will search what we have
      setGlobalFilter(value || undefined)
    } else {
      // otherwise this is an admin situation, we hit the api
      searchWrapper(value, parent)
    }
  }, 200)

  return (
    <div style={{ marginLeft: 10 }}>
      Search:{' '}
      <input
        id="search_box"
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: '1.1rem',
          margin: 7,
          backgroundColor: 'white'
        }}
      />
    </div>
  )
}

const renderText = (col, cell) => {
  let text = col !== 'Last login' ? cell.value : cell.value.split('.')[0]

  if (col !== 'Last login') {
    return (
      <div>
        {text}
      </div>
    )
  } else {
    return text
  }
}

const clickButtons = (cell, coachMode, parent) => {
  let cognito = cell.row.values.cognito_id
  let email = cell.row.values.email
  let status = cell.row.values.team_status

  if (status === 'pending' && coachMode) {
    return (
      <td
        {...cell.getCellProps()}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          margin: 0,
          padding: 3,
          fontSize: 13,
          backgroundColor: 'white',
          textAlign: "center",
          height: '100%'
        }}
        id={cell.value + 'actions'}
      >
        <div
          style={{
            backgroundColor: 'green',
            padding: 5,
            paddingTop: 2,
            paddingBottom: 2,
            borderRadius: 5,
            color: 'white',
            fontWeight: '700',
            fontSize: 12,
            textTransform: 'uppercase',
            margin: 1,
            marginRight: 2
          }}
          id={`${email}_accept`}
          onClick={() => { parent.setState({ loading: true }); confirmTeam(cognito) }}
        >
          ACCEPT
        </div>
        <div style={{
          backgroundColor: 'red',
          padding: 5,
          paddingTop: 2,
          paddingBottom: 2,
          borderRadius: 5,
          color: 'white',
          fontWeight: '700',
          fontSize: 12,
          textTransform: 'uppercase',
          margin: 1
        }}
          onClick={() => { parent.setState({ loading: true }); deleteTeam({ state: { cognito_id: cognito } }) }}
          id={`${email}_reject`}
        >
          REJECT
        </div>

      </td >
    )
  } else {
    return (
      <td
        {...cell.getCellProps()}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          margin: 0,
          padding: 3,
          fontSize: 13,
          backgroundColor: 'white',
          textAlign: "center",
          height: '100%'
        }}
        id={cell.value + 'actions'}
      >
        <div>
          <Link href={`/coach/profile/${cognito}`} target="_blank" id={`${email}_profile`}>Profile</Link>
        </div>
        |
        <div>
          <Link href={`/workouts?cognito_id=${cognito}&showAll=false`} target="_blank" id={`${email}_workouts`}>Workouts</Link>
        </div>
      </td >
    )
  }
}

const RenderBody = (getTableBodyProps, rows, prepareRow, coachMode, parent) => {

  if (parent.state.loading) {
    return (
      <Backdrop open={true} invisible={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else {
    return (
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()} style={{ backgroundColor: 'white' }}>
              {row.cells.map(cell => {
                let col = cell.column.Header
                let textAlign = { 'Email': 'left', 'Cognito': 'center', 'Last login': 'center' }

                if (col === "Actions") {
                  return clickButtons(cell, coachMode, parent)
                } else {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        margin: 0,
                        padding: 3,
                        fontSize: 13,
                        backgroundColor: 'white',
                        textAlign: textAlign[col]
                      }}
                      id={cell.value}
                    >
                      {renderText(col, cell)}
                    </td>
                  )
                }
              })}
            </tr>
          )
        })}
      </tbody>
    )
  }
}

const RenderTable = (getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, coachMode, parent) => {
  return (
    <table {...getTableProps()} style={{ border: 'solid 1px blue', width: '100%', backgroundColor: 'rgb(0, 33, 65)' }}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th
                {...column.getHeaderProps()}
                style={{
                  border: 'solid 0.3px gray',
                  background: 'aliceblue',
                  color: 'black',
                  fontWeight: 'bold',
                }}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {RenderBody(getTableBodyProps, rows, prepareRow, coachMode, parent)}
    </table>
  )
}

function UserTable({ parent, data, columns, csvData, handleSelectChange }) {
  let selectedTeam = parent.state.selectedTeam
  let leadingTeam = parent.state.leadingTeams.includes(selectedTeam)
  let coachMode = selectedTeam !== 'Weightlifting.ai' // as opposed to admin mode
  let showManageTeam = leadingTeam && coachMode

  let initialState = { hiddenColumns: coachMode ? ['cognito_id', 'team_status'] : ['team_status'] }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable({ columns, data, initialState }, useGlobalFilter)


  return (
    <div style={{ display: 'flex', width: '100%', marginTop: 64, flexDirection: 'column', alignItems: 'flex-start' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            parent={parent}
          />
          <NativeSelect
            value={parent.state.selectedTeam}
            label="Selected team"
            id="selectTeam"
            disableUnderline={true}
            style={{ marginLeft: 10 }}
            onChange={(e) => handleSelectChange(e)}
          >
            {parent.state.viewableTeams.map(team => <option value={team} key={team}>{team}</option>)}
          </NativeSelect>
          {parent.state.admin && <div style={{ margin: 'auto', marginLeft: 20 }}>
            Customers: {parent.state.customers}
          </div>}
          {parent.state.admin && parent.state.promos && <div style={{ margin: 'auto', marginLeft: 20 }}>
            Promo users: {parent.state.promos}
          </div>}
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', paddingRight: 20 }}>
          <div style={{ paddingRight: 20 }}>
            {!coachMode && <Link href={`/manageTeams`} target="_blank" id="manage_teams">Manage Teams</Link>}
            {showManageTeam && <Link href={`/manageTeam?team=${encodeURI(selectedTeam)}`} target="_blank" id="manage_team">Manage Team</Link>}
          </div>
          <div>
            {parent.state.admin && <CSVLink data={csvData} filename={`WLAI_${todayDate()}.csv`}>Download CSV</CSVLink>}
          </div>
        </div>
      </div>
      {RenderTable(getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, coachMode, parent)}
    </div>
  )
}

export { UserTable };