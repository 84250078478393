import React from 'react';
import { Button, withStyles } from "@material-ui/core";
import "date-fns";

import { styles } from "./styles";
import { withRouter } from "react-router";
import TextField from '@material-ui/core/TextField';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import DataTable from './holdTable';
import { IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete'

const DisabledField = ({ fieldName, fieldValue }) => {


    return (
        <div style={{ width: 200 }}>
            <TextField
                id={`disabled ${fieldName}`}
                label={fieldName}
                value={fieldValue}
                InputProps={{
                    readOnly: true,
                }}
                variant="outlined"
                disabled
            />
        </div>
    )
}

const SpacerField = () => {
    return (
        <div style={{ width: 200, height: 20 }} />
    )
}


const DropDownSelector = ({ menuPairs, label, getValue, changeValue }) => {
    return (
        <div style={{ width: 200, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'space-between' }}>
            <InputLabel id={`${label}-label`}>{label}</InputLabel>
            <Select
                labelId={`${label}-label`}
                id={label}
                value={getValue(label)}
                onChange={(event) => changeValue(label, event.target.value)}
                style={{ width: '100%', textAlign: 'start' }}
            >
                {menuPairs.map(item => <MenuItem key={`${item.value}`} value={item.value}>{item.desc}</MenuItem>)}
            </Select>
        </div>
    )
}

const ChangeableField = ({ fieldName, fieldValue }) => {
    return (
        <div style={{ width: 200 }}>
            <TextField
                id={`changeable ${fieldName}`}
                label={fieldName}
                value={fieldValue}
                variant="outlined"
            />
        </div>
    )
}

function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}


function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

class CoachProgram extends React.Component {

    constructor(props) {
        super(props)
        this.updateHistory = this.updateHistory.bind(this);
        this.getDropDownValue = this.getDropDownValue.bind(this);
        this.changeDropDownValue = this.changeDropDownValue.bind(this);
        this.daysRemaining = this.daysRemaining.bind(this);
        this.get_micro_offset = this.get_micro_offset.bind(this);
        this.setDeleteIndex = this.setDeleteIndex.bind(this);
    }

    state = {
        hold_history: [],
        paymentRecord: {},
        program_id: "",
        amount: "",
        payment_date: "",
        frequency: "",
        start_date: "",
        on_hold_start_date: "",
        on_hold_end_date: "",
        on_hold: false,
        micro_hold_offset: "",
        fitness_level: "",
        reloadDDV: false,
        PROGRAM_LENGTH: 83, //assuming average program length is 83 days
        deleteIndex: null,
        mostRecentHoldStart: "",
        mostRecentHoldEnd: "",
    };

    componentDidMount() {

        const paymentRecord = this.props.location.state.paymentRecord[0]


        this.setState({
            paymentRecord: paymentRecord,
            hold_history: paymentRecord.hold_history,
            program_id: paymentRecord.program_id,
            amount: paymentRecord.amount,
            payment_date: paymentRecord.payment_date,
            frequency: paymentRecord.frequency,
            start_date: paymentRecord.start_date,
            on_hold_start_date: paymentRecord.on_hold_start_date,
            on_hold_end_date: paymentRecord.on_hold_end_date,
            on_hold: paymentRecord.on_hold,
            micro_hold_offset: this.sumMicroOffset(paymentRecord.hold_history),
            fitness_level: paymentRecord.fitness_level,
            mostRecentHoldStart: this.getMostRecentHold(paymentRecord.hold_history)[0],
            mostRecentHoldEnd: this.getMostRecentHold(paymentRecord.hold_history)[1]

        })
    }

    sumMicroOffset(hold_history) {

        var micro_sum = 0

        for (let hold of hold_history) {
            if (typeof hold.hold_micro_count === 'number') {
                micro_sum += hold.hold_micro_count
            }
        }
        return micro_sum
    }

    //filters out holds with invalid micro counts, and all ids pre write
    prepareHoldHistory(hold_history) {
        const result = []
        const validHistoy = hold_history.filter(item => item.hold_micro_count !== "INVALID")
        for (let item of validHistoy) {
            result.push(Object.create({
                on_hold_start_date: item.on_hold_start_date,
                on_hold_end_date: item.on_hold_end_date, hold_micro_count: item.hold_micro_count
            }))
        }
        return result
    }

    getMostRecentHold(hold_history) {
        if (hold_history.length === 0) {
            return [null, null]
        }

        var mostRecentHold = hold_history[0].on_hold_end_date
        var mostRecentObject = hold_history[0]

        for (let hold of hold_history) {
            if (hold.on_hold_start_date !== "" &&
                hold.hold_micro_count !== "INVALID" &&
                datediff(treatAsUTC(mostRecentHold), treatAsUTC(hold.on_hold_end_date)) >= 0) {
                mostRecentHold = hold.on_hold_end_date
                mostRecentObject = hold
            }
        }
        return [mostRecentObject.on_hold_start_date, mostRecentObject.on_hold_end_date]
    }

    getDropDownValue(name) {
        switch (name) {
            case "fitness":
                return this.state.fitness_level
            case "hold":
                return this.state.on_hold
            case "reload":
                return this.state.reloadDDV
            default:
                return null
        }
    }

    changeDropDownValue(name, value) {
        switch (name) {
            case "fitness":
                this.setState({
                    fitness_level: value
                })
                return
            case "hold":
                this.setState({
                    on_hold: value
                })
                return
            case "reload":
                this.setState({
                    reloadDDV: value

                })
                return
            default:
                console.log("drop down name not recognized,hitting default state")
                return null
        }
    }

    getThisMonday = () => {
        var nm = new Date();
        nm.setDate(
            nm.getDate() + ((1 - nm.getDay()) % 7)
        );
        return `${nm.getFullYear()}-${(nm.getMonth() + 1).toString().padStart(2, '0')}-${(nm.getDate()).toString().padStart(2, '0')}`
    };

    daysRemaining() {
        return datediff(treatAsUTC(new Date(this.state.start_date)), treatAsUTC(new Date()))
    }

    get_micro_offset(start, end) {
        return Math.round(Math.ceil(datediff(treatAsUTC(new Date(start)), treatAsUTC(end)) / 7))
    }

    isValidHistoryField(date) {
        const dateRegex = /\d{4}-\d{2}-\d{2}/
        return dateRegex.test(date)
    }

    setDeleteIndex(index) {
        this.setState({
            deleteIndex: index
        })
    }

    deleteHistoryRow(id) {

        const historyCopy = JSON.parse(JSON.stringify(this.state.hold_history))
        const newHistory = historyCopy.filter(row => row.id !== id)
        const paymentRecordCopy = JSON.parse(JSON.stringify(this.state.paymentRecord))
        paymentRecordCopy.hold_history = newHistory
        this.setState({
            paymentRecord: paymentRecordCopy,
            hold_history: newHistory
        })
    }

    //gets a row and the new value after coach edits for the hold history table
    //needs to update the row with the field and value, then replace the corresponding id
    //row in this.state.hold_history
    updateHistory(id, field, value, row) {

        const rowCopy = JSON.parse(JSON.stringify(row))
        if (field === 'hold_micro_count' || !this.isValidHistoryField(value)) {
            console.log("trying to edit hold micro count or the value supplied is not a date")
            return
        }
        rowCopy[`${field}`] = value


        if (this.isValidHistoryField(rowCopy.on_hold_start_date) && this.isValidHistoryField(rowCopy.on_hold_end_date)) {

            const overlap = this.isOverlapping(rowCopy.on_hold_start_date, rowCopy.on_hold_end_date, rowCopy.id, this.state.hold_history)
            rowCopy.hold_micro_count = overlap ? "INVALID" : this.get_micro_offset(rowCopy.on_hold_start_date, rowCopy.on_hold_end_date)
        }

        const paymentRecordCopy = JSON.parse(JSON.stringify(this.state.paymentRecord))
        const hold_copy = JSON.parse(JSON.stringify(this.state.hold_history))
        function matchId(id) {
            const findMatch = (element) => element.id === id
            return hold_copy.findIndex(findMatch)

        }
        const updateIndex = matchId(id)
        hold_copy[updateIndex] = rowCopy
        paymentRecordCopy.hold_history = hold_copy

        this.setState({
            paymentRecord: paymentRecordCopy,
            hold_history: hold_copy,
            mostRecentHoldStart: this.getMostRecentHold(hold_copy)[0],
            mostRecentHoldEnd: this.getMostRecentHold(hold_copy)[1],
            micro_hold_offset: this.sumMicroOffset(hold_copy)

        })
    }

    addHistoryRow() {
        const paymentRecordCopy = JSON.parse(JSON.stringify(this.state.paymentRecord))
        const hold_copy = JSON.parse(JSON.stringify(this.state.hold_history))
        hold_copy.push(
            { on_hold_start_date: "", on_hold_end_date: "", hold_micro_count: "" }
        )
        paymentRecordCopy.hold_history = hold_copy
        this.setState({
            paymentRecord: paymentRecordCopy,
            hold_history: hold_copy
        })
    }

    isOverlapping(start, end, id, holdArray) {

        var isOverlapping = false

        for (let hold of holdArray) {
            if (id !== hold.id && hold.hold_micro_count !== "INVALID") {
                if (Math.max(treatAsUTC(start), treatAsUTC(hold.on_hold_start_date)) <= Math.min(treatAsUTC(end), treatAsUTC(hold.on_hold_end_date))) {
                    isOverlapping = true
                }

            }
        }

        return isOverlapping

    }

    render() {
        const holdPairs = [{ desc: 'on hold', value: true }, { desc: "active", value: false }]
        const fitnessPairs = [{ desc: "low", value: "low" }, { desc: "medium", value: "medium" }, { desc: "high", value: "high" }]
        const pStyles = {

            textInputRow: {
                width: '90%',
                padding: 15,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                margin: '0 auto',
            },
            textInputBottomRow: {
                width: '90%',
                padding: 15,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                margin: '0 auto',
            },
            daysRemainingRow: {
                width: '90%',
                padding: 15,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                margin: '0 auto',
                marginTop: -20
            },
            addButtonContainer: {
                width: '100%',
                padding: 15,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                margin: '0 auto',
            },
            coachProgramContainer: {
                width: '100%',
                height: 600,
                paddingTop: 75,
            },
            breakLine: {
                width: '100%',
                height: 75
            },
            dataGridContainer: {
                width: '90%',
                height: 500,
                margin: 'auto',
                display: 'flex',
                flexDirection: 'column'
            },
            smallButtonContainer: {
                paddingLeft: 20,
                paddingRight: 20
            },
            buttonStyle: {
                marginLeft: 20,
                marginRight: 20,
                width: '90%',
                height: 50
            }
        }

        return (
            <div style={pStyles.coachProgramContainer}>
                <div style={pStyles.textInputRow}>
                    <DisabledField fieldName={"Program Id"} fieldValue={this.state.program_id} />
                    <DisabledField fieldName={"Amount"} fieldValue={this.state.amount} />
                    <DisabledField fieldName={"Transaction Date"} fieldValue={this.state.payment_date} />
                </div>

                <div style={pStyles.textInputRow}>
                    <ChangeableField fieldName={"frequency"} fieldValue={this.state.frequency} />
                    <DropDownSelector menuPairs={fitnessPairs} label={"fitness"} getValue={this.getDropDownValue} changeValue={this.changeDropDownValue} />
                    <ChangeableField fieldName={"start date"} fieldValue={this.state.start_date} />
                </div>
                <div style={pStyles.daysRemainingRow}>
                    <SpacerField />
                    <SpacerField />
                    <TextField
                        id="daysRemaining"
                        value={`${84 - this.daysRemaining()} days remaining`}
                        disabled
                        InputProps={{ disableUnderline: true }}
                    />

                </div>
                <div style={pStyles.textInputRow}>
                    <DropDownSelector menuPairs={holdPairs} label={"hold"} getValue={this.getDropDownValue} changeValue={this.changeDropDownValue} />
                    <ChangeableField fieldName={"On hold start date"} fieldValue={this.state.mostRecentHoldStart ? this.state.mostRecentHoldStart : " "} />
                    <ChangeableField fieldName={"On hold end date"} fieldValue={this.state.mostRecentHoldEnd ? this.state.mostRecentHoldEnd : " "} />
                </div>

                <div style={pStyles.dataGridContainer}>
                    <DataTable hold_history={this.state.hold_history} updateHistory={this.updateHistory} setDeleteID={this.setDeleteIndex} />
                    <div style={pStyles.addButtonContainer}>

                        <IconButton color="primary"
                            aria-label="delete hold"
                            onClick={() => this.deleteHistoryRow(this.state.deleteIndex)}
                        >
                            <DeleteIcon />
                        </IconButton>

                        <IconButton color="primary"
                            aria-label="add hold"
                            onClick={() => this.addHistoryRow()}
                        >
                            <AddIcon />
                        </IconButton>
                    </div>
                </div>

                <div style={pStyles.textInputBottomRow}>
                    <DisabledField fieldName={"Current Monday"} fieldValue={this.getThisMonday()} />
                    <DisabledField fieldName={"Micro Offset"} fieldValue={this.state.micro_hold_offset} />
                </div>

                <div style={pStyles.textInputBottomRow}>
                    {/* <DropDownSelector menuPairs={reloadPairs} label={"reload"} getValue={this.getDropDownValue} changeValue={this.changeDropDownValue}/> */}

                    <div style={pStyles.smallButtonContainer}>
                        <Button
                            variant="contained"
                            id="view program"
                            color="default"
                            type="submit"
                            onClick={() => console.log("view prog button")}
                            style={pStyles.buttonStyle}
                            disabled={true}
                        >
                            {"View Program"}
                        </Button>
                    </div>

                    <div style={pStyles.smallButtonContainer}>
                        <Button
                            variant="contained"
                            id="save"
                            color="default"
                            type="submit"
                            onClick={() => {
                                console.log("save button")
                                this.prepareHoldHistory(this.state.hold_history)

                            }}
                            style={pStyles.buttonStyle}
                            disabled={true}
                        >
                            {"Save"}
                        </Button>
                    </div>

                </div>

            </div>
        )
    }

}

export default withRouter(withStyles(styles)(CoachProgram));

